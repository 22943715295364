import React, { useEffect, useState } from 'react';
import { MapContainer, Marker, Popup, TileLayer, Tooltip } from 'react-leaflet';
import { FullscreenControl } from 'react-leaflet-fullscreen';
// eslint-disable-next-line import/no-extraneous-dependencies
import MarkerClusterGroup from 'react-leaflet-markercluster';
import css from './Map.module.scss';
import AddMarker from './AddMarker';
import { MarkerNew, MarkerHide } from './MarkerNew';
import 'leaflet/dist/leaflet.css';
import Routing from './RoutingMachine';
import LeafletControlGeocoder from './LeafletControlGeocoder';
import 'react-leaflet-fullscreen/dist/styles.css';

const Map = ({
  center,
  markers,
  marker,
  canAddMarker,
  onNewMarkerMoved,
  routing = false,
  geoCoder = false,
  setAddress = undefined,
  permission = 'ADMIN',
  onClicks = undefined,
}) => {
  const [newMarker, setNewMarker] = useState(null);
  const [key, setKey] = useState(0);
  useEffect(() => {
    if (newMarker) {
      if (onNewMarkerMoved) onNewMarkerMoved(newMarker);
    }
  }, [newMarker]);
  useEffect(() => {
    setKey(key + 1);
  }, [center]);

  const getGeo = (e) => {
    if (setAddress) {
      setAddress(e);
    }
  };

  const getOrderId = (m) => {
    if (permission === 'EMPLOYEE') {
      for (let i = 0; i < markers.length; i++) {
        if (String(markers[i].position[0]) === String(m.lat) && String(markers[i].position[1] === String(m.lng))) {
          window.location.replace(`/employees/order/${markers[i].id}`);
        }
      }
    }
  };

  const markerClicked = (e) => {
    if (onClicks) {
      for (let i = 0; i < markers.length; i++) {
        if (
          String(markers[i].position[0]) === String(e.latlng?.lat) &&
          String(markers[i].position[1] === String(e.latlng?.lng))
        ) {
          return onClicks(markers[i].id);
        }
      }
    }
  };

  return (
    <MapContainer key={key} className={css.component} center={center || [37.73218, -122.431391]} zoom={15}>
      <TileLayer url='http://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}' />
      <MarkerClusterGroup>
        {markers.map((item) => (
          <Marker
            icon={routing && permission === 'EMPLOYEE' ? MarkerHide : MarkerNew}
            position={item.position}
            eventHandlers={{
              click: (e) => {
                markerClicked(e);
              },
            }}
          >
            {item.popup && <Popup>{item.popup}</Popup>}
            {item.tooltip && (
              <Tooltip
                className={routing && permission === 'EMPLOYEE' ? 'routing' : ''}
                direction={routing && permission === 'EMPLOYEE' ? 'center' : 'right'}
                offset={[0, 0]}
                opacity={1}
                permanent
              >
                {item.tooltip}
              </Tooltip>
            )}
          </Marker>
        ))}
      </MarkerClusterGroup>
      {marker?.position[0] && (
        <Marker draggable={newMarker} icon={MarkerNew} position={marker.position}>
          {marker.popup && <Popup>{marker.popup}</Popup>}
        </Marker>
      )}
      {canAddMarker && <AddMarker onDragend={setNewMarker} />}
      {routing && <Routing permission={permission} points={markers} getOrderId={getOrderId} />}
      {geoCoder && <LeafletControlGeocoder getGeo={getGeo} />}
      <FullscreenControl />
    </MapContainer>
  );
};
Map.defaultProps = {
  center: [37.73218, -122.431391],
  markers: [],
  marker: null,
  canAddMarker: false,
};

export { Map };
