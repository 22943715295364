import React, { useEffect, useContext, useState } from 'react';
import { Input, List, Typography } from 'antd';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import Cookies from 'universal-cookie';
import { DashboardPanelContext } from '../../../components/context/DashboardContext';
import { SET_UNREAD_COUNT } from '../../../components/context/reducer/actionTypes';
import BImageList from '../../../components/inputs/forms/BImageList';

const { Search } = Input;
const cookies = new Cookies();

const ChatPage = ({ orderId, userId, updateChatList = null, orderPage = false }) => {
  const history = useHistory();
  const { saveItem, fetchItem, globalDispatch, globalState } = useContext(DashboardPanelContext);
  const [commentVal, setCommentVal] = useState('');
  const [userMsg, setUserMsg] = useState([]);
  const [chatImgSave, setChatImgSave] = useState([]);
  const [newMsg, setNewMsg] = useState(false);
  const [users, setUsers] = useState([]);
  const { unreadCount } = globalState;

  useEffect(() => {
    fetchItem({
      modelName: `/users`,
      showLoader: true,
    }).then((response) => {
      if (response) {
        const res1 = [];
        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].status === '1') {
            res1.push(response.data[i]);
          }
        }
        setUsers(res1);
      }
    });
  }, []);

  const getMessages = () => {
    if (orderId) {
      fetchItem({
        modelName: `/order-comments?order_id=${orderId}`,
        showLoader: true,
      }).then((response) => {
        if (response.data.length === 1) {
          setNewMsg(true);
        }
        const _user = [];
        for (let i = 0; i < response.data.length; i++) {
          let _image = null;
          if (response.data[i].image) {
            // const f = response.data[i].image;
            const f = 'https://portal.furniturerepairexpert.com/furniture-repair/public/'.concat(
              response.data[i].image,
            );
            const imgSrc = `${f}?w=164&h=164&fit=crop&auto=format`;
            _image = [
              {
                img: imgSrc,
              },
            ];
          }
          if (String(response.data[i].user_id) === String(userId)) {
            _user.push({
              comment: response.data[i].comment,
              image: _image,
              user: 'user',
              created_at: response.data[i].created_at,
            });
          } else {
            const foundedUser = users.find((x) => String(x.id) === String(response.data[i].user_id));
            const userName = foundedUser?.f_name?.concat(' ').concat(foundedUser?.l_name);
            if (response.data[i].read === '0') {
              fetchItem({
                modelName: `order-comments/${response.data[i].id}`,
                showLoader: true,
                // eslint-disable-next-line no-loop-func
              }).then(() => {
                globalDispatch({ type: SET_UNREAD_COUNT, value: unreadCount + 1 });
              });
            }
            _user.push({
              comment: response.data[i].comment,
              image: _image,
              user: 'guest',
              created_at: response.data[i].created_at,
              userName,
            });
          }
        }
        setUserMsg(_user);
      });
    }
  };
  useEffect(() => {
    getMessages();
  }, [orderId, users]);

  useEffect(() => {
    if (newMsg) {
      updateChatList();
    }
  }, [newMsg]);

  const addComment = (val) => {
    setCommentVal('');
    if (val) {
      const comment = {
        comment: val,
        order_id: orderId,
        user_id: userId,
        read: '0',
      };
      saveItem('order-comments', comment, null).then(() => {
        getMessages();
      });
    }
    if (chatImgSave) {
      axios.defaults.baseURL = `${process.env.REACT_APP_BASE_URL}`;
      for (let i = 0; i < chatImgSave.length; i++) {
        const formData = new FormData();
        formData.append('image', chatImgSave[i]);
        formData.append('order_id', orderId);
        formData.append('user_id', userId);
        axios({
          method: 'post',
          url: 'order-comments',
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${cookies.get('userToken')}`,
          },
        }).then(() => {
          setChatImgSave([]);
          getMessages();
        });
      }
    }
  };

  const onChange = (val) => {
    setCommentVal(val.currentTarget.value);
  };

  const fileSelectedHandler = async (e) => {
    setChatImgSave([]);
    for (let i = 0; i < e.target.files.length; i++) {
      const file = e.target.files[i];
      // eslint-disable-next-line no-shadow
      setChatImgSave((beforeImagesSave) => [...beforeImagesSave, file]);
    }
  };

  return (
    <>
      {orderId ? (
        <div style={{ padding: '20px 10px 30px 10px', height: '100%', position: 'relative', margin: 0 }}>
          {!orderPage && (
            <button
              type='button'
              style={{
                background: '#1990fb',
                color: '#fff',
                border: '1px solid #1990fb',
                marginBottom: '5px',
                cursor: 'pointer',
              }}
              onClick={() => {
                // eslint-disable-next-line no-unused-expressions
                localStorage.getItem('isAdmin') === 'true'
                  ? history.push(`/order-management/order/${orderId}`)
                  : history.push(`/employees/order/${orderId}`);
              }}
            >
              Go to the order page
            </button>
          )}
          <List
            className='chat'
            dataSource={userMsg}
            renderItem={(item) => (
              <List.Item style={{ justifyContent: item.user === 'user' ? 'end' : 'flex-start', padding: '5px' }}>
                {item.image && item.user === 'user' && (
                  <>
                    <span
                      style={{
                        margin: '0 10px',
                        background: '#ddd',
                        borderRadius: '5px',
                        padding: '4px',
                        fontSize: '12px',
                      }}
                    >
                      {moment(item.created_at).format('LLL')}
                    </span>
                    <BImageList imageData={item.image} cols={1} />
                  </>
                )}
                {item.image && item.user === 'guest' && (
                  <>
                    <BImageList imageData={item.image} cols={1} />
                    <span
                      style={{
                        margin: '0 10px',
                        background: '#ddd',
                        borderRadius: '5px',
                        padding: '4px',
                        fontSize: '12px',
                      }}
                    >
                      {moment(item.created_at).format('LLL')} by {item.userName}
                    </span>
                  </>
                )}
                {item.comment && item.user === 'user' && (
                  <>
                    <span
                      style={{
                        margin: '0 10px',
                        background: '#ddd',
                        borderRadius: '5px',
                        padding: '4px',
                        fontSize: '12px',
                      }}
                    >
                      {moment(item.created_at).format('LLL')}
                    </span>
                    <Typography.Text
                      style={{
                        border: '1px solid #ddd',
                        padding: '5px 20px 5px 5px',
                        borderRadius: '5px',
                        background: item.user === 'user' ? '#1890ff47' : 'rgb(240 242 245)',
                      }}
                    >
                      {item.comment}
                    </Typography.Text>
                  </>
                )}
                {item.comment && item.user === 'guest' && (
                  <>
                    <Typography.Text
                      style={{
                        border: '1px solid #ddd',
                        padding: '5px 20px 5px 5px',
                        borderRadius: '5px',
                        background: item.user === 'user' ? '#1890ff47' : 'rgb(240 242 245)',
                      }}
                    >
                      {item.comment}
                    </Typography.Text>
                    <span
                      style={{
                        margin: '0 10px',
                        background: '#ddd',
                        borderRadius: '5px',
                        padding: '4px',
                        fontSize: '12px',
                      }}
                    >
                      {moment(item.created_at).format('LLL')} by {item.userName}
                    </span>
                  </>
                )}
              </List.Item>
            )}
            footer={
              <div style={{ marginTop: '40px' }}>
                <input
                  type='file'
                  multiple
                  onChange={fileSelectedHandler}
                  style={{
                    position: 'inherit',
                    bottom: orderPage ? '50px' : '60px',
                    right: '-70px',
                    marginBottom: '5px',
                    zIndex: '100',
                  }}
                />
                <Search
                  placeholder='Type a message'
                  enterButton='Send'
                  size='large'
                  onSearch={addComment}
                  value={commentVal}
                  onChange={onChange}
                  style={{
                    position: 'inherit',
                    bottom: orderPage ? '0px' : '10px',
                    right: '0px',
                    maxWidth: orderPage ? '100%' : '86%',
                  }}
                />
              </div>
            }
          />
        </div>
      ) : null}
    </>
  );
};
export default ChatPage;
