import { useEffect, useState } from 'react';

/**
 *
 * @returns {string} locale
 * (If the language has been set local storage it will return its value otherwise it will return default, which is 'fa')
 * @constructor
 */
const Locale = () => {
  const [locale, setLocale] = useState('en');

  useEffect(() => {
    if (localStorage.getItem('locale') !== null) {
      setLocale(localStorage.getItem('locale'));
    }
  }, [locale]);

  return locale;
};

export default Locale;
