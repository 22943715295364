const checked = (value, options) => {
  if (value !== true) {
    return options.message || 'must be checked';
  }
};

export const checkOnlyPositiveInteger = (value) => {
  if (/^[0-9]+[0-9]*$/.test(value)) {
    return true;
  }
  if (!String(value).startsWith('.') && String(value).includes('.') && !String(value).startsWith('.')) {
    return true;
  }
  return false;
};

export const onlyPositiveInteger = {
  message: 'Only number entry is allowed',
  value: /^[0-9]+[0-9]*$/,
};
export const zeroBehindTheNumber = {
  message: 'It is not allowed to enter zero behind the number',
  value: /^(?:[1-9]\d*|0)$/,
};
export const validPostalCode = (value) => {
  if (!value && value !== 0) {
    return true;
  }
  if (/\b(?!(\d)\1{3})[13-9]{4}[1346-9][013-9]{5}\b/.test(value)) {
    return true;
  }
  return 'Enter the ten-digit postal code in the correct format ';
};

export const validPhoneNumber = {
  message: 'The phone number must be eleven digits',
  value: /^[0-9]{11}$/,
};
// export const validPhoneNumber2 = {
//   message: "شماره تلفن  یازده رقم به صورت 09000000000   یا هشت رقم وارد شود ",
//   values: /^0\d{2,3}-\d{8}$/
// };

export const onlyInteger = {
  message: 'Only number entry is allowed',
  value: /^\d+$/,
};

export const onlyIntegerAndFloat = {
  value: /(\d+(\.\d+)?)/,
};

export const onlyEnglishCharacterAndNumbers = {
  value: /^[A-Za-z0-9 _\-_.()]+$/,
};

export const onlyEnglishPersianCharacterAndNumbers = {
  value: /^[A-Za-z0-9 _\-_.()\u0600-\u06FF\uFB8A\u067E\u0686\u06AF7\u200C\u200F]+$/,
  message: 'Only English and Persian letters are allowed',
};

export const emailRegex = {
  value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
};

export default {
  checked,
};
export function onlyDigit(s) {
  const reg = new RegExp(/^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/);
  return s !== undefined && s !== null && s.toString().length ? reg.test(s) : true;
}
export const validNationalCode = (code) => {
  let isValid = false;
  if (code && code.length === 10) {
    const splitedCode = code.split('');
    let sum = 0;
    splitedCode.forEach((c, i) => {
      if (i < 9) {
        sum += parseInt(c, 10) * (10 - i);
      }
    });
    let mod = sum % 11;
    if (mod < 2) {
      mod = 11 - mod;
    }
    isValid = 11 - mod === parseInt(splitedCode[9], 10);
  }
  return isValid;
};
