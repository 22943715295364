import { Card, CardContent, Grid } from '@material-ui/core';
import React, { useContext, useEffect, useRef } from 'react';
import { PropTypes } from '../common/utilities';
import { DashboardPanelContext } from './context/DashboardContext';
import BSubmitButton from './buttons/SubmitButton';

const BHistoryNavigator = ({ prevHistory, nextHistory, historyStep }) => {
  const {
    globalState: { historyIdList, isLoadingHistory },
  } = useContext(DashboardPanelContext);

  const prevBtnRef = useRef();
  const nextBtnRef = useRef();

  const handleKeyPress = ($event) => {
    if ($event.key === 'ArrowRight') {
      prevBtnRef.current?.click();
    }
    if ($event.key === 'ArrowLeft') {
      nextBtnRef.current?.click();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  return (
    <>
      {historyIdList.length !== 0 && (
        <Card>
          <CardContent>
            <Grid sm={6} spacing={4}>
              {
                <BSubmitButton
                  ref={prevBtnRef}
                  disabled={historyStep === 0}
                  color='secondary'
                  variant='outlined'
                  style={{ marginLeft: '10px' }}
                  onClick={() => prevHistory()}
                  loading={isLoadingHistory}
                  label='Next history'
                />
              }
              {
                <BSubmitButton
                  ref={nextBtnRef}
                  disabled={historyStep === historyIdList[0].length - 1}
                  color='secondary'
                  variant='outlined'
                  onClick={() => nextHistory()}
                  label='Previous history'
                  loading={isLoadingHistory}
                />
              }
            </Grid>
          </CardContent>
        </Card>
      )}
    </>
  );
};

BHistoryNavigator.propTypes = {
  prevHistory: PropTypes.func.isRequired,
  nextHistory: PropTypes.func.isRequired,
  historyStep: PropTypes.number,
};

export default BHistoryNavigator;
