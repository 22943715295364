import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { Button, Card, CardActions, CardContent, CardHeader, Divider } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import clsx from 'clsx';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import { Link, useHistory } from 'react-router-dom';
import { DashboardPanelContext } from '../../../../components/context/DashboardContext';
import BSubmitButton from '../../../../components/buttons/SubmitButton';
import { REQUIRED_STRING } from '../../../../constants/HookForm';
import BTextField from '../../../../components/inputs/forms/BTextField';
import {
  setValues,
  onChangeForBComponents,
  onKeyDownHandlerOnlyAcceptEnglishCharacterAndNumbers,
  onKeyDownHandlerOnlyAcceptIntegerEnglish,
  onKeyDownHandlerOnlyAcceptEmailCharacters,
} from '../../../../common/utilities';
import { BSwalShowError } from '../../../../common/BSwal';
import useHistoryForms from '../../../../hooks/useHistoryForms';
import BHistoryNavigator from '../../../../components/BHistoryNavigator';
import useBasicInformationMountedEffect from '../../../../hooks/useBasicInformationMountedEffect';
import BCheckBoxField from '../../../../components/inputs/forms/BCheckBoxField';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  customInputFormControl: {
    width: '100%',
  },
  formLabel: {
    fontSize: 16,
    marginBottom: 10,
  },
}));

const initialClient = {
  name: '',
  contract_number: '',
  phone_1: null,
  phone_2: null,
  full_address: '',
  email: '',
  description: '',
  status: true,
};

const ClientPage = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const { staticContext, ...rest } = props;
  const { globalState, saveItem } = useContext(DashboardPanelContext);

  const { viewReadOnly } = globalState;

  const [loading, setLoading] = useState(false);
  const { errors, handleSubmit, setValue, register } = useForm();

  const {
    fetchData,
    prevHistory,
    nextHistory,
    HistoryId,
    historyIdList,
    historyStep,
    history: fetchedHistory,
    historyInfo,
  } = useHistoryForms({
    location: props.location,
    route: '/client-management/clients',
  });

  const {
    id,
    item: currentClient,
    setItem: setCurrentClient,
  } = useBasicInformationMountedEffect({
    match: props.match,
    historyIdList,
    fetchItemURL: '/clients',
    localStorageKey: 'client',
    initialItem: initialClient,
    fetchData,
    historyId: HistoryId,
  });

  useEffect(() => {
    if (!viewReadOnly) {
      register(
        { name: 'contract_number' },
        {
          required: REQUIRED_STRING,
        },
      );
      register(
        { name: 'name' },
        {
          required: REQUIRED_STRING,
        },
      );
      register(
        { name: 'phone_1' },
        {
          required: REQUIRED_STRING,
        },
      );
      register(
        { name: 'phone_2' },
        {
          required: REQUIRED_STRING,
        },
      );
      register(
        { name: 'full_address' },
        {
          validate: (c) => c?.length >= 8 || 'The full address must be at least 8 characters.',
        },
      );
      register(
        { name: 'email' },
        {
          required: REQUIRED_STRING,
        },
      );
      register(
        { name: 'description' },
        {
          required: REQUIRED_STRING,
        },
      );
    }
  }, []);

  useEffect(() => {
    if (currentClient.data) {
      setCurrentClient(currentClient.data);
    }
    if (currentClient) {
      setValues(setValue, currentClient);
    }
  }, [currentClient]);

  useEffect(() => {
    if (fetchedHistory && historyIdList.length !== 0) {
      setCurrentClient(fetchedHistory);
    }
  }, [fetchedHistory]);

  const onSubmit = () => {
    setLoading(true);
    const client = { ...currentClient };
    if (currentClient.status) {
      client.status = '1';
    } else {
      client.status = '0';
    }
    saveItem('clients', client, id)
      .then(() => history.push('/client-management/clients'))
      .catch((err) => BSwalShowError(err?.data?.messages))
      .finally(() => setLoading(false));
  };

  const onChange = (e) => {
    onChangeForBComponents(e, setValue, currentClient, setCurrentClient);
  };

  return (
    <div className={classes.root} style={{ margin: 10 }}>
      {currentClient && (
        <Card {...rest} className={clsx(classes.root)}>
          <Button
            id='backToListIcon'
            component={Link}
            to={{
              pathname: '/client-management/clients',
            }}
            color='secondary'
            variant='outlined'
            style={{ float: 'right', marginTop: '10px', marginRight: '10px' }}
          >
            <ArrowBackIcon />
          </Button>
          <form className='formContainer' onSubmit={handleSubmit(onSubmit)}>
            <CardHeader title='Client Information' />
            <Divider />
            <CardContent>
              <br />
              {historyInfo}
              <Grid container spacing={3}>
                <BTextField
                  label='Contract Number'
                  name='contract_number'
                  required
                  value={currentClient?.contract_number}
                  onChange={onChange}
                  readOnly={viewReadOnly}
                  onKeyDown={onKeyDownHandlerOnlyAcceptEnglishCharacterAndNumbers}
                  error={errors.contract_number}
                />
                <BTextField
                  label='Name'
                  name='name'
                  value={currentClient?.name}
                  onChange={onChange}
                  readOnly={viewReadOnly}
                  onKeyDown={onKeyDownHandlerOnlyAcceptEnglishCharacterAndNumbers}
                  error={errors.name}
                  required
                />

                <BTextField
                  label='Phone'
                  sm={4}
                  name='phone_1'
                  value={currentClient?.phone_1}
                  onChange={onChange}
                  error={errors.phone_1}
                  onKeyDown={onKeyDownHandlerOnlyAcceptIntegerEnglish}
                  readOnly={viewReadOnly}
                  required
                />

                <BTextField
                  label='Mobile'
                  sm={4}
                  name='phone_2'
                  value={currentClient?.phone_2}
                  onChange={onChange}
                  error={errors.phone_2}
                  onKeyDown={onKeyDownHandlerOnlyAcceptIntegerEnglish}
                  readOnly={viewReadOnly}
                  required
                />

                <BTextField
                  label='Email'
                  name='email'
                  required
                  value={currentClient?.email}
                  error={errors.email}
                  onChange={onChange}
                  readOnly={viewReadOnly}
                  onKeyDown={onKeyDownHandlerOnlyAcceptEmailCharacters}
                />

                <BTextField
                  sm={12}
                  xs={12}
                  label='Address'
                  required
                  name='full_address'
                  value={currentClient?.full_address}
                  error={errors?.full_address}
                  onChange={onChange}
                  readOnly={viewReadOnly}
                />

                <BTextField
                  sm={12}
                  xs={12}
                  label='Description'
                  onChange={onChange}
                  name='description'
                  value={currentClient.description}
                  error={errors?.description}
                  readOnly={viewReadOnly}
                  required
                />

                <BCheckBoxField
                  sm={12}
                  name='status'
                  label='Enabled Status'
                  value={Number(currentClient?.status)}
                  error={errors.status}
                  onChange={onChange}
                  readOnly={viewReadOnly}
                />
              </Grid>
            </CardContent>
            <Divider />
            <CardActions>
              <Grid item xs={12} sm={6}>
                {!viewReadOnly && <BSubmitButton id='submitClient' label='Save Client' loading={loading} />}
              </Grid>
              <Grid item xs={12} sm={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  id='backToList'
                  component={Link}
                  to={{
                    pathname: '/client-management/clients',
                  }}
                  color='secondary'
                  variant='outlined'
                >
                  Back to Client list
                </Button>
              </Grid>
            </CardActions>
          </form>
          <BHistoryNavigator
            historyStep={historyStep}
            historyIdList={historyIdList}
            prevHistory={prevHistory}
            nextHistory={nextHistory}
          />
        </Card>
      )}
    </div>
  );
};

ClientPage.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object,
};

export default ClientPage;
