/* eslint-disable no-new */
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { Button, Card, CardActions, CardContent, CardHeader, Divider } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import clsx from 'clsx';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import { Link, useHistory } from 'react-router-dom';
import { DashboardPanelContext } from '../../../../components/context/DashboardContext';
import BSubmitButton from '../../../../components/buttons/SubmitButton';
import BSelectField from '../../../../components/inputs/forms/BSelectField';
import { REQUIRED_STRING } from '../../../../constants/HookForm';
import BTextField from '../../../../components/inputs/forms/BTextField';
import { setValues, onChangeForBComponents, convertArrayToKeyedObject } from '../../../../common/utilities';
import { BSwalShowError } from '../../../../common/BSwal';
import useHistoryForms from '../../../../hooks/useHistoryForms';
import BHistoryNavigator from '../../../../components/BHistoryNavigator';
import useBasicInformationMountedEffect from '../../../../hooks/useBasicInformationMountedEffect';
import BCheckBoxField from '../../../../components/inputs/forms/BCheckBoxField';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  customInputFormControl: {
    width: '100%',
  },
  formLabel: {
    fontSize: 16,
    marginBottom: 10,
  },
}));

const initialZip = {
  code: null,
  area_id: null,
  status: true,
};

const ZipsPage = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const { staticContext, ...rest } = props;
  const { globalState, saveItem, fetchItem } = useContext(DashboardPanelContext);

  const { viewReadOnly } = globalState;

  const [loading, setLoading] = useState(false);
  const { errors, handleSubmit, setValue, register } = useForm();
  const [areas, setAreas] = useState([]);

  const {
    fetchData,
    prevHistory,
    nextHistory,
    HistoryId,
    historyIdList,
    historyStep,
    history: fetchedHistory,
    historyInfo,
  } = useHistoryForms({
    location: props.location,
    route: '/order-management/zips',
  });

  const {
    id,
    item: currentZip,
    setItem: setCurrentZip,
  } = useBasicInformationMountedEffect({
    match: props.match,
    historyIdList,
    fetchItemURL: '/zips',
    localStorageKey: 'zips',
    initialItem: initialZip,
    fetchData,
    historyId: HistoryId,
  });

  useEffect(() => {
    fetchItem({
      modelName: `/areas`,
      showLoader: true,
    }).then((response) => {
      if (response) {
        const res1 = [];
        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].status === '1') {
            res1.push(response.data[i]);
          }
        }
        setAreas(res1);
      }
    });
  }, []);

  useEffect(() => {
    if (!viewReadOnly) {
      register(
        { name: 'code' },
        {
          required: REQUIRED_STRING,
        },
      );
      register(
        { name: 'area_id' },
        {
          required: REQUIRED_STRING,
        },
      );
    }
  }, []);

  useEffect(() => {
    if (currentZip.data) {
      setCurrentZip(currentZip.data);
    }
    if (currentZip) {
      setValues(setValue, currentZip);
    }
  }, [currentZip]);

  useEffect(() => {
    if (fetchedHistory && historyIdList.length !== 0) {
      setCurrentZip(fetchedHistory);
    }
  }, [fetchedHistory]);

  const savingItems = (zip) => {
    new Promise((resolve, reject) => {
      saveItem('zips', zip, id)
        .then((response) => {
          if (response.data.error) {
            BSwalShowError(response.data.error?.code);
            reject();
          } else {
            history.push('/order-management/zips');
            resolve();
          }
        })
        .catch((err) => BSwalShowError(err?.data?.messages))
        .finally(() => setLoading(false));
    });
  };

  const onSubmit = async () => {
    setLoading(true);
    const zip = { ...currentZip };
    if (currentZip.status) {
      zip.status = '1';
    } else {
      zip.status = '0';
    }
    if (currentZip.code.includes(',')) {
      const zips = currentZip.code.split(',');
      for (let i = 0; i < zips.length; i++) {
        zip.code = zips[i];
        // eslint-disable-next-line no-await-in-loop
        await savingItems(zip);
      }
    } else {
      saveItem('zips', zip, id)
        .then((response) => {
          if (response.data.error) {
            BSwalShowError(response.data.error?.code);
          } else {
            history.push('/order-management/zips');
          }
        })
        .catch((err) => BSwalShowError(err?.data?.messages))
        .finally(() => setLoading(false));
    }
  };

  const onChange = (e) => {
    onChangeForBComponents(e, setValue, currentZip, setCurrentZip);
  };

  return (
    <div className={classes.root} style={{ margin: 10 }}>
      {currentZip && (
        <Card {...rest} className={clsx(classes.root)}>
          <Button
            id='backToListIcon'
            component={Link}
            to={{
              pathname: '/order-management/zips',
            }}
            color='secondary'
            variant='outlined'
            style={{ float: 'right', marginTop: '10px', marginRight: '10px' }}
          >
            <ArrowBackIcon />
          </Button>
          <form className='formContainer' onSubmit={handleSubmit(onSubmit)}>
            <CardHeader title='Zip Code Information' />
            <Divider />
            <CardContent>
              <br />
              {historyInfo}
              <Grid container spacing={3}>
                <BTextField
                  label='Zip Code'
                  name='code'
                  value={currentZip?.code}
                  onChange={onChange}
                  readOnly={viewReadOnly}
                  error={errors.code}
                  required
                />
                <BSelectField
                  sm={4}
                  xs={12}
                  label='Area'
                  required
                  name='area_id'
                  value={currentZip.area_id}
                  onChange={onChange}
                  error={errors.area_id}
                  items={convertArrayToKeyedObject(areas, 'id', 'title')}
                  readOnly={viewReadOnly}
                />

                <BCheckBoxField
                  sm={12}
                  name='status'
                  label='Enabled Status'
                  value={Number(currentZip?.status)}
                  error={errors.status}
                  onChange={onChange}
                  readOnly={viewReadOnly}
                />
              </Grid>
            </CardContent>
            <Divider />
            <CardActions>
              <Grid item xs={12} sm={6}>
                {!viewReadOnly && <BSubmitButton id='submitEmployee' label='Save Zip' loading={loading} />}
              </Grid>
              <Grid item xs={12} sm={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  id='backToList'
                  component={Link}
                  to={{
                    pathname: '/order-management/zips',
                  }}
                  color='secondary'
                  variant='outlined'
                >
                  Back to Zips list
                </Button>
              </Grid>
            </CardActions>
          </form>
          <BHistoryNavigator
            historyStep={historyStep}
            historyIdList={historyIdList}
            prevHistory={prevHistory}
            nextHistory={nextHistory}
          />
        </Card>
      )}
    </div>
  );
};

ZipsPage.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object,
};

export default ZipsPage;
