import PropTypes from 'prop-types';
import { Card, CardContent, CardHeader, Divider } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import BImageList from '../../../../components/inputs/forms/BImageList';
import BTextField from '../../../../components/inputs/forms/BTextField';
import { setValues } from '../../../../common/utilities';
import useHistoryForms from '../../../../hooks/useHistoryForms';
import useBasicInformationMountedEffect from '../../../../hooks/useBasicInformationMountedEffect';
import 'antd/dist/antd.css';
import './OrdersPage.css';

const initialOrder = {
  email: '',
  phone_1: '',
  phone_2: '',
  travel_price: null,
  full_address: '',
  description: '',
  client_id: null,
  user_id: null,
  company_type_id: null,
  order_status_id: 1,
  latitude: null,
  longitude: null,
  previous_orders: [],
  special_notes: '',
  technician_report: '',
  job_types: [],
  code: '',
  area_id: null,
  interval_id: null,
  date: '',
  order_type: 'private',
  expenses: null,
  external_order_number: '',
  order_number: '',
  bonus: '',
  expenses_paid_by_employee: true,
};

const OrdersPageLink = (props) => {
  const { errors, setValue } = useForm();
  const [beforeImages, setBeforeImages] = useState([]);
  const [afterImages, setAfterImages] = useState([]);
  const [additionalImages, setAdditionalImages] = useState([]);

  const { fetchData, HistoryId, historyIdList } = useHistoryForms({
    location: props.location,
    route: '/order-management/orders',
  });

  const {
    id,
    item: currentOrder,
    setItem: setCurrentOrder,
  } = useBasicInformationMountedEffect({
    match: props.match,
    historyIdList,
    fetchItemURL: '/orders',
    localStorageKey: 'orders',
    initialItem: initialOrder,
    fetchData,
    historyId: HistoryId,
  });

  const fileSelectedHandlerAdditionalImagesLoad = async (e) => {
    setAdditionalImages([]);
    for (let i = 0; i < e.length; i++) {
      const f = e[i];
      f.name = `${process.env.REACT_APP_BASE_URL_UPLOAD}`.concat(e[i].image);
      const imgSrc = `${f.name}?w=164&h=164&fit=crop&auto=format`;
      // eslint-disable-next-line no-shadow
      setAdditionalImages((additionalImages) => [...additionalImages, { img: imgSrc }]);
    }
  };

  const fileSelectedHandlerBeforeImagesLoad = async (e) => {
    setBeforeImages([]);
    for (let i = 0; i < e.length; i++) {
      const f = e[i];
      f.name = `${process.env.REACT_APP_BASE_URL_UPLOAD}`.concat(e[i].image);
      const imgSrc = `${f.name}?w=164&h=164&fit=crop&auto=format`;
      // eslint-disable-next-line no-shadow
      setBeforeImages((beforeImages) => [...beforeImages, { img: imgSrc }]);
    }
  };

  const fileSelectedHandlerAfterImagesLoad = async (e) => {
    setAfterImages([]);
    for (let i = 0; i < e.length; i++) {
      const f = e[i];
      f.name = `${process.env.REACT_APP_BASE_URL_UPLOAD}`.concat(e[i].image);
      const imgSrc = `${f.name}?w=164&h=164&fit=crop&auto=format`;
      // eslint-disable-next-line no-shadow
      setAfterImages((afterImages) => [...afterImages, { img: imgSrc }]);
    }
  };

  useEffect(() => {
    if (currentOrder.data) {
      setCurrentOrder(currentOrder.data);
    }
    if (currentOrder.additional) {
      fileSelectedHandlerAdditionalImagesLoad(currentOrder.additional);
    }
    if (currentOrder.before) {
      fileSelectedHandlerBeforeImagesLoad(currentOrder.before);
    }
    if (currentOrder.after) {
      fileSelectedHandlerAfterImagesLoad(currentOrder.after);
    }
    if (currentOrder) {
      setValues(setValue, currentOrder);
    }
  }, [currentOrder, id]);

  return (
    <div>
      {currentOrder && (
        <Card>
          <form className='formContainer'>
            <CardHeader title='Order Information' />
            <Divider />
            <CardContent>
              <br />
              <BTextField
                sm={12}
                xs={12}
                label='Technician Report'
                name='technician_report'
                value={currentOrder.technician_report}
                error={errors?.technician_report}
                readOnly
              />
            </CardContent>
            <Divider />
            <Card style={{ margin: 10 }}>
              <CardHeader title='Before Images' />
              <CardContent>
                <BImageList imageData={beforeImages} />
              </CardContent>
            </Card>
            <Card style={{ margin: 10 }}>
              <CardHeader title='After Images' />
              <CardContent>
                <BImageList imageData={afterImages} />
              </CardContent>
            </Card>
            <Card style={{ margin: 10 }}>
              <CardHeader title='Additional Images' />
              <CardContent>
                <BImageList imageData={additionalImages} />
              </CardContent>
            </Card>
          </form>
        </Card>
      )}
    </div>
  );
};

OrdersPageLink.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object,
};

export default OrdersPageLink;
