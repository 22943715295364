import { useEffect, useState } from 'react';

const useOptions = ({ init, paginate, pageSize, search }) => {
  const [options, setOptions] = useState(init);

  useEffect(() => {
    setOptions((prevState) => ({ ...prevState, paging: paginate }));
  }, [paginate]);
  useEffect(() => {
    setOptions((prevState) => ({ ...prevState, pageSize }));
  }, [pageSize]);
  useEffect(() => {
    setOptions((prevState) => ({ ...prevState, search }));
  }, [search]);

  return { options };
};

export default useOptions;
