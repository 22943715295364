import React from 'react';
import Slider from 'react-slick';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import after from '../../../assets/img/after.png';
import before from '../../../assets/img/before.png';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function getModalStyle() {
  const top = 55;
  const left = 55;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
    marginTop: 20,
  },
  imageList: {
    width: '100%',
    height: 170,
  },
  paper: {
    position: 'relative',
    width: '600px !important',
    maxWidth: '90%',
    backgroundColor: 'transparent',
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: 0,
    maxHeight: '100%',
  },
}));

const BImageList = ({ imageData = [], cols = 6, watermark = null, removeImg = undefined, remove = false }) => {
  const classes = useStyles();
  const imageExtensions = ['png', 'jpg', 'jpeg', 'gif', 'JPEG', 'JPG', 'PNG'];
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const [activeImg, setActiveImg] = React.useState([]);
  const [activeSlide, setActiveSlde] = React.useState([]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: activeSlide,
  };

  const handleOpen = async (item) => {
    const images = [];
    imageData.forEach((image) => {
      images.push(image.img);
    });
    const activeImgSlide = images.findIndex((singleImage) => singleImage === item);
    setActiveSlde(activeImgSlide);
    setActiveImg(images);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deleteImage = (item) => {
    removeImg(item);
  };
  return (
    <div className={classes.root}>
      <ImageList rowHeight={160} className={classes.imageList} cols={cols}>
        {imageData.map((item, index) => (
          <ImageListItem key={item.img} cols={item.cols || 1}>
            {remove && (
              <HighlightOffIcon
                onClick={() => deleteImage(index)}
                style={{ position: 'absolute', top: '0px', right: '1px', cursor: 'pointer' }}
              />
            )}
            {imageExtensions.some((v) => item.img.includes(v)) ? (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
              <a onClick={() => handleOpen(item.img, index)}>
                <img src={item.img} key={item.img} alt={item.title} style={{ height: '100%' }} />
                {watermark && (
                  <div className='watermark'>
                    <img
                      src={watermark === 'after' ? after : before}
                      alt={item.title}
                      style={{ height: '40px', position: 'absolute', bottom: '0px', right: '0px' }}
                    />
                  </div>
                )}
              </a>
            ) : (
              <video width='100%' height='100%' controls>
                <source src={item.img} type='video/mp4' />
                <track src='captions_en.vtt' kind='captions' srcLang='en' label='english_captions' />
                Your browser does not support the video tag.
              </video>
            )}
          </ImageListItem>
        ))}
      </ImageList>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
        style={{ textAlign: 'center' }}
      >
        <Slider {...settings} style={{ textAlign: 'center', width: '80%', left: '10%', top: '24%', maxHeight: '100%' }}>
          {activeImg.map((image) => (
            <div style={modalStyle} className={classes.paper}>
              <img
                src={image}
                key={image}
                alt='ime-1'
                style={{ height: '100%', width: '100%', maxHeight: 'calc(100vh - 250px)' }}
              />
            </div>
          ))}
        </Slider>
      </Modal>
    </div>
  );
};
export default React.memo(BImageList);
