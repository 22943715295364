import React from 'react';
import AddIcon from '@material-ui/icons/Add';

import Locale from '../../helpers/Locale';
import { Default } from '../../data/Dictionary';
import styles from '../../assets/scss/modules/slidingButtonStyle.module.scss';

/**
 *
 * @param {Object} history
 * @param {String} location
 * @param {function} onClick
 * @param {String} className
 * @param {String} label
 * @returns {HTMLDivElement} It will return a sliding button
 * @constructor
 */
export default function SlidingButton({ onClick, className, label, id = null, style = {} }) {
  const Language = Locale();
  // const onClickEvent = () => history ? history.push(`${location}/create`) : onClick()

  return (
    <div
      aria-hidden='true'
      style={style}
      id={id}
      className={`${styles.container} ${className}__container`}
      onClick={onClick}
    >
      <div className={`${styles.plus} ${className}__plus`} id={id}>
        <AddIcon />
      </div>
      <div className={`${styles.title} ${className}__title`}>
        <span>{label || Default[Language].addNewItem}</span>
      </div>
    </div>
  );
}
