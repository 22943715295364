import React, { useState } from 'react';
import { Layout } from 'antd';
import ChatList from './ChatList/ChatList';
import ChatPage from './ChatPage/ChatPage';

const { Sider, Content } = Layout;
const Chat = () => {
  const [orderId, setOrderId] = useState(null);
  const [key, setKey] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const userId = localStorage.getItem('uid');

  const selectChatPageSearch = (value) => {
    setOrderId(value);
  };
  const selectChatPage = (value) => {
    setOrderId(value);
  };
  const updateChatList = () => {
    setKey(key + 1);
  };
  return (
    <Layout style={{ height: '100%' }}>
      <Sider style={{ height: '100%', overflowX: 'scroll' }} className='ant-layout-sider ant-layout-sider-light'>
        <ChatList selectChatPage={selectChatPage} selectChatPageSearch={selectChatPageSearch} key={key} />
      </Sider>
      <Content
        style={{
          height: '100%',
          overflowX: 'hidden',
          overflowY: 'scroll',
          paddingBottom: '40px',
          position: 'relative',
        }}
      >
        <ChatPage userId={userId} orderId={orderId} updateChatList={updateChatList} />
      </Content>
    </Layout>
  );
};
export default Chat;
