import React from 'react';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardHeader,
  CardContent,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  TableSortLabel,
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
// import { StatusBullet } from '../../../../components';

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 800,
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  status: {
    marginRight: theme.spacing(1),
  },
  actions: {
    justifyContent: 'flex-end',
  },
}));

const LatestOrders = (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader
        action={
          <RouterLink
            to='/order-management/order'
            style={{
              textDecoration: 'none',
              color: '#302198',
              border: '1px solid #302198',
              padding: '5px',
            }}
          >
            New Order
          </RouterLink>
        }
        title='Latest Orders'
        style={{ padding: '20px' }}
      />
      <Divider />
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align='left'>Order Number</TableCell>
                  <TableCell align='left'>Email</TableCell>
                  <TableCell align='left' sortDirection='desc'>
                    <Tooltip enterDelay={300} title='Sort'>
                      <TableSortLabel active direction='desc'>
                        Date
                      </TableSortLabel>
                    </Tooltip>
                  </TableCell>
                  <TableCell align='left'>Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.latestorders.map((order) => (
                  <TableRow hover key={order?.id}>
                    <TableCell>{order?.order_number}</TableCell>
                    <TableCell>{order?.email}</TableCell>
                    <TableCell />
                    <TableCell>
                      {order?.date}
                      {/* <div className={classes.statusContainer}>
                        <StatusBullet className={classes.status} color={statusColors[order.status]} size='sm' />
                        {order.status}
                      </div> */}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <Divider />
      <CardActions className={classes.actions}>
        <RouterLink to='/order-management/orders' style={{ textDecoration: 'none' }}>
          View All
        </RouterLink>
        <ArrowRightIcon />
      </CardActions>
    </Card>
  );
};

LatestOrders.propTypes = {
  className: PropTypes.string,
};

export default LatestOrders;
