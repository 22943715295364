import { font, primaryMainColor, whiteColor } from '../components/configs/Variables';

export const BMaterialTableLocalization = {
  error: 'Error in receiving information',
  header: {
    actions: 'Actions',
  },
  body: {
    filterRow: {
      filterTooltip: 'Search the column',
    },
    editRow: {
      deleteText: 'Are you sure you want to delete this row?',
      cancelTooltip: 'Rejcect',
      saveTooltip: 'Save',
    },
    addTooltip: 'Add',
    editTooltip: 'Edit',
    deleteTooltip: 'Delete',
    emptyDataSourceMessage: 'There are no items to display.',
    bulkEditTooltip: 'Edit all',
    bulkEditApprove: 'Save changes',
    bulkEditCancel: 'Reject',
  },
  toolbar: {
    searchTooltip: 'Search',
    searchPlaceholder: 'Search',
    exportName: 'CSV file',
    exportTitle: 'Download PDF',
    showColumnsTitle: 'Display fields',
    addRemoveColumns: 'List of playable fields',
    exportCSVName: 'Export CSV file',
    exportPDFName: '',
    nRowsSelected: 'row is selected.',
  },
  pagination: {
    firstTooltip: 'First Page',
    previousTooltip: 'previous page',
    nextTooltip: 'next page',
    lastTooltip: 'Last page',
    labelDisplayedRows: '{from}-{to} from {count}',
    labelRowsSelect: 'Row',
  },
};

export const BMaterialTableOptions = () => ({
  filtering: false,
  search: false,
  toolbar: false,
  pageSize: 10,
  emptyRowsWhenPaging: false,
  paging: false,
  filterCellStyle: {
    direction: 'ltr',
    textAlign: 'left',
  },
  exportButton: false,
  exportAllData: false,
  searchFieldStyle: {
    fontFamily: font,
  },
  actionsColumnIndex: -1,
  headerStyle: {
    backgroundColor: primaryMainColor,
    color: whiteColor,
    direction: 'ltr',
    textAlign: 'left',
  },
  rowStyle: {
    textAlign: 'left',
  },
  cellStyle: {
    textAlign: 'left',
  },
});
