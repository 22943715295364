import React from 'react';
import { Chart } from 'react-chartjs-2';
import { StylesProvider, ThemeProvider, jssPreset } from '@material-ui/styles';
import rtl from 'jss-rtl';
import { create } from 'jss';
import { BrowserRouter } from 'react-router-dom';
import { chartjs } from './helpers';
import './assets/scss/index.scss';
import theme from './theme';
import 'react-perfect-scrollbar/dist/css/styles.css';
import Routes from './Routes';
import DashboardPanelProvider from './components/context/DashboardContext';
import UserCredentialsProvider from './components/context/LoginContext';

Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
  draw: chartjs.draw,
});
// Configure JSS
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

export default () => (
  <BrowserRouter>
    <UserCredentialsProvider>
      <DashboardPanelProvider>
        <StylesProvider jss={jss}>
          <ThemeProvider theme={theme}>
            <Routes />
          </ThemeProvider>
        </StylesProvider>
      </DashboardPanelProvider>
    </UserCredentialsProvider>
  </BrowserRouter>
);
