import React, { useContext, useState } from 'react';
import { Redirect } from 'react-router-dom';
import Cookies from 'universal-cookie';

import axios from 'axios';
import moment from 'moment-jalaali';
import { Login } from '../../helpers/APIHandlers';
import LoginForm from '../../components/forms/login/LoginForm';
import { SET_CURRENT_DATE } from '../../components/context/reducer/actionTypes';
import { DashboardPanelContext } from '../../components/context/DashboardContext';
import { BSwalShowError } from '../../common/BSwal';
import { UserCredentialsContext } from '../../components/context/LoginContext';

export default function Index() {
  const { globalDispatch } = useContext(DashboardPanelContext);
  const { setCredentials } = useContext(UserCredentialsContext);

  const [loading, setLoading] = useState(false);
  const cookies = new Cookies();

  const loginCredentials = async (inputData) => {
    setLoading(true);
    Login(inputData.email, inputData.password)
      .then(({ data }) => {
        cookies.set('userToken', data?.access_token, {
          maxAge: data.expiresIn,
          path: '/',
        });

        setCredentials(data);
        localStorage.setItem('userCredentials', JSON.stringify(data));
        axios.defaults.headers.Authorization = `Bearer ${data?.access_token}`;
      })
      .catch((error) => {
        if (error?.data?.messages) BSwalShowError(error?.data?.messages);
        else if (!error) BSwalShowError(['Error in connection with server.']);
        else BSwalShowError(['The username or password is not correct.']);
      })
      .finally(() => setLoading(false));
  };

  if (cookies.get('userToken')) {
    const currentDate = moment().format('MM-DD-YYYY');
    globalDispatch({ type: SET_CURRENT_DATE, value: currentDate });
    return <Redirect from='/login' exact to='/' />;
  }
  return <LoginForm loginCredentials={loginCredentials} loading={loading} />;
}
