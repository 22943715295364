import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PropTypes } from '../common/utilities';
import HistoryForm from '../components/HistoryDialog/HistoryForm';
import { DashboardPanelContext } from '../components/context/DashboardContext';
import { SET_LOADING_HISTORY } from '../components/context/reducer/actionTypes';

const useHistoryForms = ({ location, route }) => {
  const historyRouter = useHistory();
  const {
    globalState: { historyIdList },
    globalDispatch,
    fetchItem,
  } = useContext(DashboardPanelContext);
  const [HistoryId, setHistoryId] = useState();
  useEffect(() => {
    setHistoryId(Number(Array(location.pathname.split('/'))[0][Array(location.pathname.split('/'))[0].length - 1]));
  }, [location]);
  const [historyStep, setHistoryStep] = useState();

  useEffect(() => {
    setHistoryStep(historyIdList[0]?.indexOf(HistoryId));
  }, [HistoryId]);
  const [history, setHistory] = useState(null);
  let historyInfo = null;
  if (historyIdList.length !== 0) {
    historyInfo = <HistoryForm item={history} />;
  }

  const fetchData = (selectedStep) => {
    if (historyIdList.length > 0 && historyIdList[0][selectedStep]) {
      globalDispatch({ type: SET_LOADING_HISTORY, value: true });
      fetchItem({
        modelName: `/histories`,
        id: historyIdList[0][selectedStep],
        showLoader: true,
      })
        .then((response) => {
          if (response) {
            setHistory(response.json);
            historyRouter.push(`${route}/histories/${historyIdList[0][selectedStep]}`);
          }
        })
        .finally(() => globalDispatch({ type: SET_LOADING_HISTORY, value: false }));
    }
  };

  const prevHistory = () => {
    fetchData(historyStep - 1);
    setHistoryStep(historyStep - 1);
  };

  const nextHistory = () => {
    fetchData(historyStep + 1);
    setHistoryStep(historyStep + 1);
  };
  return {
    nextHistory,
    prevHistory,
    fetchData,
    HistoryId,
    historyIdList,
    historyStep,
    history,
    historyInfo,
  };
};

useHistoryForms.propTypes = {
  location: PropTypes.object.isRequired,
  route: PropTypes.string.isRequired,
};

export default useHistoryForms;
