import React, { createContext, useState } from 'react';

const initCredentials = localStorage.getItem('userCredentials')
  ? JSON.parse(localStorage.getItem('userCredentials'))
  : {
      user: { currentPost: { privileges: [] }, userPosts: [] },
      token: null,
    };

export const UserCredentialsContext = createContext({
  credentials: initCredentials,
  setCredentials: () => '',
});

/**
 *
 * @param props
 * @returns {createContext} This will return the user context provider
 * @constructor
 */
export default function UserCredentialsProvider({ children }) {
  const [credentials, setCredentials] = useState(initCredentials);

  return (
    <UserCredentialsContext.Provider value={{ credentials, setCredentials }}>
      {children}
    </UserCredentialsContext.Provider>
  );
}
