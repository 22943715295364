import React, { forwardRef, useContext, useEffect } from 'react';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { List, colors, Divider } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import './index.css';
import _ from 'lodash';
import deepdash from 'deepdash/deepdash';
import { DashboardPanelContext } from '../../../../../../components/context/DashboardContext';
import { SET_SELECTED_MENU_ID } from '../../../../../../components/context/reducer/actionTypes';

deepdash(_);

const useStyles = makeStyles((theme) => ({
  root: {},
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium,
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main,
    },
  },
  nested: {
    paddingRight: theme.spacing(4),
  },
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div
    ref={ref}
    style={{
      flexGrow: 1,
      display: localStorage.getItem('isAdmin') === 'true' ? 'block' : 'inline',
      width: localStorage.getItem('isAdmin') === 'true' ? '100%' : '25%',
    }}
  >
    <RouterLink {...props} />
  </div>
));

const CustomListItem = ({ item, styleClasses, open, onClick, selected }) =>
  item.subMenus && item.subMenus.length > 0 ? (
    <MenuItem
      button
      className={styleClasses.nested}
      style={{ paddingRight: '10px' }}
      id={`mnu-${item.key}`}
      onClick={onClick}
      selected={selected}
    >
      {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
      <ListItemText primary={item.label} style={{ textAlign: 'left' }} />
      {open ? <ExpandLess /> : <ExpandMore />}
    </MenuItem>
  ) : (
    <>
      <MenuItem
        button
        style={{
          display: localStorage.getItem('isAdmin') === 'true' ? 'flex' : 'inline-flex',
          paddingRight: localStorage.getItem('isAdmin') === 'true' ? '32px' : '0',
          minHeight: localStorage.getItem('isAdmin') === 'true' ? '48px' : 'unset',
          width: localStorage.getItem('isAdmin') === 'true' ? '100%' : '25%',
        }}
        className={styleClasses.nested}
        id={`mnu-${item.key}`}
        onClick={onClick}
        selected={selected}
        component={CustomRouterLink}
        to={item.href}
      >
        {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
        <ListItemText primary={item.label} style={{ textAlign: 'left' }} />
      </MenuItem>
      {item.divider && <Divider />}
    </>
  );

CustomListItem.propTypes = {
  item: PropTypes.object,
  styleClasses: PropTypes.any,
  open: PropTypes.any,
  onClick: PropTypes.func,
  selected: PropTypes.any,
  // style: PropTypes.object,
};

const SidebarNav = (props) => {
  const { pages, className, ...rest } = props;
  // const [open, setOpen] = useState(false);
  const { globalState, globalDispatch } = useContext(DashboardPanelContext);
  const { selectedMenuId } = globalState;
  const classes = useStyles();
  const location = useLocation();
  useEffect(() => {
    if (pages && pages.length > 0) {
      const menu = _.findDeep(pages, (page) => location.pathname === page.href, {
        childrenPath: 'subMenus',
        checkCircular: false,
      });
      if (selectedMenuId === menu?.value?.key && _.get(menu, 'value.subMenus', []).length === 0) {
        // window.location.reload(false);
      } else if (menu && menu.value) {
        if (selectedMenuId === menu.value.key) {
          globalDispatch({
            type: SET_SELECTED_MENU_ID,
            value: menu.value.parent,
          });
        } else {
          globalDispatch({
            type: SET_SELECTED_MENU_ID,
            value: menu.value.key,
          });
        }
      }
    }
  }, [location, pages]);

  function eventFire(el, etype) {
    if (el) {
      if (el.fireEvent) {
        el.fireEvent(`on${etype}`);
      } else {
        const evObj = document.createEvent('Events');
        evObj.initEvent(etype, true, false);
        el.dispatchEvent(evObj);
      }
    }
  }

  const updateSelectedMenuId = (value) => {
    if (globalState.selectedMenuId !== value) {
      globalDispatch({ type: SET_SELECTED_MENU_ID, value });
    }
  };

  const handleClick = (item) => {
    if (selectedMenuId === item.key && _.get(item, 'subMenus', []).length === 0) {
      const reload = document.getElementById('reload-table');
      eventFire(reload, 'click');
      // if (reload) reload?.click();
    } else if (parseInt(item.key / 100, 10) !== parseInt(selectedMenuId / 100, 10)) updateSelectedMenuId(item.key);
    else if (item.subMenus?.length > 0)
      if (selectedMenuId === item.key) {
        updateSelectedMenuId(item.parent);
      } else if (item.key % 100 === 0 && selectedMenuId % 10 > item.key % 10) {
        updateSelectedMenuId(-1);
      } else if (item.key % 10 === 0 && selectedMenuId % 10 > item.key % 10) {
        updateSelectedMenuId(item.parent);
      } else {
        updateSelectedMenuId(item.key);
      }
  };

  return (
    <>
      <List {...rest} style={{ overflow: 'auto' }} component='nav' className={clsx(classes.root, className)}>
        {pages &&
          pages.length > 0 &&
          pages.map((page) => (
            <React.Fragment key={page.key}>
              <CustomListItem
                item={page}
                styleClasses={classes}
                open={selectedMenuId >= page.start && selectedMenuId < page.end}
                selected={selectedMenuId === page.start}
                onClick={() => handleClick(page)}
              />
              {localStorage.getItem('isAdmin') === 'true' && (
                <Collapse in={selectedMenuId >= page.start && selectedMenuId < page.end} timeout='auto' unmountOnExit>
                  <List component='div' style={{ marginRight: 10 }} disablePadding>
                    {page.subMenus &&
                      page.subMenus.map((item) => (
                        <React.Fragment key={item.key}>
                          <CustomListItem
                            item={item}
                            styleClasses={classes}
                            open={selectedMenuId >= item.start && selectedMenuId < item.end}
                            selected={selectedMenuId === item.key}
                            onClick={() => handleClick(item)}
                          />
                          <Collapse
                            in={selectedMenuId >= item.start && selectedMenuId < item.end}
                            timeout='auto'
                            unmountOnExit
                          >
                            <List component='div' style={{ marginRight: 10 }} disablePadding>
                              {item.subMenus &&
                                item.subMenus.map((item2) => (
                                  <CustomListItem
                                    item={item2}
                                    styleClasses={classes}
                                    open={selectedMenuId >= item2.start && selectedMenuId < item2.end}
                                    selected={selectedMenuId === item2.key}
                                    onClick={() => handleClick(item2)}
                                    key={item2.key}
                                  />
                                ))}
                            </List>
                          </Collapse>
                        </React.Fragment>
                      ))}
                  </List>
                </Collapse>
              )}
            </React.Fragment>
          ))}
      </List>
      {/* <a
        href='/chat'
        style={{
          width: '235px',
          background: '#fbbd2f',
          padding: '15px',
          position: 'absolute',
          bottom: '0',
          color: '#FFF',
          textDecoration: 'none',
        }}
      >
        Chat
      </a> */}
    </>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired,
};
export default SidebarNav;
