import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { cleanCookies } from 'universal-cookie/lib/utils';
import { DashboardPanelContext } from '../../components/context/DashboardContext';
import { SET_INITIAL_STATE } from '../../components/context/reducer/actionTypes';

const Logout = () => {
  const { globalDispatch, initialStateZero } = useContext(DashboardPanelContext);
  cleanCookies();
  globalDispatch({ type: SET_INITIAL_STATE, value: initialStateZero });
  localStorage.clear();
  return <Redirect to='/login' />;
};

export default Logout;
