import React, { useContext, useState, useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import Cookies from 'universal-cookie';
import { UserCredentialsContext } from '../context/LoginContext';

const RouteWithLayout = (props) => {
  const { layout: Layout, component: Component, permission, ...rest } = props;
  const [mainPermission, setMainPermission] = useState(null);
  const { credentials } = useContext(UserCredentialsContext);

  useEffect(() => {
    if (credentials.access_token) {
      if (localStorage.getItem('isAdmin') && localStorage.getItem('isAdmin') === 'true') {
        setMainPermission('ADMIN');
      } else {
        setMainPermission('EMPLOYEE');
      }
    }
  }, [credentials, localStorage.getItem('isAdmin')]);

  if (permission && mainPermission && mainPermission !== permission) return <Redirect exact to='/403' />;

  const cookies = new Cookies();
  return (
    <Route
      {...rest}
      render={(matchProps) =>
        cookies.get('userToken') ? (
          <Layout>
            <Component {...matchProps} />
          </Layout>
        ) : (
          <Redirect exact to='/login' />
        )
      }
    />
  );
};

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string,
};

export default RouteWithLayout;
