/* eslint-disable array-callback-return */
import React, { useEffect } from 'react';
import Html from 'react-pdf-html';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import logo from '../../../../../assets/img/logo/01.png';

const styles = StyleSheet?.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4',
  },
  pageImage: {
    flexDirection: 'row',
  },
  section: {
    margin: 10,
    padding: 10,
    flex: 1,
  },
  sectionLeft: {
    margin: 10,
    padding: 10,
    flot: 'right',
  },
  sectionLeft1: {
    margin: 10,
    backgroundColor: 'red',
    flot: 'right',
    overflow: 'scroll',
  },
});

const stylesheet = {
  p: {
    margin: 0,
    color: '#434343',
    fontWeight: '100',
  },
  div: {
    margin: '1px',
    color: '#434343',
    fontWeight: '100',
  },
  li: {
    color: '#434343',
  },
  strong: {
    fontWeight: '1000',
    color: '#000',
  },
};

const PdfContainer = ({
  createDate,
  date,
  orderNumber,
  address,
  email,
  client = null,
  company = null,
  jobType = null,
  tel = null,
  description = null,
  payment = null,
  external = null,
  special = null,
  clientFirstName = '',
  clientLastName = '',
  technichianReport = null,
  mobile = null,
  routesOrder = null,
  intervalPdf = null,
  after = null,
  before = null,
  additional = null,
}) => {
  const [desc, setDesc] = React.useState(null);
  const [specialNotes, setSpecialNotes] = React.useState(null);
  const [techReport, setTechReport] = React.useState(null);
  const jobTypes = [];
  for (let i = 0; i < jobType.length; i++) {
    jobTypes.push(
      <span>
        {jobType[i]}
        {i !== jobType.length - 1 ? '-' : ''}
      </span>,
    );
  }
  const pays = [];
  payment.map((pay) => {
    pays.push(<Text style={{ display: 'block', fontSize: '10px', width: '100%', marginTop: '5px' }}>{pay}</Text>);
  });

  const format = (ht) => {
    const tab = '\t';
    let result = '';
    let indent = '';

    ht.split(/>\s*</).forEach((element) => {
      if (element.match(/^\/\w/)) {
        indent = indent.substring(tab.length);
      }

      result += `${indent}<${element}>\r\n`;

      // eslint-disable-next-line no-useless-escape
      if (element.match(/^<?\w[^>]*[^\/]$/) && !element.startsWith('input')) {
        indent += tab;
      }
    });
    return result.substring(1, result.length - 3);
  };

  useEffect(() => {
    if (description && description !== '<p><br></p>') {
      setDesc(format(description));
    }
    if (special && special !== '<p><br></p>') {
      setSpecialNotes(format(special));
    }
    if (technichianReport && technichianReport !== '<p><br></p>') {
      setTechReport(format(technichianReport));
    }
  }, []);
  return (
    <Document>
      <Page key='1' size='A4' style={styles.page}>
        <View style={styles.section} key='left'>
          <Image src={logo} style={{ width: '100px', height: '100px' }} />
          <Text style={{ width: '160%', borderBottom: '3px solid #000', marginTop: '20px' }}>
            <div style={{ flot: 'left', fontSize: '10px', fontWeight: 'bold', marginRight: '100px' }}>
              ISSUED: {createDate}
            </div>
            <div> </div>
            <div> </div>
            <div> </div>
            <div style={{ flot: 'left', fontSize: '20px', fontWeight: 'bold', marginRight: '100px' }}>
              {routesOrder}
            </div>
            <div> </div>
            <div> </div>
            <div> </div>
            <div> </div>
            <div style={{ flot: 'right', fontSize: '10px', fontWeight: 'bold', paddingLeft: '100px' }}>
              APPOINTMENT: {date}
            </div>
            <div> </div>
            <div> </div>
            <div style={{ flot: 'right', fontSize: '10px', fontWeight: 'bold', paddingLeft: '100px' }}>
              {intervalPdf}
            </div>
          </Text>
          <Text style={{ width: '160%', borderBottom: '3px solid #000', marginTop: '5px' }}>
            <div style={{ flot: 'left', fontSize: '12px', fontWeight: 'bold', marginRight: '100px' }}>
              W/O # {external} {client}
            </div>
            <div> </div>
            <div> </div>
            <div> </div>
            <div> </div>
            <div> </div>
            <div style={{ flot: 'right', fontSize: '12px', fontWeight: 'bold', paddingLeft: '100px' }}>
              TICKET # {orderNumber}
            </div>
          </Text>
          <View style={{ borderRight: '3px solid #000' }}>
            {company && (
              <Text style={{ width: '100%', marginTop: '5px', fontSize: '12px' }}>CLIENT COMPANY: {company}</Text>
            )}
            {jobTypes.length > 0 && (
              <Text style={{ width: '100%', marginTop: '5px', fontSize: '12px' }}>JOB TYPE: {jobTypes} </Text>
            )}
          </View>
          <div
            style={{
              width: '160%',
              borderTop: '3px solid #000',
              marginTop: mobile ? '120px' : '90px',
              paddingTop: '5px',
            }}
          >
            {desc && (
              <Html stylesheet={stylesheet} style={{ fontSize: 10 }}>
                {desc}
              </Html>
            )}
          </div>
          {payment && payment.length > 0 && (
            <View
              style={{
                width: '160%',
                paddingTop: '5px',
                paddingBottom: '5px',
              }}
            >
              {pays}
            </View>
          )}
          {specialNotes && (
            <>
              <View
                style={{
                  width: '160%',
                  fontWeight: 'bold',
                  padding: '5px 5px 5px 0',
                  fontSize: '12px',
                  marginTop: '10px',
                }}
              >
                <Text>Special Instruction</Text>
              </View>
              <View
                style={{
                  width: '160%',
                  border: '3px solid #000',
                  padding: '5px',
                }}
              >
                <Html stylesheet={stylesheet} style={{ fontSize: 10 }}>
                  {specialNotes}
                </Html>
              </View>
            </>
          )}
          {technichianReport && (
            <>
              <View
                style={{
                  width: '160%',
                  fontWeight: 'bold',
                  padding: '5px 5px 5px 0',
                  fontSize: '12px',
                  marginTop: '10px',
                }}
              >
                <Text>Technician Report</Text>
              </View>
              <div
                style={{
                  width: '160%',
                  borderTop: '3px solid #000',
                  paddingTop: '5px',
                }}
              >
                {techReport && (
                  <Html stylesheet={stylesheet} style={{ fontSize: 10 }}>
                    {techReport}
                  </Html>
                )}
              </div>
            </>
          )}
          <View style={{ position: 'absolute', bottom: '20px' }}>
            <Text style={{ width: '100%', marginTop: '5px', fontSize: '12px' }}>Technician SIGNATURE:</Text>
          </View>
          <View
            style={{
              position: 'absolute',
              bottom: '20px',
              left: '80%',
              borderLeft: '3px solid #000',
              paddingLeft: '10px',
            }}
          >
            <Text style={{ width: '100%', marginTop: '5px', fontSize: '12px' }}>Customer SIGNATURE:</Text>
          </View>
          <View
            style={{
              position: 'absolute',
              width: '165%',
              bottom: '0px',
              height: '3px',
              backgroundColor: '#000',
            }}
          />
        </View>
        <View style={styles.sectionLeft} key='right'>
          <Text style={{ fontSize: '10px', marginBottom: '10px', textDecoration: 'underline' }}>
            <a href='www.furniturerepairexpert.com'>www.furniturerepairexpert.com</a>
          </Text>
          <Text style={{ fontSize: '10px', marginBottom: '10px' }}>
            Email:
            <a href='mailto:info@furniturerepairexpert.com' style={{ textDecoration: 'underline' }}>
              {' '}
              info@furniturerepairexpert.com{' '}
            </a>
          </Text>
          <Text style={{ fontSize: '10px', marginBottom: '10px' }}>
            Phone:
            <a href='tel:1-818-337-7007'> 1-818-337-7007 </a>
          </Text>
          <View style={{ marginTop: '100px', marginLeft: '-35px' }}>
            {(clientFirstName || clientFirstName) && (
              <Text style={{ width: '100%', marginTop: '10px', fontSize: '12px' }}>
                {clientFirstName} {clientLastName}
              </Text>
            )}
            <Text style={{ width: '35%', marginTop: '10px', fontSize: '12px' }}>{address}</Text>
            {tel && <Text style={{ width: '100%', marginTop: '5px', fontSize: '12px' }}>Phone: {tel}</Text>}
            {mobile && <Text style={{ width: '100%', marginTop: '5px', fontSize: '12px' }}>Mobile: {mobile}</Text>}
            <Text style={{ width: '40%', marginTop: '5px', fontSize: '12px' }}>{email}</Text>
          </View>
        </View>
      </Page>
      {additional && (
        <Page key='5' size='A4'>
          <View style={styles.pageImage}>
            {additional.map(
              (afterimg, index) =>
                index <= 2 && (
                  <View style={styles.sectionLeft1}>
                    <Image
                      key={String(index).concat('add')}
                      src={afterimg.img}
                      style={{
                        width: '179px',
                        height: '175px',
                        display: 'inline',
                      }}
                    />
                  </View>
                ),
            )}
          </View>
          <View style={styles.pageImage}>
            {additional.map(
              (afterimg, index) =>
                index > 2 &&
                index <= 5 && (
                  <View style={styles.sectionLeft1}>
                    <Image
                      key={String(index).concat('add')}
                      src={afterimg.img}
                      style={{
                        width: '179px',
                        height: '175px',
                        display: 'inline',
                      }}
                    />
                  </View>
                ),
            )}
          </View>
          <View style={styles.pageImage}>
            {additional.map(
              (afterimg, index) =>
                index > 5 &&
                index <= 8 && (
                  <View style={styles.sectionLeft1}>
                    <Image
                      key={String(index).concat('add')}
                      src={afterimg.img}
                      style={{
                        width: '179px',
                        height: '175px',
                        display: 'inline',
                      }}
                    />
                  </View>
                ),
            )}
          </View>
          <View style={styles.pageImage}>
            {additional.map(
              (afterimg, index) =>
                index > 8 &&
                index <= 11 && (
                  <View style={styles.sectionLeft1}>
                    <Image
                      key={String(index).concat('add')}
                      src={afterimg.img}
                      style={{
                        width: '179px',
                        height: '175px',
                        display: 'inline',
                      }}
                    />
                  </View>
                ),
            )}
          </View>
          <View style={styles.pageImage}>
            {additional.map(
              (afterimg, index) =>
                index > 11 &&
                index <= 14 && (
                  <View style={styles.sectionLeft1}>
                    <Image
                      key={String(index).concat('add')}
                      src={afterimg.img}
                      style={{
                        width: '179px',
                        height: '175px',
                        display: 'inline',
                      }}
                    />
                  </View>
                ),
            )}
          </View>
          <View style={styles.pageImage}>
            {additional.map(
              (afterimg, index) =>
                index > 14 &&
                index <= 17 && (
                  <View style={styles.sectionLeft1}>
                    <Image
                      key={String(index).concat('add')}
                      src={afterimg.img}
                      style={{
                        width: '179px',
                        height: '175px',
                        display: 'inline',
                      }}
                    />
                  </View>
                ),
            )}
          </View>
          <View style={styles.pageImage}>
            {additional.map(
              (afterimg, index) =>
                index > 17 &&
                index <= 20 && (
                  <View style={styles.sectionLeft1}>
                    <Image
                      key={String(index).concat('add')}
                      src={afterimg.img}
                      style={{
                        width: '179px',
                        height: '175px',
                        display: 'inline',
                      }}
                    />
                  </View>
                ),
            )}
          </View>
          <View style={styles.pageImage}>
            {additional.map(
              (afterimg, index) =>
                index > 20 &&
                index <= 23 && (
                  <View style={styles.sectionLeft1}>
                    <Image
                      key={String(index).concat('add')}
                      src={afterimg.img}
                      style={{
                        width: '179px',
                        height: '175px',
                        display: 'inline',
                      }}
                    />
                  </View>
                ),
            )}
          </View>
          <View style={styles.pageImage}>
            {additional.map(
              (afterimg, index) =>
                index > 23 &&
                index <= 26 && (
                  <View style={styles.sectionLeft1}>
                    <Image
                      key={String(index).concat('add')}
                      src={afterimg.img}
                      style={{
                        width: '179px',
                        height: '175px',
                        display: 'inline',
                      }}
                    />
                  </View>
                ),
            )}
          </View>
          <View style={styles.pageImage}>
            {additional.map(
              (afterimg, index) =>
                index > 26 &&
                index <= 29 && (
                  <View style={styles.sectionLeft1}>
                    <Image
                      key={String(index).concat('add')}
                      src={afterimg.img}
                      style={{
                        width: '179px',
                        height: '175px',
                        display: 'inline',
                      }}
                    />
                  </View>
                ),
            )}
          </View>
        </Page>
      )}
      {before && (
        <Page key='5' size='A4'>
          <View style={styles.pageImage}>
            {before.map(
              (afterimg, index) =>
                index <= 2 && (
                  <View style={styles.sectionLeft1}>
                    <Image
                      key={String(index).concat('add')}
                      src={afterimg.img}
                      style={{
                        width: '179px',
                        height: '175px',
                        display: 'inline',
                      }}
                    />
                  </View>
                ),
            )}
          </View>
          <View style={styles.pageImage}>
            {before.map(
              (afterimg, index) =>
                index > 2 &&
                index <= 5 && (
                  <View style={styles.sectionLeft1}>
                    <Image
                      key={String(index).concat('add')}
                      src={afterimg.img}
                      style={{
                        width: '179px',
                        height: '175px',
                        display: 'inline',
                      }}
                    />
                  </View>
                ),
            )}
          </View>
          <View style={styles.pageImage}>
            {before.map(
              (afterimg, index) =>
                index > 5 &&
                index <= 8 && (
                  <View style={styles.sectionLeft1}>
                    <Image
                      key={String(index).concat('add')}
                      src={afterimg.img}
                      style={{
                        width: '179px',
                        height: '175px',
                        display: 'inline',
                      }}
                    />
                  </View>
                ),
            )}
          </View>
          <View style={styles.pageImage}>
            {before.map(
              (afterimg, index) =>
                index > 8 &&
                index <= 11 && (
                  <View style={styles.sectionLeft1}>
                    <Image
                      key={String(index).concat('add')}
                      src={afterimg.img}
                      style={{
                        width: '179px',
                        height: '175px',
                        display: 'inline',
                      }}
                    />
                  </View>
                ),
            )}
          </View>
          <View style={styles.pageImage}>
            {before.map(
              (afterimg, index) =>
                index > 11 &&
                index <= 14 && (
                  <View style={styles.sectionLeft1}>
                    <Image
                      key={String(index).concat('add')}
                      src={afterimg.img}
                      style={{
                        width: '179px',
                        height: '175px',
                        display: 'inline',
                      }}
                    />
                  </View>
                ),
            )}
          </View>
          <View style={styles.pageImage}>
            {before.map(
              (afterimg, index) =>
                index > 14 &&
                index <= 17 && (
                  <View style={styles.sectionLeft1}>
                    <Image
                      key={String(index).concat('add')}
                      src={afterimg.img}
                      style={{
                        width: '179px',
                        height: '175px',
                        display: 'inline',
                      }}
                    />
                  </View>
                ),
            )}
          </View>
          <View style={styles.pageImage}>
            {before.map(
              (afterimg, index) =>
                index > 17 &&
                index <= 20 && (
                  <View style={styles.sectionLeft1}>
                    <Image
                      key={String(index).concat('add')}
                      src={afterimg.img}
                      style={{
                        width: '179px',
                        height: '175px',
                        display: 'inline',
                      }}
                    />
                  </View>
                ),
            )}
          </View>
          <View style={styles.pageImage}>
            {before.map(
              (afterimg, index) =>
                index > 20 &&
                index <= 23 && (
                  <View style={styles.sectionLeft1}>
                    <Image
                      key={String(index).concat('add')}
                      src={afterimg.img}
                      style={{
                        width: '179px',
                        height: '175px',
                        display: 'inline',
                      }}
                    />
                  </View>
                ),
            )}
          </View>
          <View style={styles.pageImage}>
            {before.map(
              (afterimg, index) =>
                index > 23 &&
                index <= 26 && (
                  <View style={styles.sectionLeft1}>
                    <Image
                      key={String(index).concat('add')}
                      src={afterimg.img}
                      style={{
                        width: '179px',
                        height: '175px',
                        display: 'inline',
                      }}
                    />
                  </View>
                ),
            )}
          </View>
          <View style={styles.pageImage}>
            {before.map(
              (afterimg, index) =>
                index > 26 &&
                index <= 29 && (
                  <View style={styles.sectionLeft1}>
                    <Image
                      key={String(index).concat('add')}
                      src={afterimg.img}
                      style={{
                        width: '179px',
                        height: '175px',
                        display: 'inline',
                      }}
                    />
                  </View>
                ),
            )}
          </View>
        </Page>
      )}
      {after && (
        <Page key='5' size='A4'>
          <View style={styles.pageImage}>
            {after.map(
              (afterimg, index) =>
                index <= 2 && (
                  <View style={styles.sectionLeft1}>
                    <Image
                      key={String(index).concat('add')}
                      src={afterimg.img}
                      style={{
                        width: '179px',
                        height: '175px',
                        display: 'inline',
                      }}
                    />
                  </View>
                ),
            )}
          </View>
          <View style={styles.pageImage}>
            {after.map(
              (afterimg, index) =>
                index > 2 &&
                index <= 5 && (
                  <View style={styles.sectionLeft1}>
                    <Image
                      key={String(index).concat('add')}
                      src={afterimg.img}
                      style={{
                        width: '179px',
                        height: '175px',
                        display: 'inline',
                      }}
                    />
                  </View>
                ),
            )}
          </View>
          <View style={styles.pageImage}>
            {after.map(
              (afterimg, index) =>
                index > 5 &&
                index <= 8 && (
                  <View style={styles.sectionLeft1}>
                    <Image
                      key={String(index).concat('add')}
                      src={afterimg.img}
                      style={{
                        width: '179px',
                        height: '175px',
                        display: 'inline',
                      }}
                    />
                  </View>
                ),
            )}
          </View>
          <View style={styles.pageImage}>
            {after.map(
              (afterimg, index) =>
                index > 8 &&
                index <= 11 && (
                  <View style={styles.sectionLeft1}>
                    <Image
                      key={String(index).concat('add')}
                      src={afterimg.img}
                      style={{
                        width: '179px',
                        height: '175px',
                        display: 'inline',
                      }}
                    />
                  </View>
                ),
            )}
          </View>
          <View style={styles.pageImage}>
            {after.map(
              (afterimg, index) =>
                index > 11 &&
                index <= 14 && (
                  <View style={styles.sectionLeft1}>
                    <Image
                      key={String(index).concat('add')}
                      src={afterimg.img}
                      style={{
                        width: '179px',
                        height: '175px',
                        display: 'inline',
                      }}
                    />
                  </View>
                ),
            )}
          </View>
          <View style={styles.pageImage}>
            {after.map(
              (afterimg, index) =>
                index > 14 &&
                index <= 17 && (
                  <View style={styles.sectionLeft1}>
                    <Image
                      key={String(index).concat('add')}
                      src={afterimg.img}
                      style={{
                        width: '179px',
                        height: '175px',
                        display: 'inline',
                      }}
                    />
                  </View>
                ),
            )}
          </View>
          <View style={styles.pageImage}>
            {after.map(
              (afterimg, index) =>
                index > 17 &&
                index <= 20 && (
                  <View style={styles.sectionLeft1}>
                    <Image
                      key={String(index).concat('add')}
                      src={afterimg.img}
                      style={{
                        width: '179px',
                        height: '175px',
                        display: 'inline',
                      }}
                    />
                  </View>
                ),
            )}
          </View>
          <View style={styles.pageImage}>
            {after.map(
              (afterimg, index) =>
                index > 20 &&
                index <= 23 && (
                  <View style={styles.sectionLeft1}>
                    <Image
                      key={String(index).concat('add')}
                      src={afterimg.img}
                      style={{
                        width: '179px',
                        height: '175px',
                        display: 'inline',
                      }}
                    />
                  </View>
                ),
            )}
          </View>
          <View style={styles.pageImage}>
            {after.map(
              (afterimg, index) =>
                index > 23 &&
                index <= 26 && (
                  <View style={styles.sectionLeft1}>
                    <Image
                      key={String(index).concat('add')}
                      src={afterimg.img}
                      style={{
                        width: '179px',
                        height: '175px',
                        display: 'inline',
                      }}
                    />
                  </View>
                ),
            )}
          </View>
          <View style={styles.pageImage}>
            {after.map(
              (afterimg, index) =>
                index > 26 &&
                index <= 29 && (
                  <View style={styles.sectionLeft1}>
                    <Image
                      key={String(index).concat('add')}
                      src={afterimg.img}
                      style={{
                        width: '179px',
                        height: '175px',
                        display: 'inline',
                      }}
                    />
                  </View>
                ),
            )}
          </View>
        </Page>
      )}
    </Document>
  );
};

export default PdfContainer;
