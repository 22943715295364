/* eslint-disable array-callback-return */
import React, { useState, useEffect, useContext, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import moment from 'moment';
import { Button, Grid } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ImageIcon from '@material-ui/icons/Image';
// eslint-disable-next-line import/no-extraneous-dependencies
import debounce from 'lodash.debounce';
import { DashboardPanelContext } from '../../../../../components/context/DashboardContext';
import { normalizeUsPhone } from '../../../../../common/utilities';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    marginTop: '1px',
  },
  inline: {
    display: 'inline',
  },
}));

const EmployeeOrdersList = ({ searchField, onRowClick }) => {
  const { fetchItem } = useContext(DashboardPanelContext);
  const [userLocation, setUserLocation] = useState([37.73218, -122.431391]);
  const [routes, setRoutes] = useState([]);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const classes = useStyles();
  /* const [dataLists, setDataLists] = useState([]); */

  const debouncedChangeHandler = useCallback(
    debounce((pNumber, sFiled) => {
      if (navigator.geolocation) {
        const location_timeout = setTimeout(() => {}, 10000);
        navigator.geolocation.getCurrentPosition(
          (position) => {
            clearTimeout(location_timeout);
            const lat = position.coords.latitude;
            const lng = position.coords.longitude;
            setUserLocation([lat, lng]);
          },
          () => {
            clearTimeout(location_timeout);
          },
        );
      }
      fetchItem({
        modelName: `/employee-routes?page=${pNumber}${sFiled ? `&search=${sFiled}` : ''}`,
        showLoader: true,
      }).then((response) => {
        if (response.data) {
          setLastPage(response.data.last_page);
          const __data = [];
          for (let i = 0; i < response.data.data.length; i++) {
            __data.push(response.data.data[i]);
          }
          setRoutes(__data);
        }
      });
    }, 300),
    [],
  );

  useEffect(() => {
    debouncedChangeHandler(page, searchField);
  }, [page, searchField]);

  /* useEffect(() => {
    if (routes.length > 0 && data.length > 0) {
      const _dataList = [];
      const _finaldataList = [];
      for (let j = 0; j < routes.length; j++) {
        const _list = [];
        const _sortedList = [];
        for (let i = 0; i < data.length; i++) {
           if (routes[j].includes(data[i].id)) {
            _list.push(data[i]);
          } 
        }
        for (let i = 0; i < _list.length; i++) {
          _sortedList[routes[j].indexOf(_list[i].id)] = _list[i];
        }
        _dataList.push(_sortedList);
      }
      for (let j = 0; j < _dataList.length; j++) {
        if (_dataList[j].length > 0) {
          _finaldataList.unshift(_dataList[j]);
        }
      }
      setDataLists(_finaldataList);
    }
  }, [routes, data]); */

  const prevPage = () => setPage(page > 1 ? page - 1 : 1);
  const nextPage = () => setPage(page < lastPage ? page + 1 : lastPage);

  return (
    <List className={classes.root}>
      <ListItem alignItems='flex-start'>
        <Grid sortingmode='server' container spacing={1}>
          <Grid item xs={3} style={{ fontWeight: 'bold', fontSize: '15px' }}>
            #
          </Grid>
          <Grid item xs={6} style={{ fontWeight: 'bold', fontSize: '15px' }}>
            Customer
          </Grid>
          <Grid item xs={3} style={{ fontWeight: 'bold', fontSize: '15px' }}>
            Appo. Date
          </Grid>
        </Grid>
      </ListItem>
      <Divider component='li' />
      {routes.length > 0 &&
        routes.map(
          (value) =>
            value?.orderList && (
              <div key={value.id} style={{ border: '3px solid #3f3e3e', borderTop: '1px solid #3f3e3e' }}>
                {value?.orderList?.map(
                  (item) =>
                    item && (
                      <>
                        <ListItem
                          alignItems='flex-start'
                          style={{ cursor: 'pointer' }}
                          onClick={(e) => onRowClick(e, item)}
                        >
                          <Grid container spacing={1}>
                            <Grid item xs={3}>
                              <div>{item.order_number}</div>
                              <div style={{ position: 'relative' }}>
                                {localStorage
                                  .getItem('f_name')
                                  .charAt(0)
                                  .toUpperCase()
                                  .concat(localStorage.getItem('l_name').charAt('0').toUpperCase())}
                                {value.orders_id.includes(item.id) ? (
                                  <span key={item.id}>{value.orders_id.indexOf(item.id) + 1}</span>
                                ) : null}
                                {item.special_notes ? (
                                  <CheckCircleIcon
                                    style={{
                                      color: '#000',
                                      top: '2px',
                                      fontSize: '1.2rem',
                                      marginLeft: '5px',
                                      position: 'absolute',
                                    }}
                                  />
                                ) : (
                                  ''
                                )}
                              </div>
                              {item?.after?.map((after) => after.status === '1').filter(Boolean).length +
                                item?.before?.map((before) => before.status === '1').filter(Boolean).length +
                                item?.additional?.map((additional) => additional.status === '1').filter(Boolean)
                                  .length >
                              0 ? (
                                <div style={{ position: 'relative', height: '25px' }}>
                                  <ImageIcon
                                    style={{
                                      color: '#000',
                                      top: '2px',
                                      fontSize: '1.2rem',
                                      position: 'absolute',
                                    }}
                                  />
                                  <span
                                    style={{
                                      color: '#000',
                                      top: '1px',
                                      marginLeft: '25px',
                                      position: 'absolute',
                                    }}
                                  >
                                    &#10799;{' '}
                                    {item.after.map((after) => after.status === '1').filter(Boolean).length +
                                      item.before.map((before) => before.status === '1').filter(Boolean).length +
                                      item.additional.map((additional) => additional.status === '1').filter(Boolean)
                                        .length}
                                  </span>
                                </div>
                              ) : (
                                ''
                              )}
                              <div>
                                {' '}
                                {item.interval.min} - {item.interval.max}{' '}
                              </div>
                            </Grid>
                            <Grid item xs={6}>
                              <div>
                                <span style={{ fontSize: '16px' }}>
                                  {item.customer_f_name} {item.customer_l_name}
                                </span>
                                <span> </span>
                                <span>
                                  <a
                                    style={{ color: '#3150ff' }}
                                    href={`tel:${item?.phone_1}`}
                                    onClick={(e) => e.stopPropagation()}
                                  >
                                    {' '}
                                    {item?.phone_1?.length === 10 ? normalizeUsPhone(item?.phone_1) : item?.phone_1}
                                  </a>
                                </span>
                                <span> </span>
                                <span>
                                  <a
                                    style={{ color: '#2b3da0' }}
                                    href={`https://www.google.com/maps/dir/?api=1&origin=${userLocation[0]},${userLocation[1]}&destination=${item.full_address}`}
                                    target='_blank'
                                    rel='noreferrer'
                                    onClick={(e) => e.stopPropagation()}
                                  >
                                    {' '}
                                    {item.full_address}
                                  </a>
                                </span>
                              </div>
                            </Grid>
                            <Grid item xs={3}>
                              {/* <div>{item.order_type.charAt(0).toUpperCase() + item.order_type.slice(1)}</div> */}
                              <div>{item.client?.name}</div>
                              {moment(item.date, true).isValid() && <div>{moment(item.date).format('MM-DD-YYYY')}</div>}
                            </Grid>
                          </Grid>
                        </ListItem>
                        <Divider component='li' />
                      </>
                    ),
                )}
              </div>
            ),
        )}
      {routes.length > 0 && (
        <Grid item xs={12} style={{ marginTop: '8px' }}>
          <Button
            disabled={page === 1}
            style={{ marginRight: '12px' }}
            id='prev'
            color='secondary'
            variant='outlined'
            onClick={prevPage}
          >
            Prev
          </Button>
          <Button disabled={page === lastPage} id='next' color='secondary' variant='outlined' onClick={nextPage}>
            Next
          </Button>
        </Grid>
      )}
    </List>
  );
};

export default EmployeeOrdersList;
