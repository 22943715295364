import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';

const LoadingDialog = ({ open, handleClose }) => (
  <Dialog
    fullWidth
    maxWidth='md'
    open={open()}
    style={{ textAlign: 'center' }}
    onClose={handleClose}
    aria-labelledby='form-dialog-title'
  >
    <DialogTitle id='form-dialog-title'>Loading</DialogTitle>
    <DialogContent>
      <div>Please wait while images are uploading...</div>
      <CircularProgress style={{ zIndex: '10000' }} />
    </DialogContent>
  </Dialog>
);

export default LoadingDialog;
