import React from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  buttonProgress: {
    position: 'relative',
    top: 7,
  },
  searchButton: {
    width: '154px',
  },
}));
const BSubmitButton = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const {
    loading,
    id,
    label,
    disabled = false,
    style = {},
    // onSubmit = () => null,
    onClick = () => null,
    extraIcon = null,
  } = props;
  return (
    <>
      <Button
        style={style}
        ref={ref}
        type='submit'
        variant='outlined'
        color='primary'
        disabled={loading || disabled}
        id={id}
        onClick={onClick}
        className={classes.searchButton}
      >
        {label}
        {extraIcon}
        {loading && <CircularProgress style={{ marginTop: -14 }} size={24} className={classes.buttonProgress} />}
      </Button>
    </>
  );
});

BSubmitButton.propTypes = {
  loading: PropTypes.bool,
  label: PropTypes.string.isRequired,
  id: PropTypes.any,
  style: PropTypes.any,
  // onSubmit: PropTypes.func,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default BSubmitButton;
