import React, { useEffect, useState, useContext } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Avatar, Typography, Hidden, IconButton } from '@material-ui/core';
import InputIcon from '@material-ui/icons/Input';
import { cleanCookies } from 'universal-cookie/lib/utils';
import { DashboardPanelContext } from '../../../../../../components/context/DashboardContext';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content',
  },
  avatar: {
    width: 60,
    height: 60,
  },
  avatarem: {
    width: 37,
    height: 37,
  },
  name: {
    marginTop: theme.spacing(1),
  },
}));

const Profile = (props) => {
  const { className, credentials, ...rest } = props;
  const classes = useStyles();
  const history = useHistory();
  const { fetchItem } = useContext(DashboardPanelContext);
  const [user, setUser] = useState({
    firstName: '',
    lastName: '',
    avatar: '/images/avatars/avatar_11.png',
    bio: '',
  });
  const [permission, setPermission] = useState('');

  useEffect(() => {
    if (credentials.access_token) {
      fetchItem({
        modelName: `/profile`,
        showLoader: true,
      }).then((response) => {
        const userJson = { ...user };
        userJson.firstName = response.f_name;
        userJson.lastName = response.l_name;
        userJson.id = response.id;
        const name = 'https://portal.furniturerepairexpert.com/furniture-repair/public/'.concat(response.profile_image);
        const imgSrc = `${name}?w=164&h=164&fit=crop&auto=format`;
        // eslint-disable-next-line no-shadow
        userJson.profile_image = { img: imgSrc };
        setUser(userJson);
        localStorage.setItem('isAdmin', response.is_admin === '1');
        localStorage.setItem('uid', response.id);
        if (response.is_admin === '1') {
          setPermission('ADMIN');
        } else {
          setPermission('EMPLOYEE');
        }
      });
    }
  }, [credentials]);
  return (
    <>
      {permission === 'EMPLOYEE' && (
        <span>
          {user.firstName} {user.lastName}
        </span>
      )}
      <div {...rest} className={clsx(classes.root, className)} style={{ padding: '10px' }}>
        {user && (
          <>
            {permission === 'ADMIN' && (
              <Avatar
                alt='Person'
                className={classes.avatar}
                component={RouterLink}
                src={user?.profile_image?.img}
                to={`/employee-management/employee/${user.id}`}
              />
            )}
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography className={classes.name} variant='h4'>
                {/* {`${user.firstName} ${user.lastName}`} */}
                {credentials.username}
              </Typography>
              <Hidden mdUp>
                <IconButton
                  className={classes.signOutButton}
                  color='inherit'
                  onClick={() => {
                    cleanCookies();
                    history.push('/login');
                  }}
                >
                  <InputIcon />
                </IconButton>
              </Hidden>
            </div>
            {localStorage.getItem('isAdmin') === 'true' && (
              <Typography variant='body2'>
                {user.firstName} {user.lastName}
              </Typography>
            )}
          </>
        )}
      </div>
    </>
  );
};

Profile.propTypes = {
  className: PropTypes.string,
};

export default Profile;
