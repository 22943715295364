import palette from '../../../../theme/palette';

export const data = {
  labels: ['۱ خرداد', '۲ خرداد', '۳ خرداد', '۴ خرداد', '۵ خرداد', '۶ خرداد'],
  //   datasets: [{

  //     data: [10, 20, 30, 40, 50, 60, 70]
  // }],
  datasets: [
    {
      label: 'امسال',
      backgroundColor: palette.primary.main,
      data: [18, 5, 19, 27, 29, 19, 20],
      barThickness: 12,
      maxBarThickness: 10,
      barPercentage: 0.5,
      categoryPercentage: 0.5,
    },
    {
      label: 'سال گذشته',
      backgroundColor: palette.neutral,
      data: [11, 20, 12, 29, 30, 25, 13],
      barThickness: 12,
      maxBarThickness: 10,
      barPercentage: 0.5,
      categoryPercentage: 0.5,
    },
  ],
};

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  animation: false,
  legend: { display: false },
  cornerRadius: 20,
  tooltips: {
    enabled: true,
    mode: 'index',
    intersect: false,
    borderWidth: 1,
    borderColor: palette.divider,
    backgroundColor: palette.white,
    titleFontColor: palette.text.primary,
    bodyFontColor: palette.text.secondary,
    footerFontColor: palette.text.secondary,
  },
  layout: { padding: 0 },
};
