import { useContext, useState, useEffect } from 'react';
import { UserCredentialsContext } from '../components/context/LoginContext';

const useHasPermission = (permission) => {
  const [mainPermission, setMainPermission] = useState(null);
  const { credentials } = useContext(UserCredentialsContext);
  useEffect(() => {
    if (credentials.access_token) {
      if (localStorage.getItem('isAdmin') && localStorage.getItem('isAdmin') === 'true') {
        setMainPermission('ADMIN');
      } else {
        setMainPermission('EMPLOYEE');
      }
    }
  }, [credentials]);

  return mainPermission === permission;
};

export default useHasPermission;
