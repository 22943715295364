import React, { useState, useEffect, useContext } from 'react';
import { PaymentForm, CreditCard } from 'react-square-web-payments-sdk';
import { useHistory } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Popconfirm, Tabs } from 'antd';
import { useForm } from 'react-hook-form';
import { Button } from '@material-ui/core';
import { onChangeForBComponents, onKeyDownHandlerOnlyAcceptIntegerEnglish } from '../../../../common/utilities';
import BTextField from '../../../../components/inputs/forms/BTextField';
import useBasicInformationMountedEffect from '../../../../hooks/useBasicInformationMountedEffect';
import useHistoryForms from '../../../../hooks/useHistoryForms';
import { DashboardPanelContext } from '../../../../components/context/DashboardContext';
import { BSwalShowError, BSwalCustom } from '../../../../common/BSwal';

const initialOrder = {
  email: '',
  phone_1: '',
  phone_2: '',
  travel_price: null,
  full_address: '',
  description: '',
  client_id: null,
  user_id: null,
  company_type_id: null,
  order_status_id: 1,
  latitude: null,
  longitude: null,
  previous_orders: [],
  special_notes: '',
  technician_report: '',
  job_types: [],
  code: '',
  area_id: null,
  interval_id: null,
  date: '',
  order_type: 'private',
  expenses: null,
  external_order_number: '',
  order_number: '',
  bonus: '',
  expenses_paid_by_employee: true,
};

const appId = 'sq0idp-FBgwN90L79Qz7MRXnx6diw';

const Payment = (props) => {
  const history = useHistory();
  const { saveItem } = useContext(DashboardPanelContext);
  const { TabPane } = Tabs;
  const { setValue } = useForm();
  const [creditInfo, setCreditInfo] = useState({
    amountExpenses: null,
    amount: null,
    cardNumber: null,
    cardName: '',
    expiary: null,
    code: null,
  });
  const [cashinfo, setCashInfo] = useState({
    amountExpenses: null,
    amount: null,
  });
  const [creditTotalPayment, setCreditTotalPayment] = useState(0);
  const orderPayment = {
    cc_number: '0',
    amount: null,
    percent_amount: 0,
    user_id: null,
    order_id: null,
    payment_type_id: null,
    payment_type: '',
  };
  const [payingCredit, setPayingCrdit] = useState(false);

  const { fetchData, HistoryId, historyIdList } = useHistoryForms({
    location: props.location,
    route: '/order-management/orders',
  });

  const {
    id,
    item: currentOrder,
    setItem: setCurrentOrder,
  } = useBasicInformationMountedEffect({
    match: props.match,
    historyIdList,
    fetchItemURL: '/orders',
    localStorageKey: 'orders',
    initialItem: initialOrder,
    fetchData,
    historyId: HistoryId,
  });

  useEffect(() => {
    if (currentOrder?.data) {
      setCurrentOrder(currentOrder.data);
    }
  }, [currentOrder]);

  useEffect(() => {
    if (creditInfo.amount) {
      setCreditTotalPayment(((Number(creditInfo.amount) * 4) / 100 + Number(creditInfo.amount)).toFixed(2));
    } else {
      setCreditTotalPayment(
        (
          ((Number(creditInfo.amount) + Number(creditInfo.amountExpenses)) * 4) / 100 +
          Number(creditInfo.amount) +
          Number(creditInfo.amountExpenses)
        ).toFixed(2),
      );
    }
  }, [creditInfo.amount, creditInfo.amountExpenses]);

  const changeTab = () => {
    setCreditInfo({
      amountExpenses: null,
      amount: null,
      cardNumber: null,
      cardName: '',
      expiary: null,
      code: null,
    });
    setCashInfo({
      amountExpenses: null,
      amount: null,
    });
    setPayingCrdit(false);
  };
  const onChange = (e) => {
    onChangeForBComponents(e, setValue, creditInfo, setCreditInfo);
  };
  const onChangeCash = (e) => {
    onChangeForBComponents(e, setValue, cashinfo, setCashInfo);
  };
  const payCredit = () => {
    setPayingCrdit(true);
  };
  const payCash = async () => {
    const _orderPayment = { ...orderPayment };
    _orderPayment.user_id = currentOrder.user_id;
    _orderPayment.order_id = currentOrder.id;
    _orderPayment.payment_type_id = 1;
    if (cashinfo.amountExpenses) {
      _orderPayment.amount = null;
      _orderPayment.payment_type = 'expenses';
      _orderPayment.amount = cashinfo.amountExpenses;
      await saveItem('order-payments', _orderPayment, null).then((response) => {
        if (response.data.error) {
          if (Array.isArray(response.data.error)) {
            BSwalShowError(response.data.error);
          } else if (response.data.error.user_id) {
            BSwalShowError(['This order is not assigned to any employee yet.']);
          } else {
            BSwalShowError([response.data.error]);
          }
        } else if (!cashinfo.amount) {
          if (localStorage.getItem('isAdmin') === 'true') {
            history.push(`/order-management/order/${currentOrder.id}`);
          } else {
            history.push(`/employees/order/${currentOrder.id}`);
          }
        }
      });
    }
    if (cashinfo.amount) {
      _orderPayment.amount = null;
      _orderPayment.payment_type = 'job';
      if (cashinfo.amountExpenses) {
        _orderPayment.amount = String(Number(cashinfo.amount) - Number(cashinfo.amountExpenses));
      } else {
        _orderPayment.amount = cashinfo.amount;
      }
      saveItem('order-payments', _orderPayment, null).then((response) => {
        if (response.data.error) {
          if (Array.isArray(response.data.error)) {
            BSwalShowError(response.data.error);
          } else if (response.data.error.user_id) {
            BSwalShowError(['This order is not assigned to any employee yet.']);
          } else {
            BSwalShowError([response.data.error]);
          }
        } else if (localStorage.getItem('isAdmin') === 'true') {
          history.push(`/order-management/order/${currentOrder.id}`);
        } else {
          history.push(`/employees/order/${currentOrder.id}`);
        }
      });
    }
  };
  const saveCreditPayment = async (cardNumber, url, pid) => {
    const _orderPayment = { ...orderPayment };
    _orderPayment.user_id = currentOrder.user_id;
    _orderPayment.order_id = currentOrder.id;
    _orderPayment.payment_type_id = 2;
    if (creditInfo.amountExpenses) {
      _orderPayment.amount = null;
      _orderPayment.payment_type = 'expenses';
      _orderPayment.amount = creditInfo.amountExpenses;
      _orderPayment.cc_number = cardNumber;
      _orderPayment.receipt_url = url;
      _orderPayment.payment_id = pid;
      _orderPayment.percent_amount = (4 * Number(creditInfo.amountExpenses)) / 100;
      await saveItem('order-payments', _orderPayment, null).then((response) => {
        if (response.data.error) {
          BSwalShowError(response.data.error);
        } else if (!cashinfo.amount) {
          if (localStorage.getItem('isAdmin') === 'true') {
            history.push(`/order-management/order/${currentOrder.id}`);
          } else {
            history.push(`/employees/order/${currentOrder.id}`);
          }
        }
      });
    }
    if (creditInfo.amount) {
      _orderPayment.amount = null;
      _orderPayment.payment_type = 'job';
      if (creditInfo.amountExpenses) {
        _orderPayment.amount = String(Number(creditInfo.amount) - Number(creditInfo.amountExpenses));
      } else {
        _orderPayment.amount = creditInfo.amount;
      }
      _orderPayment.amount = creditInfo.amount;
      _orderPayment.cc_number = cardNumber;
      _orderPayment.receipt_url = url;
      _orderPayment.payment_id = pid;
      _orderPayment.percent_amount = (4 * Number(creditInfo.amount)) / 100;
      saveItem('order-payments', _orderPayment, null).then((response) => {
        if (response.data.error) {
          BSwalShowError(response.data.error);
        } else if (localStorage.getItem('isAdmin') === 'true') {
          window.open(url, '_blank');
          history.push(`/order-management/order/${currentOrder.id}`);
        } else {
          window.open(url, '_blank');
          history.push(`/employees/order/${currentOrder.id}`);
        }
      });
    }
  };

  const backToOrder = () => {
    if (localStorage.getItem('isAdmin') === 'true') {
      history.push(`/order-management/order/${id}`);
    } else {
      history.push(`/employees/order/${id}`);
    }
  };

  const handlePaymentMethodResult = async (result) => {
    const _obj = {
      amount: Number(creditTotalPayment) * 100,
      payment_token: result?.token,
    };
    saveItem('square-payment', _obj, null).then((data) => {
      if (data?.data?.payment?.id) {
        // saveCreditPayment(result?.details?.card?.last4);
        saveItem('square-payment-get', { payment_id: data?.data?.payment?.id }, null).then((response) => {
          if (data?.data?.payment?.receipt_url) {
            saveCreditPayment(result?.details?.card?.last4, data?.data?.payment?.receipt_url, data?.data?.payment?.id);
            BSwalCustom('Payment is done successfully.', null, 'success');
          }
          if (response.data.error) {
            BSwalShowError(response.data.error);
          }
        });
      } else if (data?.data[0]?.detail) {
        BSwalShowError([data?.data[0]?.detail]);
      }
    });
  };
  return (
    <>
      <h3 style={{ padding: '20px', paddingBottom: 0, marginBottom: 0 }}>Payment</h3>
      <Button
        id='back'
        onClick={backToOrder}
        color='secondary'
        variant='outlined'
        style={{ marginTop: '10px', marginBottom: '10px' }}
      >
        <ArrowBackIcon />
      </Button>
      <Tabs onChange={changeTab} type='card'>
        <TabPane tab='Credit card' key='credit'>
          <div style={{ padding: '10px', minHeight: '570px', paddingTop: 0 }}>
            <BTextField
              class='resizeInput'
              label='Amount'
              name='amount'
              value={creditInfo.amount}
              onChange={onChange}
              onKeyDown={onKeyDownHandlerOnlyAcceptIntegerEnglish}
              readOnly={payingCredit}
            />
            <BTextField
              class='resizeInput'
              label='Expenses Amount'
              name='amountExpenses'
              value={creditInfo.amountExpenses}
              onChange={onChange}
              onKeyDown={onKeyDownHandlerOnlyAcceptIntegerEnglish}
              readOnly={payingCredit}
            />
            {(creditInfo.amount || creditInfo.amountExpenses) && (
              <div style={{ marginTop: '10px' }}>
                <span>4% fee is added to the amount. The total payment will be $ {creditTotalPayment}</span>
              </div>
            )}
            <Popconfirm
              title={`Are you sure you to pay $ ${creditTotalPayment}?`}
              onConfirm={payCredit}
              // onCancel={cancel}
              okText='Yes'
              cancelText='No'
              placement='right'
            >
              <Button
                id='payCredit'
                onClick={(e) => e.preventDefault()}
                color='secondary'
                variant='outlined'
                style={{ marginTop: '10px', background: '#00a2ea57' }}
                disabled={payingCredit}
              >
                Pay
              </Button>
            </Popconfirm>
            {payingCredit && (
              <div style={{ maxWidth: '500px', marginTop: '10px' }}>
                <PaymentForm
                  applicationId={appId}
                  locationId='L6PV6VQR75ZZP'
                  cardTokenizeResponseReceived={(token) => {
                    handlePaymentMethodResult(token);
                  }}
                >
                  <CreditCard />
                </PaymentForm>
              </div>
            )}
            {/* <BTextField
              label='Card Number'
              name='cardNumber'
              value={creditInfo.cardNumber}
              onChange={onChange}
              onKeyDown={onKeyDownHandlerOnlyAcceptIntegerEnglish}
            />
            <BTextField
              label='Name on Card'
              name='cardName'
              value={creditInfo.cardName}
              onChange={onChange}
              onKeyDown={onKeyDownHandlerOnlyAcceptEnglishCharacterAndNumbers}
            />
            <DatePicker picker='month' style={{ marginTop: '10px' }} />
            <BTextField
              type='password'
              name='code'
              label='Security Code'
              value={creditInfo?.code}
              onChange={onChange}
            /> */}
          </div>
        </TabPane>
        <TabPane tab='Cash' key='cash'>
          <div style={{ padding: '10px' }}>
            <BTextField
              label='Amount'
              name='amount'
              value={cashinfo.amount}
              onChange={onChangeCash}
              onKeyDown={onKeyDownHandlerOnlyAcceptIntegerEnglish}
            />
            <BTextField
              label='Expenses Amount'
              name='amountExpenses'
              value={cashinfo.amountExpenses}
              onChange={onChangeCash}
              onKeyDown={onKeyDownHandlerOnlyAcceptIntegerEnglish}
            />
            <Popconfirm
              title={`Are you sure you to pay $ ${
                cashinfo.amount ? Number(cashinfo.amount) : Number(cashinfo.amountExpenses)
              }?`}
              onConfirm={payCash}
              okText='Yes'
              cancelText='No'
              placement='right'
            >
              <Button
                id='payCash'
                onClick={(e) => e.preventDefault()}
                color='secondary'
                variant='outlined'
                style={{ marginTop: '10px', background: '#00a2ea57' }}
                disabled={!cashinfo.amount && !cashinfo.amountExpenses}
              >
                Pay
              </Button>
            </Popconfirm>
          </div>
        </TabPane>
      </Tabs>
      {/* <div>Test Info:</div>
      <div>Credit Card No: 4111 1111 1111 1111</div>
      <div>CVC: 111</div>
      <div>expiary: 12/32</div>
      <div>postal code: 12345</div> */}
    </>
  );
};

export default Payment;
