import React, { useEffect, useState } from 'react';
import moment from 'moment';
import createPersistedState from 'btechn01-use-persisted-state';
import { Grid } from '@material-ui/core';
import BDateFieldTable from '../inputs/forms/BDateFieldTable';
import BTextField from '../inputs/forms/BTextField';
import BSelectField from '../inputs/forms/BSelectField';

const initialDateRange = { from: '', to: '' };
const useDateRangeState = createPersistedState('dateRange');

const useTable = ({ columns = [] }) => {
  const [count, setCount] = useState(0);
  const [, setUpdatedOn] = useDateRangeState(initialDateRange);
  const [, setCreatedOn] = useDateRangeState(initialDateRange);
  const [parentColumns, setParentColumns] = useState([
    ...columns.map((column) => {
      const item = { ...column };
      if (!item.filterComponent) {
        if (item.type === 'date') {
          item.width = 130;
          item.maxWidth = 130;
          item.filterComponent = (props) => (
            <BDateFieldTable
              sm={12}
              xs={12}
              max={item.max || undefined}
              name={props.columnDef.field}
              value={props.columnDef.tableData.filterValue ? props.columnDef.tableData.filterValue : null}
              onChange={(c) => props.onFilterChanged(props.columnDef.tableData.id, c.currentTarget.value)}
            />
          );
        } else if (item.field === 'updatedOn' || item.field === 'createdOn') {
          item.width = 180;
          item.maxWidth = 180;
          // eslint-disable-next-line react/display-name
          item.filterComponent = (props) => (
            <>
              <Grid container spacing={1}>
                <BDateFieldTable
                  sm={6}
                  xs={12}
                  placeholder='From'
                  max={moment().format('MM-DD-YYYY')}
                  // value={updatedOn.from}
                  name={`${props.columnDef.field}From`}
                  onChange={(c) => {
                    if (c.currentTarget.value) {
                      setUpdatedOn({
                        ...JSON.parse(localStorage.getItem('dateRange')),
                        from: c.currentTarget.value,
                      });
                      props.onFilterChanged(
                        props.columnDef.tableData.id,
                        JSON.parse(localStorage.getItem('dateRange')),
                      );
                    } else {
                      setUpdatedOn(initialDateRange);
                      props.onFilterChanged(props.columnDef.tableData.id, initialDateRange);
                    }
                  }}
                />
                <BDateFieldTable
                  sm={6}
                  xs={12}
                  min={
                    JSON.parse(localStorage.getItem('dateRange'))?.from
                      ? JSON.parse(localStorage.getItem('dateRange'))?.from
                      : undefined
                  }
                  max={moment()}
                  placeholder='To'
                  // value={updatedOn.to}
                  name={`${props.columnDef.field}To`}
                  onChange={(c) => {
                    if (c.currentTarget.value) {
                      setUpdatedOn({
                        ...JSON.parse(localStorage.getItem('dateRange')),
                        to: c.currentTarget.value,
                      });
                      props.onFilterChanged(
                        props.columnDef.tableData.id,
                        JSON.parse(localStorage.getItem('dateRange')),
                      );
                    } else {
                      setUpdatedOn(initialDateRange);
                      props.onFilterChanged(props.columnDef.tableData.id, initialDateRange);
                    }
                  }}
                />
              </Grid>
            </>
          );
        } else if (!item.lookup && item.type !== 'boolean') {
          // eslint-disable-next-line react/display-name
          item.filterComponent = (props) => (
            <BTextField
              sm={12}
              name={props.columnDef.field}
              value={props.columnDef.tableData.filterValue ? props.columnDef.tableData.filterValue : null}
              onChange={(c) => props.onFilterChanged(props.columnDef.tableData.id, c.currentTarget.value)}
            />
          );
        } else if (item.lookup) {
          item.minWidth = 150;
          item.filterComponent = (props) => (
            <BSelectField
              sm={12}
              name={props.columnDef.field}
              value={props.columnDef.tableData.filterValue ? props.columnDef.tableData.filterValue : null}
              onChange={(c) => props.onFilterChanged(props.columnDef.tableData.id, c.currentTarget.value)}
              items={item.lookup}
            />
          );
        }
      }
      return item;
    }),
  ]);

  useEffect(() => {
    setUpdatedOn(initialDateRange);
    setCreatedOn(initialDateRange);
    return () => {
      setUpdatedOn(initialDateRange);
      setCreatedOn(initialDateRange);
    };
  }, []);

  return { parentColumns, setParentColumns, count, setCount };
};

export default useTable;
