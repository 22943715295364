import React, { useState, useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { UserCredentialsContext } from '../../components/context/LoginContext';
import { DashboardPanelContext } from '../../components/context/DashboardContext';

import { TotalUsers, TotalProfit, LatestOrders } from './components';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
}));

const Dashboard = () => {
  const classes = useStyles();
  const [mainPermission, setMainPermission] = useState(null);

  const { credentials } = useContext(UserCredentialsContext);
  const { fetchItem } = useContext(DashboardPanelContext);
  const [clientsLength, setClientsLength] = useState([]);
  const [orders, setLatestOrders] = useState([]);

  useEffect(() => {
    if (credentials.access_token) {
      fetchItem({
        modelName: `/profile`,
        showLoader: true,
      }).then((response) => {
        if (response.is_admin === '1') {
          setMainPermission('ADMIN');
        } else {
          setMainPermission('EMPLOYEE');
        }
      });
      fetchItem({
        modelName: `/dashboard`,
        showLoader: true,
      }).then((response) => {
        if (response) {
          setClientsLength(response?.data?.clients);
          const _orders = [];
          for (let i = 0; i < 5; i++) {
            if (response?.data.orders) {
              _orders.push(response?.data.orders[i]);
            }
          }
          setLatestOrders(_orders);
        }
      });
    }
  }, [credentials]);

  return (
    <div className={classes.root} data-testid='dashboard'>
      {mainPermission && mainPermission === 'ADMIN' && (
        <Grid container spacing={4}>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <TotalUsers totals={clientsLength} />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <TotalProfit />
          </Grid>
          <Grid item lg={8} md={12} xl={9} xs={12}>
            <LatestOrders latestorders={orders} />
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default Dashboard;
