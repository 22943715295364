import React from 'react';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
}));
const ListsContainer = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.root}>{Array.isArray(children) ? children.map((c) => c) : children}</div>;
};

export default ListsContainer;
