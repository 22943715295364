import { createTheme } from '@material-ui/core';

import palette from './palette';
import typography from './typography';
// import overrides from "./overrides";
const boxShadow = '0 0 3px 0 rgba(0, 0, 0, 0.5) !important';
// .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]
const theme = createTheme({
  direction: 'ltr',
  palette,
  typography,
  // overrides,
  overrides: {
    MuiTab: {
      wrapper: {
        '&:first-child': {
          '&:first-child': {
            fontFamily: 'Arial !important',
          },
        },
      },
    },
    MuiChip: {
      deleteIcon: {
        margin: '0 5px',
      },
    },
    MuiTableRow: {
      root: {
        // firstchild
      },
    },
    MuiTableCell: {
      root: {
        fontFamily: 'Arial !important',
        padding: '4px 2px',
        '&:first-child': {
          paddingLeft: 5,
        },
      },
    },
    MuiTableSortLabel: {
      active: {
        color: 'white !important',
      },
      icon: {
        color: 'white !important',
      },
    },
    MuiInputLabel: {
      root: {
        marginTop: 15,
        marginBottom: 10,
      },
    },
    MuiInputBase: {
      root: {
        '&$disabled': {
          background: '#dddddd !important',
          borderRadius: 8,
        },
      },
    },
    MuiTextField: {
      root: {},
    },
    MuiButton: {
      outlinedPrimary: {},
      outlined: { boxShadow },
    },
    MuiStepper: {
      root: {
        boxShadow,
        marginBottom: 15,
      },
    },
    MuiTypography: {
      root: {
        // marginRight: 2,
      },
    },
    MuiTable: {
      root: {},
    },
    MuiOutlinedInput: {
      inputAdornedEnd: {
        // paddingRight: 15,
      },
      adornedStart: {
        paddingLeft: 0,
      },
      adornedEnd: {
        paddingRight: 0,
      },
      input: {
        padding: '18.5px 4px 18.5px 6px',
      },
      disabled: {},
      inputAdornedStart: {
        paddingLeft: 6,
      },
      notchedOutline: {
        border: 'none',
        borderRadius: 8,
        boxShadow,
      },
    },
    MuiFormControlLabel: {
      root: {
        marginLeft: 0,
      },
    },
    MuiFormHelperText: {},
    MuiCard: {
      root: {
        boxShadow,
      },
    },
    MuiSelect: {
      icon: {},
      outlined: {
        // textAlign: "right",
        // paddingRight: "20px !important",
      },
      iconOutlined: {
        // left: 10,
        // right: "auto !important",
      },
    },
    MuiAppBar: {
      root: {
        // backgroundColor: '#000!important',
      },
    },
    MuiPaper: {
      root: {
        boxShadow,
      },
    },
    MuiAutocomplete: {
      inputRoot: {
        paddingRight: '3px !important',
        paddingLeft: '0px !important',
      },
      endAdornment: {
        // marginLeft: 0,
        // right: "auto !important",
      },
    },
    MuiInputAdornment: {
      // positionEnd: { marginLeft: -40 },
      positionStart: {
        marginRight: 3,
      },
    },
    MuiCardActions: {
      root: {
        padding: 16,
      },
    },
  },
  zIndex: {
    appBar: 1200,
    drawer: 1100,
  },
});
export default theme;
