import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { useForm } from 'react-hook-form';
import { DashboardPanelContext } from '../../../../components/context/DashboardContext';
import Table from '../../../../components/tables/Table';
import { SET_VIEW_READ_ONLY } from '../../../../components/context/reducer/actionTypes';
import { convertArrayToKeyedObject } from '../../../../common/utilities';
import useHasPermission from '../../../../hooks/useHasPermission';
import ListsContainer from '../../../../components/ListsContainer/ListsContainer';
import { BSwalShowError } from '../../../../common/BSwal';
import EnabledColumn from '../../../../components/EnabledColumn/EnabledColumn';
import EditComponentCheckbox from '../../../../components/EditComponentCheckbox';
import BSubmitButton from '../../../../components/buttons/SubmitButton';

const ZipsList = () => {
  const history = useHistory();
  const [pageSize, setPageSize] = useState(10);
  const tableRef = useRef(null);
  const { handleSubmit } = useForm();
  const { globalDispatch, fetchItem, saveItem } = useContext(DashboardPanelContext);
  const [columns, setColumns] = useState([]);
  const [key, setKey] = useState([]);
  const [data, setData] = useState([]);
  const [areas, setAreas] = useState([]);
  const [deletedIds, setDeletedIds] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchItem({
      modelName: `/zips`,
      showLoader: true,
    }).then((response) => {
      if (response) {
        const res1 = [];
        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].status === '1') {
            res1.push(response.data[i]);
          }
        }
        setData(res1);
      }
    });
    fetchItem({
      modelName: `/areas`,
      showLoader: true,
    }).then((response) => {
      if (response) {
        const res1 = [];
        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].status === '1') {
            res1.push(response.data[i]);
          }
        }
        setAreas(res1);
      }
    });
  }, []);

  useEffect(() => {
    setColumns([
      {
        title: 'Zip Code',
        field: 'code',
        filtering: false,
      },
      {
        title: 'Area',
        field: 'area_id',
        filtering: false,
        lookup: convertArrayToKeyedObject(areas, 'id', 'title'),
      },
      {
        title: 'Status',
        field: 'status',
        type: 'boolean',
        render: EnabledColumn,
        editComponent: EditComponentCheckbox,
        filtering: false,
        sorting: false,
      },
    ]);
    setKey(key + 1);
  }, [data, areas]);

  const actions = [
    {
      icon: 'edit',
      tooltip: 'Edit',
      iconProps: {
        id: `edit-${data?.tableData?.id}`,
      },
      onClick: async (event, rowData) => {
        if (globalDispatch) globalDispatch({ type: SET_VIEW_READ_ONLY, value: false });
        history.push(`/order-management/zip/${rowData.id}`);
      },
      position: 'row',
    },
  ];

  const handleCreate = () => {
    globalDispatch({ type: SET_VIEW_READ_ONLY, value: false });
    history.push('/order-management/zip');
  };

  const onRowClick = (event, rowData) => {
    globalDispatch({ type: SET_VIEW_READ_ONLY, value: true });
    history.push(`/order-management/zip/${rowData.id}`);
  };

  const onSelectionChanging = (currentlySelectedRows) => {
    if (currentlySelectedRows) {
      setDeletedIds(currentlySelectedRows);
    }
  };

  const disableZips = async () => {
    setLoading(true);
    for (let i = 0; i < deletedIds.length; i++) {
      const zip = { ...deletedIds[i] };
      zip.status = '0';
      // eslint-disable-next-line no-await-in-loop
      await saveItem('zips', zip, deletedIds[i].id)
        .then((response) => {
          if (response.data.error) {
            BSwalShowError(response.data.error?.code);
          }
        })
        .catch((err) => BSwalShowError(err?.data?.messages));
    }
    setLoading(false);
    window.location.reload();
  };

  return (
    <ListsContainer>
      <form className='formContainer' onSubmit={handleSubmit(disableZips)}>
        <Grid item xs={12} sm={2}>
          <BSubmitButton id='disableZip' label='Disable Zips' loading={loading} disabled={deletedIds.length === 0} />
        </Grid>
        <br />
        <Table
          key={key}
          title='Zip Code List'
          columns={columns}
          createSlug='Add Zip Code'
          exportCsv={false}
          actions={actions}
          createAction={useHasPermission('ADMIN') ? handleCreate : undefined}
          tableRef={tableRef}
          data={data}
          onRowClick={onRowClick}
          onChangeRowsPerPage={(pgSize) => {
            setPageSize(pgSize);
          }}
          pageSize={pageSize}
          filtering
          sortableColumns
          selection
          onSelectionChange={onSelectionChanging}
          selectionProps
        />
      </form>
    </ListsContainer>
  );
};

export default ZipsList;
