import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { Button, Card, CardActions, CardContent, CardHeader, Divider } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import clsx from 'clsx';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import { Link, useHistory } from 'react-router-dom';
import { DashboardPanelContext } from '../../../../components/context/DashboardContext';
import BSubmitButton from '../../../../components/buttons/SubmitButton';
import { REQUIRED_STRING } from '../../../../constants/HookForm';
import BTextField from '../../../../components/inputs/forms/BTextField';
import {
  setValues,
  onChangeForBComponents,
  onKeyDownHandlerOnlyAcceptIntegerEnglishDontAcceptSpace,
} from '../../../../common/utilities';
import { BSwalShowError } from '../../../../common/BSwal';
import BSelectField from '../../../../components/inputs/forms/BSelectField';
import useHistoryForms from '../../../../hooks/useHistoryForms';
import BHistoryNavigator from '../../../../components/BHistoryNavigator';
import useBasicInformationMountedEffect from '../../../../hooks/useBasicInformationMountedEffect';
import BCheckBoxField from '../../../../components/inputs/forms/BCheckBoxField';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  customInputFormControl: {
    width: '100%',
  },
  formLabel: {
    fontSize: 16,
    marginBottom: 10,
  },
}));
const initialInterval = {
  min: '',
  max: '',
  min_type: 0,
  max_type: 0,
  status: true,
};

const IntervalPage = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const { staticContext, ...rest } = props;
  const { globalState, saveItem } = useContext(DashboardPanelContext);

  const { viewReadOnly } = globalState;
  const [loading, setLoading] = useState(false);
  const { errors, handleSubmit, setValue, register } = useForm();

  const {
    fetchData,
    prevHistory,
    nextHistory,
    HistoryId,
    historyIdList,
    historyStep,
    history: fetchedHistory,
    historyInfo,
  } = useHistoryForms({
    location: props.location,
    route: '/order-management/intervals',
  });

  const {
    id,
    item: currentInterval,
    setItem: setCurrentInterval,
  } = useBasicInformationMountedEffect({
    match: props.match,
    historyIdList,
    fetchItemURL: '/intervals',
    localStorageKey: 'intervals',
    initialItem: initialInterval,
    fetchData,
    historyId: HistoryId,
  });

  useEffect(() => {
    if (!viewReadOnly) {
      register(
        { name: 'min' },
        {
          required: REQUIRED_STRING,
        },
      );
      register(
        { name: 'max' },
        {
          required: REQUIRED_STRING,
        },
      );
    }
  }, []);

  useEffect(() => {
    if (currentInterval.data) {
      const _interval = {
        min: String(currentInterval.data.min).replace('AM', '').replace('PM', ''),
        max: String(currentInterval.data.max).replace('AM', '').replace('PM', ''),
        min_type: currentInterval.data.min.includes('AM') ? 0 : 1,
        max_type: currentInterval.data.max.includes('AM') ? 0 : 1,
      };
      setCurrentInterval(_interval);
    }
    if (currentInterval) {
      setValues(setValue, currentInterval);
    }
  }, [currentInterval]);

  useEffect(() => {
    if (fetchedHistory && historyIdList.length !== 0) {
      setCurrentInterval(fetchedHistory);
    }
  }, [fetchedHistory]);
  const onSubmit = () => {
    setLoading(true);
    if (currentInterval.status) {
      currentInterval.status = '1';
    } else {
      currentInterval.status = '0';
    }
    const interval = {
      min: String(currentInterval.min)
        .concat(' ')
        .concat(String(currentInterval.min_type) === String(0) ? 'AM' : 'PM'),
      max: String(currentInterval.max)
        .concat(' ')
        .concat(String(currentInterval.max_type) === String(0) ? 'AM' : 'PM'),
    };
    saveItem('intervals', interval, id)
      .then(() => history.push('/order-management/intervals'))
      .catch((err) => BSwalShowError(err?.data?.messages))
      .finally(() => setLoading(false));
  };

  const onChange = (e) => {
    onChangeForBComponents(e, setValue, currentInterval, setCurrentInterval);
  };

  return (
    <div className={classes.root} style={{ margin: 10 }}>
      {currentInterval && (
        <Card {...rest} className={clsx(classes.root)}>
          <Button
            id='backToListIcon'
            component={Link}
            to={{
              pathname: '/order-management/intervals',
            }}
            color='secondary'
            variant='outlined'
            style={{ float: 'right', marginTop: '10px', marginRight: '10px' }}
          >
            <ArrowBackIcon />
          </Button>
          <form className='formContainer' onSubmit={handleSubmit(onSubmit)}>
            <CardHeader title='Visit Interval Information' />
            <Divider />
            <CardContent>
              <br />
              {historyInfo}
              <Grid container spacing={3}>
                <BTextField
                  sm={6}
                  label='From (Hour)'
                  name='min'
                  value={currentInterval?.min}
                  onChange={onChange}
                  readOnly={viewReadOnly}
                  onKeyDown={onKeyDownHandlerOnlyAcceptIntegerEnglishDontAcceptSpace}
                  error={errors.min}
                  required
                />
                <BSelectField
                  sm={6}
                  xs={12}
                  label='AM/PM'
                  required
                  name='min_type'
                  value={currentInterval.min_type}
                  onChange={onChange}
                  error={errors.min_type}
                  items={['AM', 'PM']}
                  readOnly={viewReadOnly}
                />
                <BTextField
                  label='To (Hour)'
                  sm={6}
                  name='max'
                  value={currentInterval?.max}
                  onChange={onChange}
                  readOnly={viewReadOnly}
                  onKeyDown={onKeyDownHandlerOnlyAcceptIntegerEnglishDontAcceptSpace}
                  error={errors.max}
                  required
                />
                <BSelectField
                  sm={6}
                  xs={12}
                  required
                  name='max_type'
                  label='AM/PM'
                  value={currentInterval.max_type}
                  onChange={onChange}
                  error={errors.max_type}
                  items={['AM', 'PM']}
                  readOnly={viewReadOnly}
                />
                <BCheckBoxField
                  sm={12}
                  name='status'
                  label='Enabled Status'
                  value={Number(currentInterval?.status)}
                  error={errors.status}
                  onChange={onChange}
                  readOnly={viewReadOnly}
                />
              </Grid>
            </CardContent>
            <Divider />
            <CardActions>
              <Grid item xs={12} sm={6}>
                {!viewReadOnly && <BSubmitButton id='submitEmployee' label='Save Interval' loading={loading} />}
              </Grid>
              <Grid item xs={12} sm={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  id='backToList'
                  component={Link}
                  to={{
                    pathname: '/order-management/intervals',
                  }}
                  color='secondary'
                  variant='outlined'
                >
                  Back to Visit Interval list
                </Button>
              </Grid>
            </CardActions>
          </form>
          <BHistoryNavigator
            historyStep={historyStep}
            historyIdList={historyIdList}
            prevHistory={prevHistory}
            nextHistory={nextHistory}
          />
        </Card>
      )}
    </div>
  );
};

IntervalPage.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object,
};

export default IntervalPage;
