import React from 'react';
import { StylesProvider } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { ExportPdf } from '@material-table/exporters';
import MaterialTable, { MTableBodyRow, MTableCell } from '@material-table/core';
import '../../assets/scss/generalStyles/_fonts.scss';
import { FilterList } from '@material-ui/icons';
import AutoRenewIcon from '@material-ui/icons/Autorenew';
import { font, primaryMainColor, whiteColor } from '../configs/Variables';
import SlidingButton from '../buttons/Sliding';
import './table.css';
import { BMaterialTableLocalization } from '../../constants/MaterialTable';
import useTable from './useTable';

function RTL(props) {
  return <StylesProvider>{props.children}</StylesProvider>;
}

RTL.propTypes = {
  children: PropTypes.node.isRequired,
};

const Table = ({
  tableRef,
  columns = [],
  data,
  name,
  filtering = true,
  onChangePage,
  onChangeRowsPerPage,
  onRowClick,
  pageSize = 10,
  exportMenu = false,
  actions,
  title,
  createSlug,
  location,
  createAction,
  sortableColumns,
  onSelectionChange = undefined,
  selection = false,
  paging = false,
  selectionProps = false,
  key = 0,
  totalCount = 0,
  page = 1,
  isLoading = false,
}) => {
  const { parentColumns, setParentColumns, count, setCount } = useTable({ columns });
  return (
    <RTL>
      <MaterialTable
        id={name}
        data-testid={name}
        onSelectionChange={onSelectionChange}
        key={count + key}
        tableRef={tableRef}
        title={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <h1 style={{ margin: 0, fontFamily: font, fontSize: 15 }}>{title}</h1>
            {createSlug && createAction && (
              <SlidingButton
                style={{ marginRight: 150, marginLeft: 25 }}
                label={createSlug}
                location={location}
                onClick={createAction}
                id='newItem'
              />
            )}
          </div>
        }
        options={{
          paging,
          selection,
          selectionProps: selectionProps
            ? (rowData) => ({
                disabled: rowData?.order_status?.title === 'Paid' || rowData.status === '0',
                color: 'primary',
              })
            : null,
          pageSizeOptions: [10],
          draggable: false,
          pageSize,
          columnsButton: sortableColumns,
          debounceInterval: 500,
          search: false,
          filtering,
          exportAllData: true,
          searchFieldStyle: {
            fontFamily: font,
          },
          rowStyle: {
            fontFamily: font,
            textAlign: 'left',
          },
          cellStyle: {
            textAlign: 'left',
          },
          actionsColumnIndex: -1,
          headerStyle: {
            fontFamily: font,
            backgroundColor: primaryMainColor,
            color: whiteColor,
            direction: 'ltr',
            textAlign: 'left',
          },
          toolbar: true,
          exportButton: true,
          exportMenu: exportMenu
            ? [
                {
                  label: 'Export PDF',
                  exportFunc: (cols, datas) => ExportPdf(cols, datas, 'Payments'),
                },
              ]
            : [],
        }}
        icons={{
          Filter: FilterList,
          search: true,
        }}
        columns={parentColumns}
        data={data}
        page={page}
        actions={[
          ...actions,
          filtering && {
            icon: () => <AutoRenewIcon id='reload-table' />,
            tooltip: 'Remove all the filters',
            iconProps: {
              id: `reload-table`,
            },
            isFreeAction: true,
            onClick: () => {
              setParentColumns((prevState) => [
                ...prevState.map((c) => ({
                  ...c,
                  tableData: { ...c.tableData, filterValue: null },
                })),
              ]);
              setCount(count + 1);
              // tableRef.current.onQueryChange();
            },
          },
        ]}
        onPageChange={onChangePage}
        onRowsPerPageChange={onChangeRowsPerPage}
        onRowClick={onRowClick || (() => {})}
        totalCount={totalCount}
        isLoading={isLoading}
        localization={BMaterialTableLocalization}
        components={{
          Row: (props) => <MTableBodyRow id={`row-${props?.index}`} {...props} />,
          Cell: (props) => (
            <MTableCell id={`cell-${props?.columnDef.field}-${props?.rowData.tableData.id}`} {...props} />
          ),
        }}
      />
    </RTL>
  );
};

Table.propTypes = {
  tableRef: PropTypes.any,
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  name: PropTypes.string,
  filtering: PropTypes.bool,
  onChangePage: PropTypes.func,
  onChangeRowsPerPage: PropTypes.func,
  onRowClick: PropTypes.func,
  pageSize: PropTypes.number,
  actions: PropTypes.array,
  title: PropTypes.string,
  createSlug: PropTypes.string,
  location: PropTypes.any,
  createAction: PropTypes.func,
  sortableColumns: PropTypes.bool,
};

export default Table;
