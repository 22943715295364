import React, { useContext, useEffect, useState, useRef } from 'react';
import { DatePicker, Tabs } from 'antd';
import moment from 'moment';
import { Card, CardContent } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { DashboardPanelContext } from '../../../../components/context/DashboardContext';
import EmployeePaymentTable from './EmployeePaymentTable';
import { UserCredentialsContext } from '../../../../components/context/LoginContext';

const EmployeePayment = () => {
  const { TabPane } = Tabs;
  const { credentials } = useContext(UserCredentialsContext);
  const [weekValue, setWeekValue] = useState(moment().weekday(0));
  const [loading, setLoading] = useState(false);
  const { fetchItem } = useContext(DashboardPanelContext);
  const [paymentData, setPaymentData] = useState({});
  const [tableKey, setTableKey] = useState([]);
  const [key, setKey] = useState('mon');
  const [employeeId, setEmployeeId] = useState(null);
  const tableRef = useRef(null);
  const orderColumns = [
    {
      title: 'Order Number',
      field: 'order_number',
      sorting: false,
      filtering: false,
    },
    {
      title: 'Expenses',
      field: 'expenses',
      sorting: false,
      filtering: false,
    },
    {
      title: 'Travel Price',
      field: 'travel_price',
      sorting: false,
      filtering: false,
    },
    {
      title: 'Bonus',
      field: 'bonus',
      sorting: false,
      filtering: false,
    },
    {
      title: 'Total Price',
      field: 'total_price',
      sorting: false,
      filtering: false,
    },
    {
      title: 'Status',
      field: 'order_status',
      sorting: false,
      filtering: false,
    },
  ];
  useEffect(() => {
    if (credentials.access_token) {
      fetchItem({
        modelName: `/profile`,
        showLoader: true,
      }).then((response) => {
        setEmployeeId(response.id);
      });
    }
  }, []);
  useEffect(() => {
    if (weekValue) {
      setLoading(true);
      const startDate = moment(weekValue).format('YYYY-MM-DD');
      const endDate = moment(weekValue, 'YYYY-MM-DD').add(5, 'days').format('YYYY-MM-DD');
      fetchItem({
        modelName: `/orders?from_date=${startDate}&to_date=${endDate}&sort=asc`,
        showLoader: true,
      })
        .then((response) => {
          if (response) {
            const _payment = {
              mon: {
                total: 0,
                unpaid: 0,
                paid: 0,
                open: 0,
                orders: [],
              },
              tue: {
                total: 0,
                unpaid: 0,
                paid: 0,
                open: 0,
                orders: [],
              },
              wed: {
                total: 0,
                unpaid: 0,
                paid: 0,
                open: 0,
                orders: [],
              },
              thu: {
                total: 0,
                unpaid: 0,
                paid: 0,
                open: 0,
                orders: [],
              },
              fri: {
                total: 0,
                unpaid: 0,
                paid: 0,
                open: 0,
                orders: [],
              },
              sat: {
                total: 0,
                unpaid: 0,
                paid: 0,
                open: 0,
                orders: [],
              },
              sun: {
                total: 0,
                unpaid: 0,
                paid: 0,
                open: 0,
                orders: [],
              },
              all: {
                total: 0,
                unpaid: 0,
                paid: 0,
                open: 0,
                orders: [],
              },
            };
            for (let i = 0; i < response.data.length; i++) {
              response.data[i].order_status = response.data[i].order_status.title;
              response.data[i].bonus = response.data[i]?.bonus?.bonus_amount
                ? response.data[i]?.bonus?.bonus_amount
                : '';
            }
            for (let i = 0; i < response.data.length; i++) {
              if (String(employeeId) === String(response.data[i].user_id)) {
                _payment.all.orders.push(response.data[i]);
                _payment.all.total += response.data[i].total_price;
                if (response.data[i].order_status === 'Open') {
                  _payment.all.open += response.data[i].total_price;
                }
                if (response.data[i].order_status === 'Paid') {
                  _payment.all.paid += response.data[i].total_price;
                }
                if (moment(response.data[i].date)?.format('dddd').toLowerCase().slice(0, 3) === 'mon') {
                  _payment.mon.orders.push(response.data[i]);
                  _payment.mon.total += response.data[i].total_price;
                  if (response.data[i].order_status === 'Open') {
                    _payment.mon.open += response.data[i].total_price;
                  }
                  if (response.data[i].order_status === 'Paid') {
                    _payment.mon.paid += response.data[i].total_price;
                  }
                }
                if (moment(response.data[i].date)?.format('dddd').toLowerCase().slice(0, 3) === 'tue') {
                  _payment.tue.orders.push(response.data[i]);
                  _payment.tue.total += response.data[i].total_price;
                  if (response.data[i].order_status === 'Open') {
                    _payment.tue.open += response.data[i].total_price;
                  }
                  if (response.data[i].order_status === 'Paid') {
                    _payment.tue.paid += response.data[i].total_price;
                  }
                }
                if (moment(response.data[i].date)?.format('dddd').toLowerCase().slice(0, 3) === 'wed') {
                  _payment.wed.orders.push(response.data[i]);
                  _payment.wed.total += response.data[i].total_price;
                  if (response.data[i].order_status === 'Open') {
                    _payment.wed.open += response.data[i].total_price;
                  }
                  if (response.data[i].order_status === 'Paid') {
                    _payment.wed.paid += response.data[i].total_price;
                  }
                }
                if (moment(response.data[i].date)?.format('dddd').toLowerCase().slice(0, 3) === 'thu') {
                  _payment.thu.orders.push(response.data[i]);
                  _payment.thu.total += response.data[i].total_price;
                  if (response.data[i].order_status === 'Open') {
                    _payment.thu.open += response.data[i].total_price;
                  }
                  if (response.data[i].order_status === 'Paid') {
                    _payment.thu.paid += response.data[i].total_price;
                  }
                }
                if (moment(response.data[i].date)?.format('dddd').toLowerCase().slice(0, 3) === 'fri') {
                  _payment.fri.orders.push(response.data[i]);
                  _payment.fri.total += response.data[i].total_price;
                  if (response.data[i].order_status === 'Open') {
                    _payment.fri.open += response.data[i].total_price;
                  }
                  if (response.data[i].order_status === 'Paid') {
                    _payment.fri.paid += response.data[i].total_price;
                  }
                }
                if (moment(response.data[i].date)?.format('dddd').toLowerCase().slice(0, 3) === 'sat') {
                  _payment.sat.orders.push(response.data[i]);
                  _payment.sat.total += response.data[i].total_price;
                  if (response.data[i].order_status === 'Open') {
                    _payment.sat.open += response.data[i].total_price;
                  }
                  if (response.data[i].order_status === 'Paid') {
                    _payment.sat.paid += response.data[i].total_price;
                  }
                }
                if (moment(response.data[i].date)?.format('dddd').toLowerCase().slice(0, 3) === 'sun') {
                  _payment.sun.orders.push(response.data[i]);
                  _payment.sun.total += response.data[i].total_price;
                  if (response.data[i].order_status === 'Open') {
                    _payment.sun.open += response.data[i].total_price;
                  }
                  if (response.data[i].order_status === 'Paid') {
                    _payment.sun.paid += response.data[i].total_price;
                  }
                }
              }
            }
            _payment.all.unpaid = _payment.all.total - _payment.all.paid;
            _payment.mon.unpaid = _payment.mon.total - _payment.mon.paid;
            _payment.tue.unpaid = _payment.tue.total - _payment.tue.paid;
            _payment.wed.unpaid = _payment.wed.total - _payment.wed.paid;
            _payment.thu.unpaid = _payment.thu.total - _payment.thu.paid;
            _payment.fri.unpaid = _payment.fri.total - _payment.fri.paid;
            _payment.sat.unpaid = _payment.sat.total - _payment.sat.paid;
            _payment.sun.unpaid = _payment.sun.total - _payment.sun.paid;
            setPaymentData(_payment);
          }
        })
        .finally(() => {
          setLoading(false);
        });
      setTableKey(tableKey + 1);
    }
  }, [weekValue, employeeId]);
  const changeWeek = (e) => {
    if (e) {
      setWeekValue(moment(e));
    } else {
      setWeekValue(null);
    }
  };

  const changeTab = (k) => {
    setKey(k);
  };

  const customWeekStartEndFormat = (value) =>
    `${moment(value).startOf('week').format('MM/DD')} ~ ${moment(value).endOf('week').format('MM/DD')}`;

  return (
    <div style={{ paddingBottom: '40px' }}>
      <Card>
        <CardContent>
          <Grid container spacing={1}>
            <DatePicker
              size='default'
              picker='week'
              value={weekValue}
              onChange={changeWeek}
              disabled={loading}
              format={customWeekStartEndFormat}
            />
          </Grid>
          <br />
          <br />
          <Tabs onChange={changeTab} type='card'>
            <TabPane tab='Mon' key='mon'>
              <EmployeePaymentTable data={paymentData[key]} columns={orderColumns} tableRef={tableRef} key={tableKey} />
            </TabPane>
            <TabPane tab='Tue' key='tue'>
              <EmployeePaymentTable data={paymentData[key]} columns={orderColumns} tableRef={tableRef} key={tableKey} />
            </TabPane>
            <TabPane tab='Wed' key='wed'>
              <EmployeePaymentTable data={paymentData[key]} columns={orderColumns} tableRef={tableRef} key={tableKey} />
            </TabPane>
            <TabPane tab='Thu' key='thu'>
              <EmployeePaymentTable data={paymentData[key]} columns={orderColumns} tableRef={tableRef} key={tableKey} />
            </TabPane>
            <TabPane tab='Fri' key='fri'>
              <EmployeePaymentTable data={paymentData[key]} columns={orderColumns} tableRef={tableRef} key={tableKey} />
            </TabPane>
            <TabPane tab='Sat' key='sat'>
              <EmployeePaymentTable data={paymentData[key]} columns={orderColumns} tableRef={tableRef} key={tableKey} />
            </TabPane>
            <TabPane tab='Sun' key='sun'>
              <EmployeePaymentTable data={paymentData[key]} columns={orderColumns} tableRef={tableRef} key={tableKey} />
            </TabPane>
            <TabPane tab='All' key='all'>
              <EmployeePaymentTable data={paymentData[key]} columns={orderColumns} tableRef={tableRef} key={tableKey} />
            </TabPane>
          </Tabs>
        </CardContent>
      </Card>
    </div>
  );
};
export default EmployeePayment;
