import { DashboardPanelContextInterface } from './contextInterfaces';

const initContext: DashboardPanelContextInterface = {
  fetchList: () => undefined,
  fetchImage: async () => '',
  fetchItem: async () => null,
  fetchItemNoError: async () => null,
  search: async () => ({
    result: [],
    totalPages: 0,
    totalRows: 0,
  }),
  listReport: async () => null,
  fetchHistory: async () => null,
  autoComplete: async () => null,
  saveItem: async () => null,
  removeItem: async () => '',
  globalState: {
    language: 'en',
    currentDate: '',
    error: undefined,
    saveSuccess: false,
    isPanelOpen: true,
    itemLoading: false,
    pageLoading: false,
    itemsLoading: false,
    viewReadOnly: false,
    historyIdList: [],
    selectedMenuId: undefined,
    enums: {},
    currentCustomerPrimaryParameter: undefined,
    currentPersonnelPrimaryParameter: undefined,
    currentCustomerPrimaryParameterName: 'CITY',
    currentPersonnelPrimaryParameterName: 'CITY',
    isLoadingHistory: false,
    unreadCount: 0,
  },
  globalDispatch: () => undefined,
  initialStateZero: {},
};
export { initContext };
