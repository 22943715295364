import React, { useState, useContext, useEffect } from 'react';
import { Input, AutoComplete, List } from 'antd';
import { DashboardPanelContext } from '../../../components/context/DashboardContext';

const ChatList = ({ selectChatPage, selectChatPageSearch, key }) => {
  const [options, setOptions] = useState([]);
  const { fetchItem, globalState } = useContext(DashboardPanelContext);
  const [value, setValue] = useState('');
  const [chatOrderNumbers, setChatOrderNumbers] = useState([]);
  const [activeItem, setActiveItem] = useState(null);
  const [chatOrderId, setChatOrderId] = useState([]);
  const [msgCount, setMsgCount] = useState([0]);
  const { unreadCount } = globalState;

  useEffect(() => {
    if (localStorage.getItem('isAdmin') === 'true') {
      fetchItem({
        modelName: `/order-comments`,
        showLoader: true,
      }).then((response) => {
        let _res = [];
        if (response.data.length > 0) {
          for (let i = 0; i < response.data.length; i++) {
            if (response.data[i].order_number) {
              _res.push(response.data[i]);
            }
          }
        }
        _res = _res.reverse();
        const _chatOrderNumbers = [];
        const _id = [];
        for (let i = 0; i < _res.length; i++) {
          if (!_chatOrderNumbers.includes(_res[i].order_number) && _chatOrderNumbers.length < 50) {
            _chatOrderNumbers.push(_res[i].order_number);
            const obj = {};
            obj[_res[i].order_number] = _res[i].order_id;
            _id.push(obj);
          }
        }
        const _count = [];
        for (let j = 0; j < _chatOrderNumbers.length; j++) {
          _count.push(0);
        }
        for (let i = 0; i < _res.length; i++) {
          for (let j = 0; j < _chatOrderNumbers.length; j++) {
            if (
              _chatOrderNumbers[j] === _res[i].order_number &&
              String(_res[i].user_id) !== String(localStorage.getItem('uid')) &&
              _res[i].read === '0'
            ) {
              _count[j] += 1;
            }
          }
        }
        setChatOrderNumbers(_chatOrderNumbers);
        setChatOrderId(_id);
        setMsgCount(_count);
      });
    }
    if (localStorage.getItem('isAdmin') === 'false') {
      fetchItem({
        modelName: `/orders`,
        showLoader: true,
      }).then((res) => {
        fetchItem({
          modelName: `/order-comments`,
          showLoader: true,
        }).then((response) => {
          let _res = [];
          if (response.data.length > 0) {
            for (let i = 0; i < response.data.length; i++) {
              if (response.data[i].order_number) {
                _res.push(response.data[i]);
              }
            }
          }
          _res = _res.reverse();
          const _chatOrderNumbers = [];
          const _id = [];
          const allOrders = [];
          for (let j = 0; j < res.data.length; j++) {
            if (res.data[j].status === '1' && String(res.data[j].user_id) === String(localStorage.getItem('uid'))) {
              allOrders.push(res.data[j].order_number);
            }
          }
          for (let i = 0; i < _res.length; i++) {
            if (
              allOrders.includes(_res[i].order_number) &&
              !_chatOrderNumbers.includes(_res[i].order_number) &&
              _chatOrderNumbers.length < 50
            ) {
              _chatOrderNumbers.push(_res[i].order_number);
              const obj = {};
              obj[_res[i].order_number] = _res[i].order_id;
              _id.push(obj);
            }
          }
          const _count = [];
          for (let j = 0; j < _chatOrderNumbers.length; j++) {
            _count.push(0);
          }
          for (let i = 0; i < _res.length; i++) {
            for (let j = 0; j < _chatOrderNumbers.length; j++) {
              if (
                _chatOrderNumbers[j] === _res[i].order_number &&
                String(_res[i].user_id) !== String(localStorage.getItem('uid')) &&
                _res[i].read === '0'
              ) {
                _count[j] += 1;
              }
            }
          }
          setChatOrderNumbers(_chatOrderNumbers);
          setChatOrderId(_id);
          setMsgCount(_count);
        });
      });
    }
  }, [key, unreadCount]);

  const handleSearch = (query) => {
    if (query.length >= 4) {
      fetchItem({
        modelName: `/orders?order_number=${query}`,
        showLoader: true,
      }).then((response) => {
        const res = [];
        for (let i = 0; i < response.data.length; i++) {
          if (
            localStorage.getItem('isAdmin') === 'false' &&
            response.data[i].status === '1' &&
            String(localStorage.getItem('uid')) === String(response.data[i].user_id)
          ) {
            res.push({
              value: response.data[i]?.id,
              label: (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <span>{response.data[i]?.order_number}</span>
                </div>
              ),
            });
          }
          if (localStorage.getItem('isAdmin') === 'true') {
            res.push({
              value: response.data[i]?.id,
              label: (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <span>{response.data[i]?.order_number}</span>
                </div>
              ),
            });
          }
        }
        setOptions(res);
      });
    }
    // setOptions(value ? searchResult(value) : []);
  };
  const onSelect = (v) => {
    selectChatPageSearch(v);
    setValue('');
  };

  const onChange = (v) => {
    setValue(v);
  };

  const selectItem = (v) => {
    v.preventDefault();
    // eslint-disable-next-line array-callback-return
    chatOrderId.map((ids) => {
      if (ids[v.target.innerText]) {
        selectChatPage(ids[v.target.innerText]);
      }
    });
    setActiveItem(v.target.innerText);
  };
  return (
    <div style={{ padding: '15px' }}>
      <AutoComplete
        dropdownMatchSelectWidth={120}
        options={options}
        onSelect={onSelect}
        onSearch={handleSearch}
        value={value}
        onChange={onChange}
      >
        <Input.Search size='large' placeholder='order number' enterButton />
      </AutoComplete>
      {chatOrderNumbers.length > 0 && (
        <List
          size='small'
          bordered
          dataSource={chatOrderNumbers}
          renderItem={(item, index) => (
            <List.Item style={{ background: activeItem === item ? '#ddd' : 'transparent' }}>
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
              <a key='list-loadmore-edit' onClick={selectItem}>
                {item}
              </a>
              {msgCount[index] > 0 && (
                <span style={{ background: '#ddd', padding: '0px 7px', borderRadius: '10px' }}>{msgCount[index]}</span>
              )}
            </List.Item>
          )}
        />
      )}
    </div>
  );
};
export default ChatList;
