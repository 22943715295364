import React from 'react';
import Table from '../../../../components/tables/Table';
import ListsContainer from '../../../../components/ListsContainer/ListsContainer';

const EmployeePaymentTable = ({ data, columns, tableRef, key }) => (
  <ListsContainer>
    <Table
      key={key}
      title=''
      columns={columns}
      actions={[]}
      createAction={undefined}
      tableRef={tableRef}
      data={data?.orders}
      onRowClick={false}
      filtering={false}
      paging={false}
    />
    <br />
    <div>Open: {data?.open}</div>
    <div>Paid: {data?.paid}</div>
    <div>Unpaid: {data?.unpaid}</div>
    <div>Total: {data?.total}</div>
  </ListsContainer>
);
export default EmployeePaymentTable;
