import React from 'react';
import { PropTypes } from '../common/utilities';
import BCheckBoxField from './inputs/forms/BCheckBoxField';

const EditComponentCheckbox = (props) => (
  <BCheckBoxField
    {...props}
    readOnly={props.columnDef.readonly}
    onChange={(e) => props.onChange(e.currentTarget.value)}
  />
);

EditComponentCheckbox.propTypes = {
  enabled: PropTypes.string,
};

export default EditComponentCheckbox;
