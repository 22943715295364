import moment from 'moment';
import Cookies from 'universal-cookie';
import { Button, Card, CardActions, CardContent, CardHeader, Divider } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import { DashboardPanelContext } from '../../../../components/context/DashboardContext';
import BSubmitButton from '../../../../components/buttons/SubmitButton';
import BSelectField from '../../../../components/inputs/forms/BSelectField';
import { REQUIRED_STRING } from '../../../../constants/HookForm';
import BTextField from '../../../../components/inputs/forms/BTextField';
import BImageList from '../../../../components/inputs/forms/BImageList';
import {
  setValues,
  onChangeForBComponents,
  onKeyDownHandlerOnlyAcceptEnglishCharacterAndNumbers,
  convertArrayToKeyedObject,
  onChangeTable,
} from '../../../../common/utilities';
import { BSwalShowError } from '../../../../common/BSwal';
import Inventory from './components/Inventory';
import 'antd/dist/antd.css';
import './OrderPage.css';

const cookies = new Cookies();

const initialOrder = {
  email: '',
  phone_1: '',
  phone_2: '',
  travel_price: null,
  full_address: '',
  description: '',
  client_id: null,
  user_id: null,
  company_type_id: null,
  order_status_id: 1,
  latitude: null,
  longitude: null,
  previous_orders: [],
  special_notes: '',
  job_types: [],
  code: '',
  area_id: null,
  interval_id: null,
  date: '',
  order_type: 'private',
  created_by: {},
  user: {},
  expenses: null,
  external_order_number: '',
  order_number: '',
  bonus: '',
  expenses_paid_by_employee: true,
  customer_f_name: '',
  customer_l_name: '',
};

const OrderPageNewAdmin = () => {
  const { globalState, saveItem, fetchItem } = useContext(DashboardPanelContext);
  const history = useHistory();
  const { viewReadOnly } = globalState;
  const [loading, setLoading] = useState(false);
  const { errors, handleSubmit, setValue, register } = useForm();
  const [orderStatuses, setOrderStatuses] = useState([]);
  const [ordersLinksData, setOrdersLinksData] = useState([]);
  const [key, setKey] = useState(0);
  const [orders, setOrders] = useState([]);
  const [currentOrder, setCurrentOrder] = useState(initialOrder);
  const [additionalImages, setAdditionalImages] = useState([]);
  const [additionalImagesSave, setAdditionalImagesSave] = useState([]);
  const permission = localStorage.getItem('isAdmin') === 'true' ? 'ADMIN' : 'EMPLOYEE';

  const [columns] = useState([
    {
      title: 'Previous order Number',
      field: 'order_number',
      editComponent: (prop) => (
        <BTextField
          sm={12}
          onChange={(e) => prop.onChange(e.currentTarget.value)}
          value={prop.value}
          onKeyDown={onKeyDownHandlerOnlyAcceptEnglishCharacterAndNumbers}
        />
      ),
    },
  ]);

  useEffect(() => {
    fetchItem({
      modelName: `/orders`,
      showLoader: true,
    }).then((response) => {
      if (response) {
        const res1 = [];
        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].status === '1') {
            response.data[i].created_by = {};
            response.data[i].user = {};
            response.data[i].user_id = null;
            res1.push(response.data[i]);
          }
        }
        setOrders(res1);
      }
    });
    fetchItem({
      modelName: `/orders/${localStorage.getItem('currentOrder')}`,
      showLoader: true,
    }).then((response) => {
      if (response) {
        const _order = { ...response.data };
        _order.created_by = {};
        _order.user = {};
        _order.user_id = null;
        _order.order_type = 'private';
        _order.additional = [];
        _order.before = [];
        _order.after = [];
        _order.created_from_order = _order.order_number;
        _order.order_number = null;
        _order.travel_price = null;
        _order.expenses = null;
        _order.bonus = null;
        _order.previous_orders = [];
        _order.description = null;
        _order.special_notes = '';
        _order.technician_report = '';
        _order.external_order_number = '';
        _order.expenses_paid_by_employee = false;
        _order.prepaid = false;
        _order.order_status_id = 1;
        _order.client_id = null;
        _order.company_type_id = null;
        _order.job_types = [];
        setCurrentOrder(_order);
      }
    });
  }, []);

  useEffect(() => {
    setOrdersLinksData(currentOrder.previous_orders);
  }, [currentOrder.previous_orders]);

  useEffect(() => {
    register(
      { name: 'date' },
      {
        required: REQUIRED_STRING,
      },
    );
  }, []);

  useEffect(() => {
    fetchItem({
      modelName: `/order-statuses`,
      showLoader: true,
    }).then((response) => {
      if (response) {
        const _final = [];
        for (let i = 0; i < response.data.length; i++) {
          if (String(response.data[i].title) !== 'Paid' && response.data[i].status === '1') {
            _final.push(response.data[i]);
          }
        }
        setOrderStatuses(_final);
      }
    });
  }, []);

  const fileSelectedHandlerAdditionalImagesLoad = async (e) => {
    setAdditionalImages([]);
    for (let i = 0; i < e.length; i++) {
      const f = e[i];
      f.name = `${process.env.REACT_APP_BASE_URL_UPLOAD}`.concat(e[i].image);
      const imgSrc = `${f.name}?w=164&h=164&fit=crop&auto=format`;
      // eslint-disable-next-line no-shadow
      setAdditionalImages((additionalImages) => [...additionalImages, { img: imgSrc }]);
    }
  };

  useEffect(() => {
    if (currentOrder.data) {
      setCurrentOrder(currentOrder.data);
    }
    if (currentOrder.additional) {
      fileSelectedHandlerAdditionalImagesLoad(currentOrder.additional);
    }
    if (currentOrder) {
      setValues(setValue, currentOrder);
    }
    if (currentOrder.id) {
      const _order = currentOrder;
      _order.date = moment(currentOrder.date).format('MM-DD-YYYY');
      setCurrentOrder(_order);
    }
    if (currentOrder.id && currentOrder.previous_orders) {
      setOrdersLinksData(currentOrder.previous_orders);
    }
  }, [currentOrder]);

  useEffect(() => {
    setKey(key + 1);
  }, [currentOrder.date]);

  const onSubmit = () => {
    setLoading(true);
    const _order = { ...currentOrder };
    _order.order_number = null;
    _order.travel_price = null;
    _order.expenses = null;
    _order.bonus = null;
    if (!_order.latitude || !_order.longitude) {
      BSwalShowError(['Please select the exact location of the address on the map.']);
      setLoading(false);
      return;
    }
    if (currentOrder.expenses_paid_by_employee) {
      _order.expenses_paid_by_employee = '1';
    } else {
      _order.expenses_paid_by_employee = '0';
    }
    _order.previous_order_id = [];
    let tempOrders = [];
    const _tempOrders = [];
    for (let i = 0; i < currentOrder?.previous_orders?.length; i++) {
      const temp = orders.filter(
        (x) => String(x.order_number) === String(currentOrder.previous_orders[i].order_number),
      );
      if (temp[0]) {
        _tempOrders.push(temp);
      } else {
        BSwalShowError(['The Entered Order Number not found.']);
        setLoading(false);
        return;
      }
    }
    // eslint-disable-next-line prefer-destructuring
    tempOrders = _tempOrders;
    for (let i = 0; i < tempOrders?.length; i++) {
      if (tempOrders[i].length > 0) {
        for (let j = 0; j < tempOrders[i].length; j++) {
          _order.previous_order_id.push(tempOrders[i][j].id);
        }
      }
    }
    _order.job_types = [];
    if (currentOrder.order_type === 'private') {
      _order.job_types.push(1);
    }
    saveItem('orders', _order, null)
      .then((response) => {
        if (response.data.error) {
          BSwalShowError(response.data.error?.code);
        } else {
          axios.defaults.baseURL = `${process.env.REACT_APP_BASE_URL}`;
          for (let i = 0; i < additionalImagesSave.length; i++) {
            const formData = new FormData();
            formData.append('additional', additionalImagesSave[i]);
            axios({
              method: 'post',
              url: 'orders/add-image/'.concat(response?.data?.data?.id),
              data: formData,
              headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${cookies.get('userToken')}`,
              },
            });
          }
          history.push(`/order-management/order/${response.data.data.id}`);
        }
      })
      .catch((err) => BSwalShowError(err?.data?.messages))
      .finally(() => setLoading(false));
  };

  const onChange = (e) => {
    onChangeForBComponents(e, setValue, currentOrder, setCurrentOrder);
  };

  const addPreviousOrderId = (item) => {
    for (let i = 0; i < item.length; i++) {
      const temp = orders.filter((x) => String(x.order_number) === String(item[i].order_number));
      if (
        temp[0] &&
        (!currentOrder.previous_orders[i] || currentOrder.previous_orders[i].order_number !== item[i].order_number)
      ) {
        onChange(onChangeTable('previous_orders', item));
      } else {
        BSwalShowError(['The Entered Order Number not found.']);
        return;
      }
    }
  };

  const fileSelectedHandlerAdditionalImages = async (e) => {
    const imageSet = [...additionalImages];
    const _arr = [];
    for (let i = 0; i < e.target.files.length; i++) {
      const file = e.target.files[i];
      // eslint-disable-next-line no-shadow
      setAdditionalImagesSave((additionalImagesSave) => [...additionalImagesSave, file]);
      _arr.push(e.target.files[i]);
    }
    if (_arr.length > 0) {
      const filePromises = _arr.map(
        (file) =>
          // Return a promise per file
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = async () => {
              try {
                const response = { img: reader.result };
                // Resolve the promise with the response value
                resolve(response);
              } catch (err) {
                reject(err);
              }
            };
            reader.onerror = (error) => {
              reject(error);
            };
            reader.readAsDataURL(file);
          }),
      );
      // Wait for all promises to be resolved
      const fileInfos = await Promise.all(filePromises);
      for (let i = 0; i < fileInfos.length; i++) {
        imageSet.push(fileInfos[i]);
      }
      setAdditionalImages(imageSet);
    }
  };

  const removeImgAdditional = (imgIndex) => {
    const _selected = [...additionalImages];
    _selected.splice(imgIndex, 1);
    setAdditionalImages(_selected);
    const _saved = [...additionalImagesSave];
    _saved.splice(imgIndex, 1);
    setAdditionalImagesSave(_saved);
  };

  return (
    <div style={{ paddingBottom: '40px' }}>
      {currentOrder && (
        <Card>
          <Button
            id='backToListIcon'
            component={Link}
            to={{
              pathname: permission === 'ADMIN' ? '/order-management/orders' : '/employees/orders',
            }}
            color='secondary'
            variant='outlined'
            style={{ float: 'right', marginTop: '10px', marginRight: '10px' }}
          >
            <ArrowBackIcon />
          </Button>
          <form className='formContainer' onSubmit={handleSubmit(onSubmit)}>
            <CardHeader title='Order Information' />
            <Divider />
            <CardContent>
              <br />
              <div>Order Type: {currentOrder.order_type}</div>
              <div>Area: {currentOrder.area?.title}</div>
              <div>
                Hours interval: {currentOrder.interval?.min} - {currentOrder.interval?.max}
              </div>
              <div>Date: {currentOrder.date}</div>
              {currentOrder.order_type === 'insurance' && <div>Client: {currentOrder.client_id}</div>}
              {currentOrder.order_type === 'insurance' && <div>Compnay Type: {currentOrder.company_type_id}</div>}
              {currentOrder.order_type === 'insurance' && (
                <div>External Order Number: {currentOrder.external_order_number}</div>
              )}
              <div>
                {' '}
                Phone:
                <a href={`tel:${currentOrder?.phone_1}`}> {currentOrder.phone_1} </a>
              </div>
              <div>
                {' '}
                Mobile:
                <a href={`tel:${currentOrder?.phone_2}`}> {currentOrder.phone_2} </a>
              </div>
              <div>
                Email:
                <a href={`mailto:${currentOrder?.email}`}> {currentOrder.email} </a>
              </div>
              <div>
                First Name:
                <span> {currentOrder.customer_f_name}</span>
              </div>
              <div>
                Last Name:
                <span> {currentOrder.customer_l_name}</span>
              </div>
              <BSelectField
                sm={4}
                xs={12}
                label='Order Status'
                required
                name='order_status_id'
                value={1}
                onChange={onChange}
                error={errors.order_status_id}
                items={convertArrayToKeyedObject(orderStatuses, 'id', 'title')}
                readOnly
              />
              <BTextField
                sm={12}
                xs={12}
                label='Full Address'
                required
                name='full_address'
                value={currentOrder?.full_address}
                error={errors?.full_address}
                onChange={onChange}
                readOnly={viewReadOnly}
              />
              <BTextField
                sm={12}
                xs={12}
                label='Description'
                onChange={onChange}
                name='description'
                value={currentOrder.description}
                error={errors?.description}
              />
              <BTextField
                sm={12}
                xs={12}
                label='Special Notes'
                onChange={onChange}
                name='special_notes'
                value={currentOrder.special_notes}
                error={errors?.special_notes}
              />
            </CardContent>
            <Inventory
              style={{ margin: 10 }}
              title='Previous Orders'
              items={ordersLinksData}
              onChange={(items) => addPreviousOrderId(items)}
              columns={columns}
              u={false}
            />
            <Divider />
            <Card style={{ margin: 10 }}>
              <CardHeader title='Additional Images' />
              <CardContent>
                <input type='file' multiple onChange={fileSelectedHandlerAdditionalImages} />
                <BImageList imageData={additionalImages} removeImg={removeImgAdditional} remove />
              </CardContent>
            </Card>
            <br />
            <CardActions>
              <Grid item xs={12} sm={6}>
                <BSubmitButton
                  disabled={currentOrder?.order_status?.title === 'Paid'}
                  id='submitEmployee'
                  label='Save Order'
                  loading={loading}
                />
              </Grid>
              <Grid item xs={12} sm={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  id='backToList'
                  component={Link}
                  to={{
                    pathname: permission === 'ADMIN' ? '/order-management/orders' : '/employees/orders',
                  }}
                  color='secondary'
                  variant='outlined'
                >
                  Back to Orders list
                </Button>
              </Grid>
            </CardActions>
          </form>
        </Card>
      )}
    </div>
  );
};

export default OrderPageNewAdmin;
