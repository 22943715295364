import React from 'react';
import Grid from '@material-ui/core/Grid';
import moment from 'moment-jalaali';
import BTextField from '../inputs/forms/BTextField';
import { MomentDateTime } from '../../constants/Moment';
import { PropTypes } from '../../common/utilities';

const HistoryForm = ({ item }) => (
  <Grid container spacing={4} style={{ marginBottom: '10px' }}>
    <BTextField value={item?.createdBy?.personFullName} label='Registrar' name='createdBy' readOnly sm={3} />
    <BTextField
      value={moment(item?.createdOn).format(MomentDateTime)}
      label='Date of Registration'
      name='createdOn'
      readOnly
      sm={3}
    />
    <BTextField value={item?.updatedBy?.personFullName} label='The latest modifier' name='updatedBy' readOnly sm={3} />
    <BTextField
      value={moment(item?.updatedOn).format(MomentDateTime)}
      label='Last Date Modified'
      name='updatedOn'
      readOnly
      sm={3}
    />
  </Grid>
);

HistoryForm.propTypes = {
  item: PropTypes.shape({
    createdBy: PropTypes.shape({
      personFullName: PropTypes.string,
    }),
    updatedBy: PropTypes.shape({
      personFullName: PropTypes.string,
    }),
    updatedOn: PropTypes.string,
  }),
};

export default HistoryForm;
