import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { DashboardPanelContext } from '../../../../components/context/DashboardContext';
import Table from '../../../../components/tables/Table';
import { SET_VIEW_READ_ONLY } from '../../../../components/context/reducer/actionTypes';
import useTableActions from '../../../../hooks/useTableActions';
import useHasPermission from '../../../../hooks/useHasPermission';
import ListsContainer from '../../../../components/ListsContainer/ListsContainer';
import EnabledColumn from '../../../../components/EnabledColumn/EnabledColumn';
import EditComponentCheckbox from '../../../../components/EditComponentCheckbox';

const CompanyTypesList = () => {
  const history = useHistory();
  const [pageSize, setPageSize] = useState(10);
  const tableRef = useRef(null);
  const { globalDispatch, fetchItem } = useContext(DashboardPanelContext);
  const [columns, setColumns] = useState([]);
  const [key, setKey] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchItem({
      modelName: `/company-types`,
      showLoader: true,
    }).then((response) => {
      if (response) {
        setData(response.data);
      }
    });
    setColumns([
      {
        title: 'Name',
        field: 'name',
        filtering: false,
        sorting: false,
      },
      {
        title: 'Status',
        field: 'status',
        type: 'boolean',
        render: EnabledColumn,
        editComponent: EditComponentCheckbox,
        filtering: false,
        sorting: false,
      },
    ]);
    setKey(key + 1);
  }, []);

  const actions = useTableActions({
    updatePermission: 'ADMIN',
    deletePermission: '',
    editURL: '/order-management/company-type/',
    removePATH: 'company-types',
    tableRef,
  });

  const handleCreate = () => {
    globalDispatch({ type: SET_VIEW_READ_ONLY, value: false });
    history.push('/order-management/company-type');
  };

  const onRowClick = (event, rowData) => {
    globalDispatch({ type: SET_VIEW_READ_ONLY, value: true });
    history.push(`/order-management/company-type/${rowData.id}`);
  };

  return (
    <ListsContainer>
      <Table
        key={key}
        title='Company Type List'
        columns={columns}
        createSlug='Add Company Type'
        exportCsv={false}
        actions={actions}
        createAction={useHasPermission('ADMIN') ? handleCreate : undefined}
        tableRef={tableRef}
        data={data}
        onRowClick={onRowClick}
        onChangeRowsPerPage={(pgSize) => {
          setPageSize(pgSize);
        }}
        pageSize={pageSize}
        filtering={false}
        sortableColumns
      />
    </ListsContainer>
  );
};

export default CompanyTypesList;
