import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { Button, Card, CardActions, CardContent, CardHeader, Divider } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import clsx from 'clsx';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import { Link, useHistory } from 'react-router-dom';
import { DashboardPanelContext } from '../../../../components/context/DashboardContext';
import BSubmitButton from '../../../../components/buttons/SubmitButton';
import BCheckBoxField from '../../../../components/inputs/forms/BCheckBoxField';
import { REQUIRED_STRING } from '../../../../constants/HookForm';
import BTextField from '../../../../components/inputs/forms/BTextField';
import {
  setValues,
  onChangeForBComponents,
  onKeyDownHandlerOnlyAcceptEnglishCharacterAndNumbers,
} from '../../../../common/utilities';
import { BSwalShowError } from '../../../../common/BSwal';
import useHistoryForms from '../../../../hooks/useHistoryForms';
import BHistoryNavigator from '../../../../components/BHistoryNavigator';
import useBasicInformationMountedEffect from '../../../../hooks/useBasicInformationMountedEffect';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  customInputFormControl: {
    width: '100%',
  },
  formLabel: {
    fontSize: 16,
    marginBottom: 10,
  },
}));

const initialCompanyType = {
  name: '',
  status: true,
};

const CompanyTypesPage = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const { staticContext, ...rest } = props;
  const { globalState, saveItem } = useContext(DashboardPanelContext);

  const { viewReadOnly } = globalState;

  const [loading, setLoading] = useState(false);
  const { errors, handleSubmit, setValue, register } = useForm();

  const {
    fetchData,
    prevHistory,
    nextHistory,
    HistoryId,
    historyIdList,
    historyStep,
    history: fetchedHistory,
    historyInfo,
  } = useHistoryForms({
    location: props.location,
    route: '/order-management/company-types',
  });

  const {
    id,
    item: currentCompanyType,
    setItem: setCurrentCompanyType,
  } = useBasicInformationMountedEffect({
    match: props.match,
    historyIdList,
    fetchItemURL: '/company-types',
    localStorageKey: 'company-types',
    initialItem: initialCompanyType,
    fetchData,
    historyId: HistoryId,
  });

  useEffect(() => {
    if (!viewReadOnly) {
      register(
        { name: 'name' },
        {
          required: REQUIRED_STRING,
        },
      );
    }
  }, []);

  useEffect(() => {
    if (currentCompanyType.data) {
      setCurrentCompanyType(currentCompanyType.data);
    }
    if (currentCompanyType) {
      setValues(setValue, currentCompanyType);
    }
  }, [currentCompanyType]);

  useEffect(() => {
    if (fetchedHistory && historyIdList.length !== 0) {
      setCurrentCompanyType(fetchedHistory);
    }
  }, [fetchedHistory]);

  const onSubmit = () => {
    setLoading(true);
    const companyType = { ...currentCompanyType };
    if (currentCompanyType.status) {
      companyType.status = '1';
    } else {
      companyType.status = '0';
    }
    saveItem('company-types', companyType, id)
      .then(() => history.push('/order-management/company-types'))
      .catch((err) => BSwalShowError(err?.data?.messages))
      .finally(() => setLoading(false));
  };

  const onChange = (e) => {
    onChangeForBComponents(e, setValue, currentCompanyType, setCurrentCompanyType);
  };

  return (
    <div className={classes.root} style={{ margin: 10 }}>
      {currentCompanyType && (
        <Card {...rest} className={clsx(classes.root)}>
          <Button
            id='backToListIcon'
            component={Link}
            to={{
              pathname: '/order-management/company-types',
            }}
            color='secondary'
            variant='outlined'
            style={{ float: 'right', marginTop: '10px', marginRight: '10px' }}
          >
            <ArrowBackIcon />
          </Button>
          <form className='formContainer' onSubmit={handleSubmit(onSubmit)}>
            <CardHeader title='Company Type Information' />
            <Divider />
            <CardContent>
              <br />
              {historyInfo}
              <Grid container spacing={3}>
                <BTextField
                  label='Name'
                  name='name'
                  value={currentCompanyType?.name}
                  onChange={onChange}
                  readOnly={viewReadOnly}
                  onKeyDown={onKeyDownHandlerOnlyAcceptEnglishCharacterAndNumbers}
                  error={errors.name}
                  required
                />

                <BCheckBoxField
                  sm={12}
                  name='status'
                  label='Enabled Status'
                  value={Number(currentCompanyType?.status)}
                  error={errors.status}
                  onChange={onChange}
                  readOnly={viewReadOnly}
                />
              </Grid>
            </CardContent>
            <Divider />
            <CardActions>
              <Grid item xs={12} sm={6}>
                {!viewReadOnly && <BSubmitButton id='submitEmployee' label='Save Company Type' loading={loading} />}
              </Grid>
              <Grid item xs={12} sm={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  id='backToList'
                  component={Link}
                  to={{
                    pathname: '/order-management/company-types',
                  }}
                  color='secondary'
                  variant='outlined'
                >
                  Back to Company Type list
                </Button>
              </Grid>
            </CardActions>
          </form>
          <BHistoryNavigator
            historyStep={historyStep}
            historyIdList={historyIdList}
            prevHistory={prevHistory}
            nextHistory={nextHistory}
          />
        </Card>
      )}
    </div>
  );
};

CompanyTypesPage.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object,
};

export default CompanyTypesPage;
