import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { Select, MenuItem, IconButton } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Typography from '@material-ui/core/Typography';
import { Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { Clear } from '@material-ui/icons';
import { DashboardPanelContext } from '../../context/DashboardContext';
import { convertKeyedObjectToArray, ReactFormHookServerError } from '../../../common/utilities';
import BInputLabel from '../../BInputLabel';
import BErrorFormHelperText from '../../BErrorFormHelperText';

const checkedIcon = <CheckBoxIcon fontSize='small' />;
const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;

const useStyle = makeStyles(() => ({
  selectContainer: {
    position: 'relative',
  },
  clearButton: {
    position: 'absolute',
    right: 25,
    top: 12,
  },
}));

const BSelectField = ({
  items = {},
  label,
  name,
  value,
  readOnly = false,
  error,
  onChange,
  onKeyDown,
  serverError,
  required = false,
  xs = 12,
  sm = 4,
  multiple = false,
}) => {
  const {
    globalState: { pageLoading },
  } = useContext(DashboardPanelContext);
  const classes = useStyle();
  const prepareForOnChange = (e) => {
    const itm = {
      currentTarget: {
        name,
        value: e.target.value,
      },
    };

    if (onChange) onChange(itm);
  };

  const prepareForOnChangeMultiple = (e, d) => {
    const itm = {
      currentTarget: {
        name,
        value: d.map((c) => c.key),
      },
    };

    if (onChange) onChange(itm);
  };

  const [multipleOptions, setMultipleOptions] = useState([]);
  const [_value, setValue] = useState([]);
  useEffect(() => {
    setMultipleOptions(convertKeyedObjectToArray(items, 'key', 'value'));
  }, [items]);

  useEffect(() => {
    if (value && multiple && multipleOptions.length > 0) {
      const result = [];
      value.forEach((e) => {
        const f = multipleOptions.find((c) => c.key === e);
        result.push(f);
      });
      setValue(result);
    }
  }, [value, multipleOptions]);

  const clearSelect = () =>
    prepareForOnChange({
      target: {
        name,
        value: undefined,
      },
    });

  const renderInput = (params) => (
    <TextField
      error={error?.message || ReactFormHookServerError(serverError, 'messages', 'fieldName', name)}
      {...params}
      name={name}
      fullWidth
      variant='outlined'
      value={params}
    />
  );

  const renderOption = (option, { selected }) => (
    <>
      <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
      {option.value}
    </>
  );

  const getOptionLabel = (option) => option?.value;
  const getOptionSelected = (option, __value) => option?.key === __value?.key;
  const renderOptions = () =>
    Object.keys(items).map((item) => (
      <MenuItem key={item} id={`${name}-option-${item}`} value={item}>
        {items[item]}
      </MenuItem>
    ));

  const renderClearIcon = () => {
    if (value && !readOnly)
      return (
        <IconButton focusRipple={false} size='small' className={classes.clearButton} onClick={clearSelect}>
          <Clear />
        </IconButton>
      );
    return <></>;
  };

  const autoCompleteMultipleProps = {
    multiple,
    id: name,
    value: _value,
    options: multipleOptions,
    disableCloseOnSelect: true,
    disabled: readOnly,
    onChange: prepareForOnChangeMultiple,
    getOptionSelected,
    getOptionLabel,
    renderOption,
    renderInput,
  };

  const selectProps = {
    id: name,
    error: error?.message || ReactFormHookServerError(serverError, 'messages', 'fieldName', name),
    name,
    onKeyDown: onKeyDown || (() => undefined),
    onChange: prepareForOnChange,
    fullWidth: true,
    variant: 'outlined',
    value: items[value] ? value : '',
    disabled: readOnly,
  };

  return (
    <>
      <Grid item xs={xs} sm={sm} aria-disabled={readOnly}>
        {pageLoading && Object.keys(items).length === 0 ? (
          <>
            <Typography variant='h1'>
              <Skeleton height={100} />
            </Typography>
          </>
        ) : (
          <>
            <BInputLabel label={label} error={error} name={name} required={required} serverError={serverError} />
            {!multiple && (
              <div className={classes.selectContainer}>
                <Select {...selectProps}>{renderOptions()}</Select>
                {renderClearIcon()}
                <BErrorFormHelperText text={error?.message} />
                {ReactFormHookServerError(serverError, 'messages', 'fieldName', name)}
              </div>
            )}
            {multiple && (
              <>
                <Autocomplete {...autoCompleteMultipleProps} />
                <BErrorFormHelperText text={error?.message} />
                {ReactFormHookServerError(serverError, 'messages', 'fieldName', name)}
              </>
            )}
          </>
        )}
      </Grid>
    </>
  );
};

BSelectField.propTypes = {
  items: PropTypes.object.isRequired,
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
  readOnly: PropTypes.bool,
  error: PropTypes.object,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  serverError: PropTypes.array,
  xs: PropTypes.number,
  sm: PropTypes.number,
  multiple: PropTypes.bool,
  required: PropTypes.bool,
};
export default React.memo(BSelectField);
