import { validPhoneNumber, onlyEnglishPersianCharacterAndNumbers } from '../common/validators';

export const REQUIRED_STRING = 'This field is required.';
export const REQUIRED = {
  required: REQUIRED_STRING,
};

export const REQUIRED_ONLY_PERSIAN_ENGLISH_CHARACTER_AND_NUMBERS = {
  required: REQUIRED_STRING,
  pattern: onlyEnglishPersianCharacterAndNumbers,
};

export const REQUIRED_VALID_PHONE_NUMBER = {
  required: REQUIRED_STRING,
  pattern: validPhoneNumber,
};
