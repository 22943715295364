import React, { useContext, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Divider, Drawer } from '@material-ui/core';
import { Badge } from 'antd';
import PeopleIcon from '@material-ui/icons/People';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import PinDropIcon from '@material-ui/icons/PinDrop';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import QuestionAnswerRoundedIcon from '@material-ui/icons/QuestionAnswerRounded';
import DirectionsIcon from '@material-ui/icons/Directions';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import AssessmentIcon from '@material-ui/icons/Assessment';
import _ from 'lodash';
import { Profile, SidebarNav } from './components';
import { DashboardPanelContext } from '../../../../components/context/DashboardContext';

const drawerWidth = 290;

const useStyles = makeStyles((theme) => ({
  divider: {
    margin: theme.spacing(2, 0),
  },
  nav: {
    marginBottom: theme.spacing(2),
  },
  navem: {
    marginBottom: theme.spacing(2),
    paddingTop: '0',
  },
  root: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: 290,
    flexShrink: 0,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)',
    },
  },
  drawerem: {
    width: 71,
    flexShrink: 0,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)',
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: 0,
  },
}));

const Sidebar = (props) => {
  const { open, variant, onClose, className, credentials, onOpen, ...rest } = props;
  const { fetchItem, globalState } = useContext(DashboardPanelContext);
  const classes = useStyles();
  const { unreadCount } = globalState;

  const [permissions, setPermissions] = useState('');
  const [msgCount, setMsgCount] = useState(0);
  const [menus, setMenus] = useState([]);
  useEffect(() => {
    if (localStorage.getItem('isAdmin')) {
      if (localStorage.getItem('isAdmin') === 'true') {
        setPermissions('ADMIN');
      } else {
        setPermissions('EMPLOYEE');
      }
    }
  }, [localStorage.getItem('isAdmin')]);

  useEffect(() => {
    if (localStorage.getItem('isAdmin') === 'true') {
      fetchItem({
        modelName: `/order-comments`,
        showLoader: true,
      }).then((response) => {
        let _count = 0;
        for (let i = 0; i < response.data.length; i++) {
          if (
            String(response.data[i].user_id) !== String(localStorage.getItem('uid')) &&
            response.data[i].read === '0'
          ) {
            _count += 1;
          }
        }
        setMsgCount(_count);
      });
    }
    if (localStorage.getItem('isAdmin') === 'false') {
      fetchItem({
        modelName: `/orders`,
        showLoader: true,
      }).then((res) => {
        fetchItem({
          modelName: `/order-comments`,
          showLoader: true,
        }).then((response) => {
          let _count = 0;
          for (let j = 0; j < res.data.length; j++) {
            for (let i = 0; i < response.data.length; i++) {
              if (
                String(res.data[j].user_id) === String(localStorage.getItem('uid')) &&
                res.data[j].order_number === response.data[i].order_number &&
                String(response.data[i].user_id) !== String(localStorage.getItem('uid')) &&
                response.data[i].read === '0'
              ) {
                _count += 1;
              }
            }
          }
          setMsgCount(_count);
        });
      });
    }
  }, [localStorage.getItem('uid'), unreadCount, localStorage.getItem('isAdmin')]);
  useEffect(() => {
    setMenus(
      [
        {
          key: 200,
          label: 'Employee Management',
          icon: <AssignmentIndIcon />,
          parent: -1,
          start: 200,
          end: 299,
          subMenus: [
            {
              key: 211,
              label: 'Employee List',
              permission: 'ADMIN',
              href: '/employee-management/employees',
            },
          ],
        },
        {
          key: 400,
          label: 'Client Management',
          icon: <PeopleIcon />,
          parent: -1,
          start: 400,
          end: 499,
          subMenus: [
            {
              key: 410,
              label: 'Client List',
              permission: 'ADMIN',
              href: '/client-management/clients',
            },
          ],
        },
        {
          key: 500,
          label: 'Settings',
          icon: <ShoppingBasketIcon />,
          parent: -1,
          start: 500,
          end: 599,
          subMenus: [
            {
              key: 515,
              label: 'Order Status',
              permission: 'ADMIN',
              href: '/order-management/statuses',
            },
            {
              key: 516,
              label: 'Service Types',
              permission: 'ADMIN',
              href: '/order-management/service-types',
            },
            {
              key: 517,
              label: 'Job Types',
              permission: 'ADMIN',
              href: '/order-management/job-types',
            },
            {
              key: 518,
              label: 'Company Types',
              permission: 'ADMIN',
              href: '/order-management/company-types',
            },
            {
              key: 519,
              label: 'Zips',
              permission: 'ADMIN',
              href: '/order-management/zips',
            },
            {
              key: 520,
              label: 'Areas',
              permission: 'ADMIN',
              href: '/order-management/areas',
            },
            {
              key: 521,
              label: 'Visit Intervals',
              permission: 'ADMIN',
              href: '/order-management/intervals',
            },
          ],
        },
        {
          key: 1000,
          label: 'Chat',
          icon: (
            <Badge
              count={msgCount}
              style={{
                top: '3px',
                minWidth: '15px',
                padding: '0 3px',
                fontSize: '9px',
                lineHeight: '15px',
                height: '15px',
              }}
            >
              <QuestionAnswerRoundedIcon />
            </Badge>
          ),
          parent: -1,
          start: 1000,
          end: 1099,
          permission: 'ADMIN',
          href: '/chat',
          subMenus: [],
        },
        {
          key: 100,
          icon: <DirectionsIcon />,
          label: 'Routes',
          permission: 'ADMIN',
          href: '/employee-management/create-routes',
          parent: -1,
          start: 100,
          end: 199,
          subMenus: [],
        },
        {
          key: 300,
          label: 'Orders',
          permission: 'ADMIN',
          icon: <BorderColorIcon />,
          href: '/order-management/orders',
          parent: -1,
          start: 300,
          end: 399,
          subMenus: [],
        },
        {
          key: 1100,
          label: 'Make Payments',
          permission: 'ADMIN',
          href: '/order-management/make-payments',
          icon: <CreditCardIcon />,
          parent: -1,
          start: 1100,
          end: 1199,
          subMenus: [],
        },
        {
          key: 1300,
          label: 'Insurance Payment',
          permission: 'ADMIN',
          href: '/order-management/make-payments-insurance',
          icon: <CreditCardIcon />,
          parent: -1,
          start: 1300,
          end: 1399,
          subMenus: [],
        },
        {
          key: 1200,
          label: 'View Reports',
          permission: 'ADMIN',
          href: '/order-management/view-reports',
          icon: <AssessmentIcon />,
          parent: -1,
          start: 1200,
          end: 1299,
          subMenus: [],
        },
        {
          key: 600,
          label: '',
          icon: <BorderColorIcon />,
          parent: -1,
          start: 600,
          end: 699,
          permission: 'EMPLOYEE',
          href: '/employees/orders',
          subMenus: [],
        },
        {
          key: 700,
          label: '',
          icon: <PinDropIcon />,
          parent: -1,
          start: 700,
          end: 799,
          permission: 'EMPLOYEE',
          href: '/employees/routes',
          subMenus: [],
        },
        {
          key: 800,
          label: '',
          icon: <MonetizationOnIcon />,
          parent: -1,
          start: 800,
          end: 899,
          permission: 'EMPLOYEE',
          href: '/employees/payments',
          subMenus: [],
        },
        {
          key: 900,
          label: '',
          icon: (
            <Badge
              count={msgCount}
              style={{
                top: '3px',
                minWidth: '15px',
                padding: '0 3px',
                fontSize: '9px',
                lineHeight: '15px',
                height: '15px',
              }}
            >
              <QuestionAnswerRoundedIcon />
            </Badge>
          ),
          parent: -1,
          start: 900,
          end: 999,
          permission: 'EMPLOYEE',
          href: '/employee/chat',
          subMenus: [],
        },
      ]
        .map((c) => ({
          ...c,
          permissions: _.flattenDeep([
            ..._.get(c, 'subMenus', []).map((e) => e.permission || e?.subMenus.map((r) => r.permission)),
          ]),
        }))
        .map((c) => ({
          ...c,
          subMenus: [
            ...c.subMenus?.map((e) => ({
              ...e,
              permissions: [..._.get(e, 'subMenus', []).map((r) => r.permission)],
            })),
          ],
        })),
    );
  }, [msgCount]);

  const [pages, setPages] = useState([]);
  useMemo(() => {
    if (permissions.length > 0)
      setPages(
        _.filterDeep(
          menus,
          (value) => {
            if (value) return permissions === value.permission || permissions === value.permissions;
          },
          { childrenPath: 'subMenus', checkCircular: false },
        ),
      );
  }, [permissions, msgCount, menus]);
  return (
    <>
      {localStorage.getItem('isAdmin') && (
        <>
          {localStorage.getItem('isAdmin') === 'true' ? (
            <Drawer
              anchor='left'
              classes={{ paper: localStorage.getItem('isAdmin') === 'true' ? classes.drawer : classes.drawerem }}
              onClose={onClose}
              open={open}
              variant='persistent'
            >
              <div
                {...rest}
                className={clsx(classes.root, className)}
                style={{ padding: localStorage.getItem('isAdmin') === 'true' ? '16px' : '10px' }}
              >
                <Profile credentials={credentials} />
                {/* <ChangePost /> */}
                <Divider className={classes.divider} />
                <SidebarNav className={classes.nav} pages={pages} />
              </div>
            </Drawer>
          ) : (
            <div
              style={{
                width: '100%',
                height: '40px',
                background: 'rgb(11, 37, 69)',
                position: 'fixed',
                bottom: '0',
                zIndex: '20000',
              }}
            >
              <SidebarNav className={classes.navem} pages={pages} />
            </div>
          )}
        </>
      )}
    </>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
  credentials: PropTypes.any,
};

export default Sidebar;
