import React from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';
import { MarkerNew } from './MarkerNew';

class AddMarker extends React.Component {
  helpDiv;

  marker;

  componentDidMount() {
    const { map } = this.props;
    const control = this.createButtonControl();
    control.addTo(map);
  }

  componentWillUnmount() {
    this.helpDiv.remove();
  }

  createButtonControl() {
    const MapHelp = L.Control.extend({
      onAdd: (map) => {
        const helpDiv = L.DomUtil.create(
          'button',
          'MuiButtonBase-root MuiButton-root MuiButton-outlined MuiButton-outlinedPrimary bg-white',
        );
        this.helpDiv = helpDiv;
        helpDiv.innerHTML = 'New';

        helpDiv.addEventListener('click', () => {
          this.marker = L.marker(map.getCenter(), { draggable: true }).setIcon(MarkerNew).addTo(map);

          if (this.props.onDragend) this.props.onDragend(map.getCenter());

          this.marker.on('dragend', (e) => {
            const _marker = e.target;
            const _position = _marker.getLatLng();
            if (this.props.onDragend) this.props.onDragend(_position);
          });
          helpDiv.remove();
        });

        // a bit clueless how to add a click event listener to this button and then
        // open a popup div on the map
        return helpDiv;
      },
    });
    return new MapHelp({ position: 'bottomright' });
  }

  render() {
    return null;
  }
}

function withMap(Component) {
  return function WrappedComponent(props) {
    const map = useMap();
    return <Component {...props} map={map} />;
  };
}

export default withMap(AddMarker);
