import { useEffect, useState } from 'react';
import L from 'leaflet';
import 'leaflet-routing-machine/dist/leaflet-routing-machine.css';
import 'leaflet-routing-machine';
import { useMap } from 'react-leaflet';
import { MarkerHide, MarkerNew } from './MarkerNew';

const Routing = ({ points, getOrderId, permission }) => {
  const [markerPoints, setMarkerPoints] = useState([]);
  const [key, setKey] = useState(0);
  const map = useMap();

  useEffect(() => {
    for (let i = 0; i < points.length; i++) {
      // eslint-disable-next-line no-shadow
      setMarkerPoints((markerPoints) => [...markerPoints, L.latLng(points[i].position[0], points[i].position[1])]);
    }
    setKey(key + 1);
  }, [points]);

  useEffect(() => {
    if (!map) return;
    const routingControl = L.Routing.control({
      waypoints: markerPoints,
      routeWhileDragging: true,
      collapsible: true,
      lineOptions: {
        styles: [
          {
            color: 'blue',
            opacity: 0.6,
            weight: 4,
          },
        ],
      },
      units: 'imperial',
      autoRoute: true,
      createMarker(i, wp) {
        return L.marker(wp.latLng)
          .setIcon(permission === 'EMPLOYEE' ? MarkerHide : MarkerNew)
          .on('click', (e) => {
            getOrderId(e.latlng);
          });
      },
    }).addTo(map);
    return () => map.removeControl(routingControl);
  }, [map, points, key]);
  return null;
};
export default Routing;
