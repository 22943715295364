export const Table = {
  fa: {
    search: 'جستجو',
    operation: 'عملیات',
    row: 'سطر',
    columnSearch: 'جستجوی ستون',
    emptyData: 'رکوردی برای نمایش وجود ندارد!',
    excelFile: 'فایل اکسل',
    downloadExcel: 'دانلود اکسل',
    firstPage: 'اولین صفحه',
    lastPage: 'آخرین صفحه',
    All: 'همه',
    nextPage: 'صفحه بعد',
    previousPage: 'صفحه قبل',
    from: 'از',
  },
  en: {
    search: 'search',
    operation: 'operation',
    row: 'rows',
    columnSearch: 'column search',
    emptyData: 'No records to display!',
    excelFile: 'excel file',
    downloadExcel: 'export excel',
    firstPage: 'first page',
    lastPage: 'last page',
    All: 'All',
    nextPage: 'next page',
    previousPage: 'previous page',
    from: 'from',
  },
};

export const Enabled = {
  fa: {
    true: 'فعال',
    false: 'غیرفعال',
  },
  en: {
    true: 'enabled',
    false: 'disabled',
  },
};

export const Default = {
  fa: {
    phone: 'تلفن',
    phoneNumber: 'شماره تماس',
    address: 'آدرس',
    description: 'توضیحات',
    postalCode: 'کدپستی',
    details: 'مشاهده جزئیات',
    addNewItem: 'ایجاد مورد جدید',
    create: 'ایجاد',
    delete: 'حذف',
    edit: 'ویرایش',
    back: 'بازگشت',
    submit: 'ثبت',
    cancel: 'لغو',
    enabled: 'وضعیت',
    registerDate: 'تاریخ ثبت',
    startDate: 'تاریخ شروع',
    endDate: 'تاریخ پایان',
    username: 'نام کاربری',
    firstName: 'نام',
    lastName: 'نام خانوادگی',
    fatherName: 'نام پدر',
    legalName: 'نام حقوقی',
    birthDate: 'تاریخ تولد',
    maritalStatus: 'وضعیت تاهل',
    gender: 'جنسیت',
    nationalCode: 'کد ملی',
    identificationCode: 'شماره شناسنامه',
    telegram: 'تلگرام',
    whatsapp: 'واتساپ',
    fax: 'فکس',
    mobile: 'موبایل',
    email: 'ایمیل',
    other: 'سایر',
    nextStep: 'مرحله بعد',
    previousStep: 'مرحله قبل',
    enterAmount: 'مقدار را وارد کنید',
    height: 'ارتفاع',
    width: 'عرض',
    length: 'طول',
    weight: 'وزن',
    volume: 'حجم',
    barcode: 'بارکد',
    language: 'زبان',
    translation: 'ترجمه',
    formalPrice: 'قیمت رسمی',
    informalPrice: 'قیمت غیر رسمی',
    coverPrice: 'قیمت روی جلد',
    count: 'تعداد',
    productCount: 'تعداد کالا',
    noOptionAvailable: 'موردی وجود ندارد',
    noOptionMatched: 'موردی یافت نشد',
    exit: 'خروج',
    basicInfo: 'اطلاعات پایه',
    personnel: 'پرسنل',
    accountInformation: 'اطلاعات حساب',
    createNewItem: 'ایجاد مورد جدید',
    veryBad: 'خیلی بد',
    bad: 'بد',
    mediocre: 'متوسط',
    good: 'خوب',
    perfect: 'عالی',
    seller: 'فروشنده',
    purchaser: 'مسئول سفارش',
    receiver: 'تحویل گیرنده',
    morning: 'صبح',
    noon: 'ظهر',
    afternoon: 'عصر',
    night: 'شب',
    all: 'همه',
    shift: 'شیفت',
    accountNumber: 'شماره حساب',
    alternativeAccountNumber: 'شماره حساب جایگزین ',
    bankName: 'نام بانک',
    accountType: 'نوع حساب',
  },
  en: {
    phone: 'phone',
    phoneNumber: 'phoneNumber',
    address: 'address',
    description: 'description',
    postalCode: 'postal code',
    addNewItem: 'add new item',
    create: 'create',
    delete: 'delete',
    edit: 'edit',
    back: 'back',
    submit: 'submit',
    cancel: 'cancel',
    enabled: 'status',
    registerDate: 'Register Date',
    startDate: 'start date',
    endDate: 'end date',
    username: 'username',
    firstName: 'first name',
    lastName: 'last name',
    fatherName: 'father name',
    legalName: 'legal name',
    birthDate: 'birth date',
    maritalStatus: 'marital status',
    gender: 'gender',
    nationalCode: 'national code',
    identificationCode: 'identification code',
    telegram: 'telegram',
    whatsapp: "what's app",
    fax: 'fax',
    mobile: 'mobile',
    email: 'email',
    other: 'other',
    nextStep: 'next step',
    previousStep: 'previous step',
    enterAmount: 'enter amount',
    height: 'height',
    width: 'width',
    length: 'length',
    weight: 'weight',
    volume: 'volume',
    barcode: 'barcode',
    language: 'language',
    translation: 'translation',
    formalPrice: 'formal price',
    informalPrice: 'informal price',
    coverPrice: 'cover price',
    count: 'count',
    productCount: 'product count',
    noOptionAvailable: 'no option is available',
    noOptionMatched: 'there is no option matched',
    exit: 'exit',
    basicInfo: 'basic information',
    personnel: 'personnel',
    accountInformation: 'account information',
    createNewItem: 'create new item',
    veryBad: 'very bad',
    bad: 'bad',
    mediocre: 'mediocre',
    good: 'good',
    perfect: 'perfect',
    seller: 'seller',
    purchaser: 'purchaser',
    receiver: 'receiver',
    morning: 'morning',
    noon: 'noon',
    afternoon: 'afternoon',
    night: 'night',
    all: 'all',
    shift: 'shift',
    accountNumber: 'accountNumber',
    alternativeAccountNumber: 'alternative account number',
    bankName: 'bank name',
    accountType: 'account type',
  },
};

export const dashboardTitles = {
  fa: {
    customerCreditManagement: 'مدیریت اعتبار مشتری',
    staffCreditManagement: 'مدیریت اعتبار پرسنل',
    creditManagement: 'مدیریت اعتبار',
    countryDivisions: 'تقسیم‌بندی کشوری',
    salesDivisions: 'بخش‌بندی فروش',
    productHierarchy: 'سلسله مراتب کالا',
  },
  en: {
    customerCreditManagement: 'Customer Credit Management',
    staffCreditManagement: 'Staff Credit Management',
    creditManagement: 'Credit Management',
    countryDivisions: 'Country Divisions',
    salesDivisions: 'Sale division',
    productHierarchy: 'Product hierarchy',
  },
};

export const dashboard = {
  fa: {
    officeManagement: 'مدیریت دفاتر',
    inventoryManagement: 'مدیریت انبارها',
    settingParameters: 'تنظیمات پارامتر های سامانه',
    countryManagement: 'مدیریت کشورها',
    internationalTerritoryManagement: 'مدیریت مناطق بین‌المللی',
    provinceManagement: 'مدیریت استان‌ها',
    cityManagement: 'مدیریت شهرها',
    cityRegionManagement: 'مدیریت مناطق شهری',
    regionManagement: 'مدیریت مناطق',
    branchManagement: 'مدیریت شعب',
    territoryManagement: 'مدیریت حوزه‌ها',
    areaManagement: 'مدیریت حیطه‌ها',
    districtManagement: 'مدیریت نواحی',
    companyManagement: 'مدیریت شرکت',
    brandManagement: 'مدیریت برند',
    lineManagement: 'مدیریت لاین',
    classManagement: 'مدیریت رده',
    categoryManagement: 'مدیریت دسته',
    groupManagement: 'مدیریت گروه',
    guildManagement: 'مدیریت صنف‌ها',
    salePlanManagement: 'مدیریت طرح فروش',
    tariffManagement: 'مدیریت نرخنامه',
    productManagement: 'مدیریت کالا',
    userManagement: 'مدیریت کاربران',
    mapManagement: 'مدیریت نقشه',
    determineTheCoefficientOfPerformanceValidityParameter: 'ضریب پارامتر اعتبار عملکرد',
    staffCreditsList: 'لیست اعتبار عملکرد',
    customers: 'مشتریان',
    customersManagement: 'مدیریت مشتریان',
    customersManagementSetting: 'تنظیمات مدیریت مشتریان',
    notFoundTitle: 'خطای ۴۰۴',
    notFoundDescription: 'چنین صفحه‌ای پیدا نشد!',
  },
  en: {
    officeManagement: 'office management',
    inventoryManagement: 'inventory management',
    settingParameters: 'setting parameters',
    countryManagement: 'country management',
    internationalTerritoryManagement: 'international territory management',
    provinceManagement: 'province management',
    cityManagement: 'city management',
    cityRegionManagement: 'city region management',
    regionManagement: 'region management',
    branchManagement: 'branch management',
    territoryManagement: 'territory management',
    areaManagement: 'area management',
    districtManagement: 'district management',
    companyManagement: 'company management',
    brandManagement: 'brand management',
    lineManagement: 'line management',
    classManagement: 'class management',
    categoryManagement: 'category management',
    groupManagement: 'group management',
    guildManagement: 'guild management',
    salePlanManagement: 'sale plan management',
    tariffManagement: 'tariff management',
    productManagement: 'product management',
    userManagement: 'user management',
    mapManagement: 'map management',
    determineTheCoefficientOfPerformanceValidityParameter:
      'Determine the coefficient of performance validity parameter',
    staffCreditsList: 'Staff Credits List',
    customers: 'customers',
    customersManagement: 'customers management',
    customersManagementSetting: 'customers management setting',
    notFoundTitle: '404 - Not Found',
    notFoundDescription: 'The Page You Are Looking For Does Not Exist!',
  },
};

export const forms = {
  fa: {
    // general keys
    brandName: 'برند',
    brandList: 'لیست برندها',
    brandCreate: 'ایجاد برند',
    lineName: 'لاین',
    lineList: 'لیست لاین‌ها',
    lineCreate: 'ایجاد لاین',
    className: 'رده',
    classList: 'لیست رده‌ها',
    classCreate: 'ایجاد رده',
    categoryName: 'دسته',
    categoryList: 'لیست دسته‌ها ',
    categoryCreate: 'ایجاد دسته',
    groupName: 'گروه',
    groupList: 'لیست گروه‌ها',
    groupCreate: 'ایجاد گروه',
    inventoryCreate: 'ایجاد انبار',
    settingParameterCreate: 'ایجاد پارامترهای تنظیم سامانه',
    languageInformation: 'اطلاعات زبان خارجی',
    addLanguage: 'افزودن زبان',
    packingInformation: 'اطلاعات بسته‌بندی',
    addPackage: 'افزودن بسته‌بندی',
    planInformation: 'اطلاعات طرح',
    productInformation: 'اطلاعات کالا',
    finalSubmission: 'تایید نهایی',
    confirm: 'تایید',
    cancel: 'لغو',

    // login
    username: 'نام کاربری',
    password: 'رمز عبور',
    rememeberMe: 'مرا به خاطر بسپار!',
    forgotPass: 'رمز عبور خود را فراموش کردید؟',
    register: 'نام کاربری ندارید؟ ثبت نام کنید',
    rightsProtection: 'تمامی حقوق برای پرگاس طب محفوظ است.',
    login: 'ورود',

    // country divisions
    countryName: 'کشور',
    countryList: 'لیست کشورها',
    countryCreate: 'ایجاد کشور',
    provinceName: 'استان',
    provinceList: 'لیست استان‌ها',
    provinceCreate: 'ایجاد استان',
    cityName: 'شهر',
    cityList: 'لیست شهرها',
    cityCreate: 'ایجاد شهر',
    cityRegionName: 'منطقه شهری',
    cityRegionList: 'لیست مناطق شهری',
    cityRegionCreate: 'ایجاد منطقه شهری',
    cityCode: 'کد شهر',
    provinceCode: 'کد استان',

    // sale divisions
    internationalTerritoryName: 'سطح بین المللی',
    regionName: 'منطقه',
    branchName: 'شعبه',
    territoryName: 'حوزه',
    areaName: 'حیطه',
    districtName: 'ناحیه',
    internationalTerritoryList: 'لیست سطوح بین المللی',
    internationalTerritoryCreate: 'ایجاد سطح بین المللی',
    regionList: 'لیست مناطق ',
    regionCreate: 'ایجاد منطقه',
    branchList: 'لیست شعب',
    branchCreate: 'ایجاد شعبه',
    territoryList: 'لیست حوزه‌ها',
    territoryCreate: 'ایجاد حوزه',
    areaList: 'لیست حیطه‌ها',
    areaCreate: 'ایجاد حیطه',
    districtList: 'لیست نواحی',
    districtCreate: 'ایجاد ناحیه',

    // offices
    officeName: 'نام دفتر',
    officeType: 'نوع دفتر',
    officeManager: 'مدیر دفتر',
    officeList: 'لیست دفاتر',
    officeCreate: 'ایجاد دفتر',

    // inventories
    inventoryName: 'نام انبار',
    inventoryType: 'نوع انبار',
    inventoryKeeper: 'مسئول انبار',
    inventoryList: 'لیست انبارها',
    inventoryBrands: 'برند کالاها',
    inventoryOffice: 'دفتر مرتبط',
    inventoryPostalCode: 'کد پستی',
    inventoryNamePlaceholder: 'نام انبار را وارد کنید',
    inventoryAddressPlaceholder: 'آدرس انبار را وارد نمایید ...',
    inventoryDescriptionPlaceholder: 'هرگونه توضیحات درباره انبار را اینجا بنویسید ...',

    // settingParameters
    parameterName: 'نام پارامتر',
    parameterGroup: 'گروه پارامتر',
    parameterValue: 'مقدار',
    parameterType: 'نوع',

    // product hierarchy
    companyName: 'شرکت',
    companyEnglishName: 'عنوان انگلیسی شرکت',
    companyList: 'لیست شرکت‌ها',
    companyCreate: 'ایجاد شرکت',
    lineEnglishName: 'عنوان انگلیسی لاین',
    classEnglishName: 'عنوان انگلیسی رده',
    categoryEnglishName: 'عنوان انگلیسی دسته',
    groupEnglishName: 'عنوان انگلیسی گروه',

    // guild
    guildName: 'نام صنف',
    guildList: 'لیست صنف‌ها',
    guildCreate: 'ایجاد صنف',
    guildEnglishName: 'نام انگلیسی صنف',

    // sale plan
    salePlan: 'طرح فروش',
    salePlanName: 'نام طرح فروش',
    salePlanList: 'لیست طرح‌های فروش',
    salePlanCreate: 'ایجاد طرح فروش',
    salePlanEnglishName: 'نام انگلیسی طرح',
    salePlanSpecifications: 'اطلاعات طرح فروش',
    salePlanDescriptionPlaceholder: 'هرگونه توضیحات درباره طرح فروش را اینجا بنویسید ...',
    productBrand: 'برند کالا',
    productLine: 'لاین کالا',
    productClass: 'رده کالا',
    productCategory: 'دسته کالا',
    productGroup: 'گروه کالا',
    purchaseCount: 'تعداد خرید',
    offerCount: 'تعداد آفر',
    cooperationDiscountPercent: 'تخفیف همکاری',
    marketingAndResearchDiscountPercent: 'پژوهش و بازاریابی',
    giftCardDiscountPercent: 'کارت هدیه',
    basket: 'سبد',
    addBasket: 'اضافه کردن سبد',
    createBasket: 'ساخت سبد',
    basketName: 'نام سبد',
    sellingBasket: 'سبد فروش',
    enterBasketName: 'نام سبد را وارد کنید',
    products: 'کالاها',
    addProduct: 'اضافه کردن کالا',
    addOfferStep: 'افزودن پله جدید',
    offer: 'آفر',
    offers: 'آفرها',

    // tariff
    tariffName: 'نام نرخنامه',
    tariffList: 'لیست نرخنامه‌ها',
    tariffCreate: 'ایجاد نرخنامه',
    tariffSpecification: 'مشخصات نرخنامه',
    tariffPlaceholder: 'هرگونه توضیحات درباره نرخنامه را اینجا بنویسید ...',

    // product
    productName: 'نام کالا',
    productCreate: 'ایجاد کالا',
    productList: 'لیست کالاها',
    productEnglishName: 'نام انگلیسی کالا',
    productBarcode: 'بارکد کالا',
    productDescriptionPlaceholder: 'هرگونه توضیحات درباره کالا را اینجا بنویسید ...',
    productSpecifications: 'مشخصات کالا',
    productImage: 'عکس کالا',
    smallPackage: 'کارتن کوچک',
    largePackage: 'کارتن بزرگ',
    pallet: 'پالت',
    language: 'زبان',
    translate: 'ترجمه',
    translation: 'ترجمه',
    deliveryDuration: 'زمان تحویل',
    width: 'عرض',
    height: 'ارتفاع',
    length: 'طول',
    weight: 'وزن',
    volume: 'حجم',
    barcode: 'بارکد',
    productCount: 'تعداد کالا در بسته',

    // user
    userName: 'نام کاربر',
    userCreate: 'ایجاد کاربر',
    userList: 'لیست کاربران',
    firstRelativeName: 'نام بستگان اول',
    firstRelativeNumber: 'شماره بستگان اول',
    secondRelativeName: 'نام بستگان دوم',
    secondRelativeNumber: 'شماره بستگان دوم',
    firstReferenceName: 'نام معرف اول',
    firstReferenceNumber: 'شماره معرف اول',
    secondReferenceName: 'نام معرف دوم',
    secondReferenceNumber: 'شماره معرف دوم',

    // customers
    customerName: 'نام مشتری',
    customerCreate: 'ایجاد مشتری',
    customerList: 'لیست مشتریان',
    customerReviewer: 'بررسی کننده اطلاعات',
    customerState: 'وضعیت بررسی',
    REVIEWING: 'در حال بررسی',
    APPROVED: 'تایید شده',
    REJECTED: 'رد شده',
    customerInfo: 'اطلاعات مشتری',
    economicCode: 'کد اقتصادی',
    businessLicenceCode: 'شماره پروانه',
    reputation: 'شهرت و اعتبار',
    urbanTexture: 'بافت اطراف مشتری',
    residential: 'مسکونی',
    official: 'اداری',
    recreational: 'تفریحی',
    mixed: 'مختلط',
    landOwner: 'مالک',
    renter: 'مستاجر',
    ownershipType: 'نوع مالکیت',
    stampImage: 'تصویر مهر مشتری',
    signatureImage: 'تصویر امضای مشتری',
    visitCardImage: 'تصویر کارت ویزیت',
    facadeImage: 'تصویر سر در',
    organizationalUnit: 'واحد سازمانی',
    organizationalLevel: 'سطح سازمانی',
    identificationCode: 'کد شناسایی',

    // customers settings
    customersReviewersList: 'لیست تاییدکنندگان مشتریان',
    addNewEmployee: 'اضافه کردن پرسنل جدید',
    contactInfo: 'اطلاعات تماس',
    ContactType: 'نوع تماس',
    addContactInfo: 'افزودن اطلاعات تماس',
    addBankAccount: 'افزودن اطلاعات حساب',

    // customer credit management
    determineTheCoefficientOfPerformanceValidityParameterList: 'لیست پارامترها',
    determineTheCoefficientOfPerformanceValidityParameterName: 'نام',
    determineTheCoefficientOfPerformanceValidityParameterDeterminingTheCoefficient: 'تعیین ضریب',

    // new
    primaryCreditParameterGroup: 'گروه اعتبار اولیه',
    PrimaryCreditParameter: 'پارامتر اعتبار اولیه',
    creditState: 'وضعیت بررسی',

    // role
    roleName: 'عنوان سمت',

    // Distribution management
    inventoryResponder: 'نام مسئول انبار',
    confirmationStatus: 'وضعیت تایید',
    date: 'تاریخ',
    distributorId: 'شناسه موزع',
    miscellaneousDistributorName: 'نام موزع متفرقه',
    deviceType: 'نوع وسیله',
    deviceNumber: 'شماره وسیله',
    deviceCapacity: 'ظرفیت وسیله',
    instituteName: 'نام موسسه',
    phoneNumberOfInstitute: 'شماره تماس موسسه',
    instituteAddress: 'آدرس موسسه',

    ordersList: 'لیست سفارشات',
  },
  en: {
    // general keys
    brandName: 'brand name',
    brandList: 'brand list',
    brandCreate: 'brand create',
    lineName: 'line name',
    lineList: 'line list',
    lineCreate: 'line create',
    className: 'class name',
    classList: 'class list',
    classCreate: 'class create',
    categoryName: 'category name',
    categoryList: 'category list',
    categoryCreate: 'category create',
    groupName: 'group name',
    groupList: 'group list',
    groupCreate: 'group create',
    inventoryCreate: 'inventory create',
    languageInformation: 'language information',
    addLanguage: 'add language',
    packingInformation: 'packing information',
    addPackage: 'add package',
    planInformation: 'plan information',
    productInformation: 'product information',
    finalSubmission: 'final submission',
    confirm: 'confirm',
    cancel: 'cancel',

    // login
    username: 'username',
    password: 'password',
    rememeberMe: 'Remember me!',
    forgotPass: 'forgot your password?',
    register: 'No username? Sign up!',
    rightsProtection: 'All rights of this website belong to Pergas Teb company.',
    login: 'login',

    // country divisions
    countryName: 'country name',
    countryList: 'country list',
    countryCreate: 'create country',
    provinceName: 'province name',
    provinceList: 'province list',
    provinceCreate: 'create province',
    cityName: 'city name',
    cityList: 'city list',
    cityCreate: 'create city',
    cityRegionName: 'city region name',
    cityRegionList: 'city region list',
    cityRegionCreate: 'create city region',
    cityCode: 'city code',
    provinceCode: 'province code',

    // sale divisions
    internationalTerritoryName: 'international territory name',
    regionName: 'region name',
    branchName: 'branch name',
    territoryName: 'territory name',
    areaName: 'area name',
    districtName: 'district name',
    internationalTerritoryList: 'international territory list',
    internationalTerritoryCreate: 'create international territory',
    regionList: 'region list',
    regionCreate: 'create region',
    branchList: 'branch list',
    branchCreate: 'create branch',
    territoryList: 'territory list',
    territoryCreate: 'create territory',
    areaList: 'area list',
    areaCreate: 'create area ',
    districtList: 'district list',
    districtCreate: 'create district ',

    // offices
    officeName: 'office name',
    officeType: 'office type',
    officeManager: 'office manager',
    officeList: 'office list',
    officeCreate: 'create office',

    // inventories
    inventoryName: 'inventory name',
    inventoryType: 'inventory type',
    inventoryKeeper: 'inventory keeper',
    inventoryList: 'inventory list',
    inventoryBrands: 'brands',
    inventoryOffice: 'related office',
    inventoryPostalCode: 'postal code',
    inventoryNamePlaceholder: 'enter name for inventory',
    inventoryAddressPlaceholder: 'enter address for inventory',
    inventoryDescriptionPlaceholder: 'write every needed description for inventory here ...',

    // product hierarchy
    companyName: 'company name',
    companyEnglishName: 'company english name',
    companyList: 'company list',
    companyCreate: 'company create',
    brandEnglishName: 'brand english name',
    lineEnglishName: 'line english name',
    classEnglishName: 'class english name',
    categoryEnglishName: 'category english name',
    groupEnglishName: 'group english name',

    // guild
    guildName: 'guild name',
    guildList: 'guild list',
    guildCreate: 'create guild',
    guildEnglishName: 'guild English name',

    // sale plan
    salePlan: 'sale plan',
    salePlanName: 'sale plan name',
    salePlanList: 'sale plan list',
    salePlanCreate: 'create sale plan',
    salePlanEnglishName: 'plan English name',
    salePlanSpecifications: 'sale plan specifications',
    productBrand: 'product brand',
    productLine: 'product line',
    productClass: 'product class',
    productCategory: 'product category',
    productGroup: 'product group',
    purchaseCount: 'purchase quantity',
    offerCount: 'offer quantity',
    cooperationDiscountPercent: 'colleague discount',
    marketingAndResearchDiscountPercent: 'research and marketing',
    giftCardDiscountPercent: 'gift card',
    basket: 'basket',
    addBasket: 'add basket',
    sellingBasket: 'selling basket',
    enterBasketName: 'enter basket name',
    createBasket: 'create basket',
    basketName: 'basket name',
    products: 'products',
    addProduct: 'add product',
    addOfferStep: 'add offer step',
    offer: 'offer',
    customerInfo: 'customer information',

    // tariff
    tariffSpecification: 'tariff specification',
    tariffList: 'tariff list',
    tariffCreate: 'tariff create',
    tariffPlaceholder: 'write every needed description for tariff here ...',

    // product
    productName: 'product name',
    productCreate: 'product create',
    productList: 'product list',
    productEnglishName: 'product English name',
    productBarcode: ' product barcode',
    smallPackage: 'product small package',
    largePackage: 'product large package',
    pallet: 'product pallet',
    language: 'language',
    translate: 'translation',

    // user
    userName: 'user name',
    userCreate: 'create user',
    userList: 'user list',
    firstRelativeName: 'first relative name',
    firstRelativeNumber: 'first relative number',
    secondRelativeName: 'second relative name',
    secondRelativeNumber: 'second relative number',
    firstReferenceName: 'first reference name',
    firstReferenceNumber: 'first reference number',
    secondReferenceName: 'second reference name',
    secondReferenceNumber: 'second reference number',

    // customers
    customerName: 'customer name',
    customerCreate: 'create customer',
    customerList: 'customer list',
    customerReviewer: 'information reviewer',
    customerState: 'customer state',
    REVIEWING: 'reviewing',
    APPROVED: 'approved',
    REJECTED: 'rejected',
    economicCode: 'economic code',
    businessLicenceCode: 'business licence code',
    reputation: 'reputation',
    urbanTexture: 'urban textures',
    residential: 'residential',
    official: 'official',
    recreational: 'recreational',
    mixed: 'mixed',
    landOwner: 'landOwner',
    renter: 'renter',
    ownershipType: 'ownership type',
    stampImage: 'stamp image',
    signatureImage: 'signature image',
    visitCardImage: 'visit card image',
    facadeImage: 'facade image',
    organizationalUnit: 'organization unit',
    organizationalLevel: 'organization level',
    identificationCode: 'identification code',

    // customers settings
    customersReviewersList: 'customer reviewers list',
    contactInfo: 'contact info',
    ContactType: 'customer employee contact type',
    addContactInfo: 'add contact info',
    addBankAccount: 'add bank account',

    // customer credit management
    determineTheCoefficientOfPerformanceValidityParameterList: 'Parameter List',
    determineTheCoefficientOfPerformanceValidityParameterName: 'Name',
    determineTheCoefficientOfPerformanceValidityParameterDeterminingTheCoefficient: 'Determining the coefficient',

    // role
    roleName: 'role name',

    ordersList: 'Orders List',
  },
};

export const language = {
  fa: {
    arabic: 'عربی',
    dutch: 'آلمانی',
    english: 'انگلیسی',
    french: 'فرانسوی',
    turkish: 'ترکی',
  },
  en: {
    arabic: 'arabic',
    dutch: 'dutch',
    english: 'english',
    french: 'french',
    turkish: 'turkish',
  },
};
