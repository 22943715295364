import React, { useState } from 'react';
import { InputLabel, Paper } from '@material-ui/core';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import VisibilityIcon from '@material-ui/icons/Visibility';
import InputAdornment from '@material-ui/core/InputAdornment';
import { BSwalShowError } from '../../../common/BSwal';
import Locale from '../../../helpers/Locale';
import { forms } from '../../../data/Dictionary';
import BSubmitButton from '../../buttons/SubmitButton';

/**
 *
 * @param props
 * @returns {HTMLDivElement} This will return the login page inputs
 * @constructor
 */
export default function LoginForm({ loginCredentials, loading }) {
  const Language = Locale();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  /**
   *
   * @param {Object} event
   */
  const userNameHandler = (event) => {
    setEmail(event.target.value);
  };

  /**
   *
   * @param {Object} event
   */
  const passwordHandler = (event) => {
    setPassword(event.target.value);
  };

  /**
   *
   * @param {Object} event
   * @param {String} email
   * @param {String} password
   */

  const handleSubmit = async (event) => {
    if (!email && !password) return BSwalShowError(['Enter the username/password']).then();
    event.preventDefault();
    await loginCredentials({ email, password });
    try {
      // await login(userName, password)
      // history.push('/dashboard')
    } catch (e) {
      Promise.reject(e);
    }
  };
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(!passwordShown);
  };
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') return handleSubmit(event);
  };

  return (
    <>
      <Grid
        container
        justifyContent='center'
        alignItems='center'
        style={{
          height: '100%',
        }}
      >
        <Grid item xs={12} sm={10} md={8}>
          <Paper>
            <Grid container justifyContent='center' alignItems='center'>
              {/* <Hidden only='xs'> */}
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={6}
                style={{
                  minHeight: '25rem',
                  background: 'url(/logo/furniture-repair-expert.png)',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
              />
              {/* </Hidden> */}
              <Grid item sm={6} md={5} lg={4}>
                <CardContent>
                  <form
                    style={{ display: 'flex', flexDirection: 'column' }}
                    noValidate
                    onSubmit={(event) => {
                      handleSubmit(event).then();
                    }}
                  >
                    <InputLabel>Email</InputLabel>
                    <TextField id='email' data-testid='email' variant='outlined' onChange={userNameHandler} />
                    <InputLabel>{forms[Language].password}</InputLabel>

                    <TextField
                      id='password'
                      data-testid='password'
                      variant='outlined'
                      onKeyPress={handleKeyPress}
                      onChange={passwordHandler}
                      // type={"password"}
                      type={passwordShown ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <VisibilityIcon onClick={togglePasswordVisiblity} style={{ cursor: 'pointer' }} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </form>
                </CardContent>
                <CardActions
                  style={{
                    paddingRight: 15,
                    paddingLeft: 15,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Grid
                    container
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Grid item xs={12}>
                      <BSubmitButton
                        id='submitButton'
                        data-testid='submitButton'
                        loading={loading}
                        label='Login'
                        style={{ width: '100%' }}
                        onClick={(event) => handleSubmit(event, email, password)}
                        variant='outlined'
                        // color={"white"}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        style={{ marginRight: -10, marginTop: 10 }}
                        control={<Checkbox name='checkedC' />}
                        label={forms[Language].rememeberMe}
                      />
                    </Grid>
                  </Grid>
                </CardActions>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
