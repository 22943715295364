import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/styles';
import { useMediaQuery } from '@material-ui/core';
import _ from 'lodash';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import createPersistedState from 'btechn01-use-persisted-state';
import { Sidebar, Topbar } from './components';
import { DashboardPanelContext } from '../../components/context/DashboardContext';
import { SET_ERROR } from '../../components/context/reducer/actionTypes';
import { UserCredentialsContext } from '../../components/context/LoginContext';
import ConvertErrorsToHuman from '../../data/errors/backErrors';
import { BSwalShowError } from '../../common/BSwal';
import './main.css';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 56,
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64,
    },
    transition: theme.transitions.create(['width', 'margin', 'padding'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  shiftContent: {
    paddingLeft: 300,
    transition: theme.transitions.create(['width', 'margin', 'padding'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen - 50,
    }),
  },
  shiftContentem: {
    paddingLeft: localStorage.getItem('isAdmin') === 'true' ? 70 : 0,
    transition: theme.transitions.create(['width', 'margin', 'padding'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen - 50,
    }),
  },
  content: {
    height: '100%',
  },
}));

const useSidebarState = createPersistedState('sidebar');

const Main = (props) => {
  const { globalState, globalDispatch, fetchItem } = useContext(DashboardPanelContext);
  const { credentials } = useContext(UserCredentialsContext);
  const { error } = globalState;
  const [employeeClass, setEmployeeClass] = useState(false);
  const { children } = props;
  const [user, setUser] = useState({
    firstName: '',
    lastName: '',
    avatar: '/images/avatars/avatar_11.png',
    bio: '',
  });
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true,
  });

  const [openSidebar, setOpenSidebar] = useSidebarState(!!isDesktop);
  const [permission, setPermission] = useState('');
  useEffect(() => {
    if (localStorage.getItem('isAdmin')) {
      if (localStorage.getItem('isAdmin') === 'true') {
        setEmployeeClass(false);
      } else {
        setEmployeeClass(true);
      }
    }
  }, [localStorage.getItem('isAdmin')]);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  // const handleClose = (_event, reason) => {
  //   if (reason === 'clickaway') {
  //     return;
  //   }
  //   globalDispatch({ type: SET_SAVE_SUCCESS, value: false });
  // };

  const handleCloseError = (reason, index) => {
    if (reason === 'clickaway') {
      return;
    }
    const errors = error.messages;
    delete errors[index];
    globalDispatch({ type: SET_ERROR, value: errors });
  };

  const toggleSidebarOpen = () => {
    setOpenSidebar((prev) => !prev);
  };

  useEffect(() => {
    if (credentials.access_token) {
      fetchItem({
        modelName: `/profile`,
        showLoader: true,
      }).then((response) => {
        const userJson = { ...user };
        userJson.firstName = response.f_name;
        userJson.lastName = response.l_name;
        userJson.id = response.id;
        const name = 'https://portal.furniturerepairexpert.com/furniture-repair/public/'.concat(response.profile_image);
        const imgSrc = `${name}?w=164&h=164&fit=crop&auto=format`;
        // eslint-disable-next-line no-shadow
        userJson.profile_image = { img: imgSrc };
        setUser(userJson);
        localStorage.setItem('isAdmin', response.is_admin === '1');
        localStorage.setItem('uid', response.id);
        localStorage.setItem('f_name', response.f_name);
        localStorage.setItem('l_name', response.l_name);
        if (response.is_admin === '1') {
          setPermission('ADMIN');
        } else {
          setPermission('EMPLOYEE');
        }
      });
    }
  }, [credentials]);

  useEffect(() => {
    if (error !== undefined) {
      if (error && (error.status === 409 || error.status === 500 || error.status === 503 || error.status === 403)) {
        BSwalShowError(_.get(error, 'messages', [])).then((result) => {
          if (result.value) {
            // globalDispatch({ type: SET_ERROR, value: null });
          }
        });
      }
    }
  }, [error]);

  return (
    <div
      className={clsx({
        [classes.root]: true,
        [localStorage.getItem('isAdmin') === 'true' ? classes.shiftContent : classes.shiftContentem]: openSidebar,
      })}
    >
      <Topbar toggleSidebarOpen={toggleSidebarOpen} currentDate={globalState.currentDate} permission={permission} />
      <Sidebar
        credentials={credentials}
        onClose={handleSidebarClose}
        onOpen={handleSidebarOpen}
        open={openSidebar}
        // variant={isDesktop ? 'persistent' : 'temporary'}
        variant='persistent'
      />
      <main className={employeeClass ? 'mainContent' : ''} style={{ height: '100%' }}>
        {/* <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={saveSuccess}
          autoHideDuration={5000}
          onClose={handleClose}
        >
          <Alert severity='success' onClose={handleClose}>
            The information saved successfully.
          </Alert>
        </Snackbar> */}

        {/* {itemsLoading && <Alert severity="info">در حال بار گزاری ... </Alert>} */}
        {/* {itemLoading && <Alert severity="info">در حال بار گزاری ... </Alert>} */}
        {_.get(error, 'messages', []).length > 0 ? (
          error.messages
            .filter((c) => c.fieldName === 'unknown')
            .map((errorItem, index) => (
              <React.Fragment key={index}>
                <Snackbar
                  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                  open={!!errorItem}
                  autoHideDuration={5000}
                  onClose={(event, reason) => handleCloseError(reason, index)}
                >
                  <Alert severity='error' onClose={(event, reason) => handleCloseError(reason, index)}>
                    {errorItem.fieldName}
                    <br />
                    {ConvertErrorsToHuman(errorItem.message.slice(0, 10))}
                  </Alert>
                </Snackbar>
              </React.Fragment>
            ))
        ) : (
          <div />
        )}
        {children}
      </main>
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node,
};

export default Main;
