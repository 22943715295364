import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { DashboardPanelContext } from '../../../../components/context/DashboardContext';
import Table from '../../../../components/tables/Table';
import { SET_VIEW_READ_ONLY } from '../../../../components/context/reducer/actionTypes';
import useTableActions from '../../../../hooks/useTableActions';
import useHasPermission from '../../../../hooks/useHasPermission';
import ListsContainer from '../../../../components/ListsContainer/ListsContainer';
import EnabledColumn from '../../../../components/EnabledColumn/EnabledColumn';
import EditComponentCheckbox from '../../../../components/EditComponentCheckbox';

const ClientList = () => {
  const history = useHistory();
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const tableRef = useRef(null);
  const { globalDispatch, fetchItem } = useContext(DashboardPanelContext);
  const [columns, setColumns] = useState([]);
  const [key, setKey] = useState([]);
  const [data, setData] = useState([]);
  const [tableDataLoader, setTableDataLoader] = useState(false);

  const getClientsList = (pageNumber) => {
    setTableDataLoader(true);
    fetchItem({
      modelName: `/clients?page=${pageNumber}`,
      showLoader: true,
    }).then((response) => {
      if (response) {
        setTableDataLoader(false);
        setTotalCount(response.data.total > pageSize ? response.data.total + pageSize : response.data.total);
        setData(response.data.data);
      }
    });
  };

  useEffect(() => {
    getClientsList(page);
    setColumns([
      {
        title: 'Name',
        field: 'name',
        filtering: false,
        sorting: false,
      },
      {
        title: 'Contract Number',
        field: 'contract_number',
        filtering: false,
        sorting: false,
      },
      {
        title: 'Phone',
        field: 'phone_1',
        filtering: false,
        sorting: false,
      },
      {
        title: 'Mobile',
        field: 'phone_2',
        filtering: false,
        sorting: false,
      },
      {
        title: 'Address',
        field: 'full_address',
        filtering: false,
        sorting: false,
      },
      {
        title: 'Email',
        field: 'email',
        filtering: false,
        sorting: false,
      },
      {
        title: 'Description',
        field: 'description',
        filtering: false,
        sorting: false,
      },
      {
        title: 'Status',
        field: 'status',
        type: 'boolean',
        render: EnabledColumn,
        editComponent: EditComponentCheckbox,
        filtering: false,
        sorting: false,
      },
    ]);
    setKey(key + 1);
  }, [page]);

  const actions = useTableActions({
    updatePermission: 'ADMIN',
    deletePermission: '',
    editURL: '/client-management/client/',
    removePATH: 'clients',
    tableRef,
  });

  const handleCreate = () => {
    globalDispatch({ type: SET_VIEW_READ_ONLY, value: false });
    history.push('/client-management/client');
  };

  const onRowClick = (event, rowData) => {
    globalDispatch({ type: SET_VIEW_READ_ONLY, value: true });
    history.push(`/client-management/client/${rowData.id}`);
  };

  const onChangePage = (pageNumber) => {
    setPage(pageNumber === 0 ? 1 : pageNumber);
  };

  return (
    <ListsContainer>
      <Table
        key={key}
        title='Client List'
        columns={columns}
        createSlug='Add Client'
        exportCsv={false}
        actions={actions}
        createAction={useHasPermission('ADMIN') ? handleCreate : undefined}
        tableRef={tableRef}
        data={data}
        onRowClick={onRowClick}
        onChangeRowsPerPage={(pgSize) => {
          setPageSize(pgSize);
        }}
        pageSize={pageSize}
        paging
        filtering={false}
        totalCount={totalCount}
        page={page}
        onChangePage={onChangePage}
        sortableColumns
        isLoading={tableDataLoader}
      />
    </ListsContainer>
  );
};

export default ClientList;
