import React, { useContext, useEffect } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';

import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar, Hidden, IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import InputIcon from '@material-ui/icons/Input';
import Typography from '@material-ui/core/Typography';
import { cleanCookies } from 'universal-cookie/lib/utils';
import moment from 'moment-jalaali';
import logoo from '../../../../assets/img/logo/logotext.png';
import { UserCredentialsContext } from '../../../../components/context/LoginContext';
import { DashboardPanelContext } from '../../../../components/context/DashboardContext';
import { SET_CURRENT_DATE } from '../../../../components/context/reducer/actionTypes';
import { Profile } from '../Sidebar/components';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none',
  },
  flexGrow: {
    flexGrow: 1,
  },
  signOutButton: {
    marginLeft: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const Topbar = (props) => {
  const { credentials } = useContext(UserCredentialsContext);
  const { globalDispatch, globalState } = useContext(DashboardPanelContext);
  const history = useHistory();
  const { className, currentDate, toggleSidebarOpen, ...rest } = props;

  const classes = useStyles();

  useEffect(() => {
    if (credentials) {
      const _currentDate = moment().format('MM-DD-YYYY');
      if (_currentDate !== globalState.currentDate) globalDispatch({ type: SET_CURRENT_DATE, value: _currentDate });
    } else {
      cleanCookies();
    }
  }, []);

  return (
    <AppBar {...rest} className={clsx(classes.root, className)}>
      {credentials.access_token && (
        <Toolbar>
          {localStorage.getItem('isAdmin') === 'true' && (
            <Hidden mdDown>
              <IconButton color='inherit' onClick={toggleSidebarOpen}>
                <MenuIcon />
              </IconButton>
            </Hidden>
          )}
          <RouterLink to='/dashboard'>
            <img alt='Logo' style={{ height: 30, marginRight: '40px' }} src={logoo} />
          </RouterLink>
          <div className={classes.flexGrow} />
          {props.permission === 'EMPLOYEE' && <Profile credentials={credentials} />}
          {props.permission === 'ADMIN' && localStorage.getItem('isAdmin') === 'true' && (
            <Typography variant='h6' color='inherit'>
              {currentDate}
            </Typography>
          )}
          <Hidden mdDown>
            <IconButton
              className={classes.signOutButton}
              color='inherit'
              onClick={() => {
                history.push('/logout');
              }}
            >
              <InputIcon />
            </IconButton>
          </Hidden>
          {localStorage.getItem('isAdmin') === 'true' && (
            <Hidden lgUp>
              <IconButton color='inherit' onClick={toggleSidebarOpen}>
                <MenuIcon />
              </IconButton>
            </Hidden>
          )}
        </Toolbar>
      )}
    </AppBar>
  );
};

export default Topbar;
