import { useEffect } from 'react';
import L from 'leaflet';
import 'leaflet-routing-machine/dist/leaflet-routing-machine.css';
import 'leaflet-control-geocoder/dist/Control.Geocoder.css';
import 'leaflet-control-geocoder/dist/Control.Geocoder.js';
import 'leaflet-routing-machine';
import { useMap } from 'react-leaflet';
import icon from './constants';

export default function LeafletControlGeocoder(props) {
  const { getGeo } = props;
  const map = useMap();

  useEffect(() => {
    // let geocoder = L.Control.Geocoder.nominatim();
    let geocoder = L.Control.Geocoder.google({ apiKey: 'AIzaSyCcoAggEdJBUIAhWHC2ZJYtXlLQWCVLNOo' });
    // eslint-disable-next-line no-restricted-globals
    if (typeof URLSearchParams !== 'undefined' && location.search) {
      // parse /?geocoder=nominatim from URL
      // eslint-disable-next-line no-restricted-globals
      const params = new URLSearchParams(location.search);
      const geocoderString = params.get('geocoder');
      if (geocoderString && L.Control.Geocoder[geocoderString]) {
        geocoder = L.Control.Geocoder[geocoderString]();
      } else if (geocoderString) {
        // console.warn('Unsupported geocoder', geocoderString);
      }
    }

    const routingControl = L.Control.geocoder({
      query: '',
      placeholder: 'Search here...',
      defaultMarkGeocode: false,
      geocoder,
    })
      .on('markgeocode', (e) => {
        const latlng = e.geocode.center;
        L.marker(latlng, { icon }).addTo(map).bindPopup(e.geocode.name).openPopup();
        map.fitBounds(e.geocode.bbox);
        getGeo(e);
      })
      .addTo(map);
    return () => map.removeControl(routingControl);
  }, []);

  return null;
}
