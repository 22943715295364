import React, { useRef } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '../../../../components/tables/Table';
import useTableActions from '../../../../hooks/useTableActions';
import { PropTypes } from '../../../../common/utilities';

const HistoryDialog = ({
  open,
  handleClose,
  histories,
  historyColumns,
  historyTitle,
  onRowClick,
  bonusData,
  bonusDataColumns,
  expenses = 0,
  travelPrice,
}) => {
  const tableRef = useRef(null);
  const makeListId = (refs, rowData) => {
    if (refs?.current?.dataManager?.data) {
      const idList = [];
      refs.current.dataManager.data.forEach((item) => {
        idList.push(item.id);
      });
      if (onRowClick) onRowClick(idList, rowData);
    }
  };

  const actions = useTableActions({
    updatePermission: null,
    deletePermission: null,
    editURL: null,
    removePATH: null,
    tableRef,
  });
  return (
    <Dialog fullWidth maxWidth='md' open={open()} onClose={handleClose} aria-labelledby='form-dialog-title'>
      <DialogTitle id='form-dialog-title' />
      <DialogContent>
        <div>Travel Price: {travelPrice}</div>
        {expenses !== 0 && <div>{expenses}</div>}
        <br />
        <Table
          title={historyTitle}
          columns={historyColumns}
          data={histories}
          tableRef={tableRef}
          onRowClick={(_event, rowData) => makeListId(tableRef, rowData)}
          actions={actions}
          sortableColumns
          paging={false}
        />
        <br />
        {bonusData.length > 0 && (
          <Table
            title='Bonus Details'
            columns={bonusDataColumns}
            data={bonusData}
            tableRef={tableRef}
            onRowClick={(_event, rowData) => makeListId(tableRef, rowData)}
            actions={actions}
            sortableColumns
            paging={false}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button id='close' onClick={handleClose} color='secondary'>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
HistoryDialog.propTypes = {
  open: PropTypes.func,
  handleClose: PropTypes.func,
  historyColumns: PropTypes.array.isRequired,
  historyTitle: PropTypes.string,
  onRowClick: PropTypes.func,
  histories: PropTypes.func,
};

export default HistoryDialog;
