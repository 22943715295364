import React, { useContext, useEffect, useRef, useState } from 'react';
import { Card, CardContent } from '@material-ui/core';
import PaymentIcon from '@material-ui/icons/Payment';
import { useForm } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import { DashboardPanelContext } from '../../../../components/context/DashboardContext';
import Table from '../../../../components/tables/Table';
import BDateFieldTable from '../../../../components/inputs/forms/BDateFieldTable';
import { convertArrayToKeyedObject, onChangeForBComponents } from '../../../../common/utilities';
import ListsContainer from '../../../../components/ListsContainer/ListsContainer';
import BSubmitButton from '../../../../components/buttons/SubmitButton';
import HistoryDialog from './HistoryDialog';

const ViewReportList = () => {
  const [pageSize, setPageSize] = useState(10);
  const tableRef = useRef(null);
  const { handleSubmit, setValue } = useForm();
  const { fetchItem } = useContext(DashboardPanelContext);
  const [columns, setColumns] = useState([]);
  const [key, setKey] = useState([]);
  const [data, setData] = useState([]);
  const [beforeData, setBeforeData] = useState([]);
  const [clients, setClients] = useState([]);
  const [companyTypes, setCompanyTypes] = useState([]);
  const [orderStatuses, setOrderStatuses] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [currentOrder, setCurrentOrder] = useState([]);
  const [loading, setLoading] = useState(false);
  const [historyModalVisible, setHistoryModalVisible] = useState(false);
  const [histories, setHistories] = useState([]);
  const [historyColumns, setHistoryColumns] = useState([]);
  const [paymentsData, setPaymentsData] = useState([]);
  const [paymentsDataColumns, setPaymentsDataColumns] = useState([]);
  const [modalColumns, setModalColumns] = useState([]);
  const [bonusData, setBonusData] = useState([]);
  const [bonusDataColumns, setBonusDataColumns] = useState([]);
  const [travelPrice, setTravelPrice] = useState(null);
  const [expenses, setExpenses] = useState(null);
  const [historyTitle, setHistoryTitle] = useState('');
  const [grandTotal, setGrandTotal] = useState(0);

  useEffect(() => {
    fetchItem({
      modelName: `/users`,
      showLoader: true,
    }).then((response) => {
      if (response) {
        const res1 = [];
        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].status === '1') {
            res1.push(response.data[i]);
          }
        }
        setEmployees(res1);
      }
    });
    fetchItem({
      modelName: `/clients`,
      showLoader: true,
    }).then((response) => {
      if (response) {
        const res = [];
        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].status === '1') {
            res.push(response.data[i]);
          }
        }
        setClients(res);
      }
    });
    fetchItem({
      modelName: `/company-types`,
      showLoader: true,
    }).then((response) => {
      if (response) {
        const res = [];
        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].status === '1') {
            res.push(response.data[i]);
          }
        }
        setCompanyTypes(res);
      }
    });
    fetchItem({
      modelName: `/order-statuses`,
      showLoader: true,
    }).then((response) => {
      if (response) {
        const _final = [];
        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].status === '1') {
            _final.push(response.data[i]);
          }
          // if (String(response.data[i].title) !== 'Open') {
          //   _final.push(response.data[i]);
          // }
        }
        setOrderStatuses(_final);
      }
    });
    fetchItem({
      modelName: `/orders`,
      showLoader: true,
    }).then((response) => {
      if (response) {
        const res1 = [];
        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].status === '1') {
            res1.push(response.data[i]);
          }
        }
        setBeforeData(res1);
      }
    });
  }, []);

  useEffect(() => {
    const res = beforeData;
    const final_res = [];
    for (let i = 0; i < beforeData.length; i++) {
      if (beforeData[i].job_types.length > 0) {
        let _tmpstring = '';
        for (let j = 0; j < beforeData[i].job_types.length; j++) {
          // eslint-disable-next-line prefer-destructuring
          _tmpstring = _tmpstring.concat('-').concat(beforeData[i]?.job_types[j]?.title);
        }
        _tmpstring = _tmpstring.substring(1);
        res[i].job_types = _tmpstring;
      }
      final_res.push(res[i]);
      // if (beforeData[i].order_status.title !== 'Open' && beforeData[i].approved_by_admin === '1') {
      //   final_res.push(res[i]);
      // }
    }
    setData(final_res);
  }, [beforeData]);
  useEffect(() => {
    setColumns([
      {
        title: 'Order Number',
        field: 'order_number',
        sorting: false,
      },
      {
        title: 'Client',
        field: 'client_id',
        sorting: false,
        lookup: convertArrayToKeyedObject(clients, 'id', 'name'),
      },
      {
        title: 'Employee',
        field: 'user_id',
        sorting: false,
        lookup: convertArrayToKeyedObject(employees, 'id', 'name'),
      },
      {
        title: 'Order Status',
        field: 'order_status_id',
        lookup: convertArrayToKeyedObject(orderStatuses, 'id', 'title'),
        sorting: false,
      },
      {
        title: 'Order Type',
        field: 'order_type',
        sorting: false,
      },
      {
        title: 'Date',
        field: 'date',
        render: (rowData) => moment(rowData.date).format('MM-DD-YYYY h:mm:ss'),
        filtering: false,
      },
      {
        title: 'Total',
        field: 'total_price',
        filtering: false,
        sorting: false,
      },
    ]);
    setHistoryColumns([
      {
        title: 'Title',
        field: 'title',
        filtering: false,
        sorting: false,
      },
      {
        title: 'Private Price-1 (Low price - not home)',
        field: 'private_price_1',
        filtering: false,
        sorting: false,
      },
      {
        title: 'Insurance Price-1 (Low price - not home)',
        field: 'insurance_price_1',
        filtering: false,
        sorting: false,
      },
      {
        title: 'Price-2 (Just inspect)',
        field: 'price_2',
        filtering: false,
        sorting: false,
      },
      {
        title: 'Price-3 (Complete Price)',
        field: 'price_3',
        filtering: false,
        sorting: false,
      },
      {
        title: 'Price-4 (Manual adding for an extra job)',
        field: 'price_4',
        filtering: false,
        sorting: false,
      },
    ]);
    setKey(key + 1);
  }, [employees, clients, orderStatuses, companyTypes, data]);

  useEffect(() => {
    setPaymentsDataColumns([
      {
        title: 'Payment Amount',
        field: 'amount',
        sorting: false,
        filtering: false,
      },
      {
        title: 'Percent Amount',
        field: 'percent_amount',
        render: (rowData) => (rowData.percent_amount ? rowData.percent_amount : '-'),
        sorting: false,
        filtering: false,
      },
      {
        title: 'Credit Card Number',
        field: 'cc_number',
        render: (rowData) => (rowData.cc_number ? rowData.cc_number : '-'),
        sorting: false,
        filtering: false,
      },
      {
        title: 'Payment Type',
        field: 'payment_type_id',
        render: (rowData) =>
          rowData.payment_type_id === 1 || rowData.payment_type_id === '1' ? 'Cash' : 'Credit Card',
        filtering: false,
        sorting: false,
      },
      {
        title: 'Intention',
        field: 'payment_type',
        render: (rowData) => rowData.payment_type.charAt(0).toUpperCase() + rowData.payment_type.slice(1),
        sorting: false,
        filtering: false,
      },
    ]);
    setKey(key + 1);
  }, [paymentsData]);

  useEffect(() => {
    setBonusDataColumns([
      {
        title: 'Bonus Amount',
        field: 'bonus_amount',
        sorting: false,
        filtering: false,
      },
      {
        title: 'Created At',
        field: 'created_at',
        render: (rowData) => moment(rowData.created_at).format('MM-DD-YYYY'),
        sorting: false,
        filtering: false,
      },
      {
        title: 'Updated at',
        field: 'updated_at',
        render: (rowData) => moment(rowData.updated_at).format('MM-DD-YYYY'),
        sorting: false,
        filtering: false,
      },
    ]);
    setKey(key + 1);
  }, [bonusData]);

  const getPayments = (e) => {
    if (e.bonus?.id) {
      fetchItem({
        modelName: 'order-bonuses',
        id: e.bonus?.id,
        showLoader: true,
      }).then((response) => {
        setBonusData([response.data]);
      });
    }
    setTravelPrice(e.travel_price);
    if (e.order_type === 'private') {
      setHistoryTitle('Payments Dtails');
      if (e.expenses) {
        setExpenses(
          String(e.expenses_paid_by_employee) === '0'
            ? 'Expenses paid by Admin:'.concat('  $').concat(String(e.expenses))
            : 'Expenses paid by Employee:'.concat('  $').concat(String(e.expenses)),
        );
      }
      fetchItem({
        modelName: 'order-payments',
        showLoader: true,
      })
        .then((response) => {
          if (response) {
            const _final = [];
            for (let i = 0; i < response.data.length; i++) {
              if (String(response.data[i].order_id) === String(e.id)) {
                _final.push(response.data[i]);
              }
            }
            setPaymentsData(_final);
            setHistories(_final);
            setModalColumns(paymentsDataColumns);
            setHistoryModalVisible(true);
          }
        })
        .finally(() => setLoading(false));
    }
    if (e.order_type === 'insurance') {
      setHistoryTitle('Job Types Details');
      setExpenses(null);
      fetchItem({
        modelName: `/job-types`,
        showLoader: true,
      }).then((response) => {
        if (response) {
          const _final = [];
          const _types = e.job_types.split('-');
          for (let i = 0; i < response.data.length; i++) {
            for (let j = 0; j < _types.length; j++) {
              if (response.data[i].status === '1' && response.data[i].title === _types[j]) {
                _final.push(response.data[i]);
              }
            }
          }
          setHistories(_final);
          setModalColumns(historyColumns);
          setHistoryModalVisible(true);
        }
      });
    }
  };

  const onSelectionChanging = (currentlySelectedRows) => {
    const _data = data;
    const _row = [];
    let _total = 0;
    const indexes = [];
    if (currentlySelectedRows) {
      for (let i = 0; i < currentlySelectedRows.length; i++) {
        const index = _data.indexOf(_data.find((x) => String(x.id) === String(currentlySelectedRows[i].id)));
        indexes.push(index);
        _data[index] = currentlySelectedRows[i];
        _row.push(_data[index]);
        _total += currentlySelectedRows[i].total_price;
      }
    }
    for (let i = 0; i < data.length; i++) {
      if (!indexes.includes(i)) {
        if (_data[i].tableData) {
          _data[i].tableData.checked = false;
        }
      }
    }
    setData(_data);
    setGrandTotal(_total);
  };

  const onSubmit = () => {
    setLoading(true);
    fetchItem({
      modelName: `/orders?from_date=${moment(currentOrder.from_Date).format('MM-DD-YYYY')}&to_date=${moment(
        currentOrder.to_Date,
      ).format('MM-DD-YYYY')}&sort=asc`,
      showLoader: true,
    })
      .then((response) => {
        if (response) {
          const res1 = [];
          for (let i = 0; i < response.data.length; i++) {
            if (response.data[i].status === '1') {
              res1.push(response.data[i]);
            }
          }
          setBeforeData(res1);
        }
      })
      .finally(() => setLoading(false));
  };
  const onChange = (e) => {
    onChangeForBComponents(e, setValue, currentOrder, setCurrentOrder);
  };

  const actions = [
    {
      icon: () => <PaymentIcon />,
      tooltip: <h3>View Payments Details</h3>,
      onClick: (event, rowData) => getPayments(rowData),
      position: 'row',
    },
  ];

  const openDialog = () => historyModalVisible;

  const historyDialog = (
    <HistoryDialog
      open={openDialog}
      handleClose={() => {
        setHistoryModalVisible(false);
      }}
      maxWidth='xl'
      histories={histories}
      historyColumns={modalColumns}
      bonusData={bonusData}
      bonusDataColumns={bonusDataColumns}
      historyTitle={historyTitle}
      onRowClick={false}
      travelPrice={travelPrice}
      expenses={expenses}
    />
  );
  const dateForm = (
    <Card>
      <form className='formContainer' onSubmit={handleSubmit(onSubmit)}>
        <CardContent>
          <Grid container spacing={3}>
            <BDateFieldTable
              name='from_Date'
              label='From'
              value={currentOrder?.from_Date ? currentOrder?.from_Date : null}
              onChange={onChange}
            />
            <BDateFieldTable
              name='to_Date'
              label='To'
              value={currentOrder?.to_Date ? currentOrder?.to_Date : null}
              onChange={onChange}
            />
            <Grid item xs={12} sm={3} style={{ marginTop: '50px' }}>
              <BSubmitButton id='submitEmployee' label='Search' loading={loading} />
            </Grid>
            <Grid item xs={12} sm={3}>
              Total: {grandTotal}
            </Grid>
          </Grid>
        </CardContent>
      </form>
    </Card>
  );

  return (
    <>
      {dateForm}
      {historyDialog}
      <ListsContainer>
        <Table
          key={key}
          title='View Reports'
          columns={columns}
          exportMenu
          exportButton
          actions={actions}
          createAction={undefined}
          tableRef={tableRef}
          data={data}
          onRowClick={false}
          onChangeRowsPerPage={(pgSize) => {
            setPageSize(pgSize);
          }}
          pageSize={pageSize}
          filtering
          sortableColumns
          selection
          onSelectionChange={onSelectionChanging}
        />
      </ListsContainer>
    </>
  );
};

export default ViewReportList;
