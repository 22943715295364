import React, { useContext, useEffect, useState } from 'react';
import { Switch, Redirect, Route, useLocation } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';

import {
  Dashboard as DashboardView,
  NotFound as NotFoundView,
  EmployeeList as EmployeeListView,
  EmployeePage as EmployeePageView,
  OrderStatusesList as OrderStatusesListView,
  OrderStatusesPage as OrderStatusesPageView,
  ServiceTypesList as ServiceTypesListView,
  ServiceTypesPage as ServiceTypesPageView,
  ClientList as ClientListView,
  ClientPage as ClientPageView,
  JobTypesList as JobTypesListView,
  JobTypesPage as JobTypesPageView,
  CompanyTypesList as CompanyTypesListView,
  CompanyTypesPage as CompanyTypesPageView,
  OrdersList as OrdersListView,
  OrdersPage as OrdersPageView,
  OrderPageNewEmployee as OrderPageNewEmployeeView,
  OrderPageNewAdmin as OrderPageNewAdminView,
  CreateRoutePage as CreateRoutePageView,
  CreateRouteList as CreateRouteListView,
  MakePaymentsList as MakePaymentsListView,
  MakePaymentsListInsurance as MakePaymentsListInsuranceView,
  Chat as ChatPageView,
  ViewReportList as ViewReportListView,
  ZipsList as ZipsListView,
  ZipsPage as ZipsPageView,
  AreasList as AreasListView,
  AreasPage as AreasPageView,
  IntervalList as IntervalListView,
  IntervalPage as IntervalPageView,
  EmployeePayment as EmployeePaymentView,
  OrdersPageLink as OrdersPageLinkView,
  Payment as PaymentView,
} from './views';
import Login from './views/Login/Index';
import Logout from './views/Logout/Logout';
import { DashboardPanelContext } from './components/context/DashboardContext';
import { SET_ERROR } from './components/context/reducer/actionTypes';

import DontHavePermission from './views/403';

const Routes = () => {
  const [path, setPath] = useState('/dashboard');

  const cookies = new Cookies();
  const { globalDispatch, globalState } = useContext(DashboardPanelContext);
  const location = useLocation();

  useEffect(() => {
    if (globalState.error !== undefined) globalDispatch({ type: SET_ERROR, value: undefined });

    if (cookies.get('userToken') && location.pathname !== '/login') {
      setPath(location.pathname);
    }
    if (location.pathname === '/logout' || location.pathname === '/') {
      setPath('/dashboard');
    }
  }, [location]);

  return (
    <Switch>
      <Route exact path='/'>
        <Redirect from='/' to={path} />
      </Route>
      <Route path='/login' name='Login Page' exact component={Login} />
      <Route component={Logout} exact path='/logout' />
      <Route component={DontHavePermission} exact path='/403' />
      <RouteWithLayout component={DashboardView} exact layout={MainLayout} path='/dashboard' />
      <RouteWithLayout
        permission='ADMIN'
        component={EmployeeListView}
        layout={MainLayout}
        path='/employee-management/employees'
      />
      <RouteWithLayout
        permission='ADMIN'
        component={EmployeePageView}
        layout={MainLayout}
        path='/employee-management/employee/:id?'
      />
      <RouteWithLayout
        permission='ADMIN'
        component={OrderStatusesListView}
        layout={MainLayout}
        path='/order-management/statuses'
      />
      <RouteWithLayout
        permission='ADMIN'
        component={OrderStatusesPageView}
        layout={MainLayout}
        path='/order-management/status/:id?'
      />
      <RouteWithLayout
        permission='ADMIN'
        component={ServiceTypesListView}
        layout={MainLayout}
        path='/order-management/service-types'
      />
      <RouteWithLayout
        permission='ADMIN'
        component={ServiceTypesPageView}
        layout={MainLayout}
        path='/order-management/service-type/:id?'
      />
      <RouteWithLayout
        permission='ADMIN'
        component={ClientListView}
        layout={MainLayout}
        path='/client-management/clients'
      />
      <RouteWithLayout
        permission='ADMIN'
        component={ClientPageView}
        layout={MainLayout}
        path='/client-management/client/:id?'
      />
      <RouteWithLayout
        permission='ADMIN'
        component={JobTypesListView}
        layout={MainLayout}
        path='/order-management/job-types'
      />
      <RouteWithLayout
        permission='ADMIN'
        component={JobTypesPageView}
        layout={MainLayout}
        path='/order-management/job-type/:id?'
      />
      <RouteWithLayout
        permission='ADMIN'
        component={CompanyTypesListView}
        layout={MainLayout}
        path='/order-management/company-types'
      />
      <RouteWithLayout
        permission='ADMIN'
        component={CompanyTypesPageView}
        layout={MainLayout}
        path='/order-management/company-type/:id?'
      />
      <RouteWithLayout
        permission='ADMIN'
        component={OrdersListView}
        layout={MainLayout}
        path='/order-management/orders'
      />
      <RouteWithLayout
        permission='ADMIN'
        component={OrdersPageView}
        layout={MainLayout}
        path='/order-management/order/:id?'
      />
      <RouteWithLayout
        permission='ADMIN'
        component={CreateRouteListView}
        layout={MainLayout}
        path='/employee-management/create-routes'
      />
      <RouteWithLayout
        permission='ADMIN'
        component={CreateRoutePageView}
        layout={MainLayout}
        path='/employee-management/create-route/:id?'
      />
      <RouteWithLayout
        permission='ADMIN'
        component={MakePaymentsListView}
        layout={MainLayout}
        path='/order-management/make-payments'
      />
      <RouteWithLayout
        permission='ADMIN'
        component={MakePaymentsListInsuranceView}
        layout={MainLayout}
        path='/order-management/make-payments-insurance'
      />
      <RouteWithLayout
        permission='ADMIN'
        component={ViewReportListView}
        layout={MainLayout}
        path='/order-management/view-reports'
      />
      <RouteWithLayout permission='ADMIN' component={ZipsListView} layout={MainLayout} path='/order-management/zips' />
      <RouteWithLayout
        permission='ADMIN'
        component={ZipsPageView}
        layout={MainLayout}
        path='/order-management/zip/:id?'
      />
      <RouteWithLayout
        permission='ADMIN'
        component={AreasListView}
        layout={MainLayout}
        path='/order-management/areas'
      />
      <RouteWithLayout
        permission='ADMIN'
        component={AreasPageView}
        layout={MainLayout}
        path='/order-management/area/:id?'
      />
      <RouteWithLayout
        permission='ADMIN'
        component={IntervalListView}
        layout={MainLayout}
        path='/order-management/intervals'
      />
      <RouteWithLayout
        permission='ADMIN'
        component={IntervalPageView}
        layout={MainLayout}
        path='/order-management/interval/:id?'
      />
      <RouteWithLayout permission='EMPLOYEE' component={OrdersListView} layout={MainLayout} path='/employees/orders' />
      <RouteWithLayout
        permission='EMPLOYEE'
        component={OrdersPageView}
        layout={MainLayout}
        path='/employees/order/:id?'
      />
      <RouteWithLayout
        permission='ADMIN'
        component={OrderPageNewAdminView}
        layout={MainLayout}
        path='/admin/new-order'
      />
      <RouteWithLayout
        permission='EMPLOYEE'
        component={OrderPageNewEmployeeView}
        layout={MainLayout}
        path='/employees/new-order'
      />
      <RouteWithLayout
        permission='EMPLOYEE'
        component={CreateRouteListView}
        layout={MainLayout}
        path='/employees/routes'
      />
      <RouteWithLayout
        permission='EMPLOYEE'
        component={CreateRoutePageView}
        layout={MainLayout}
        path='/employees/route/:id?'
      />
      <RouteWithLayout
        permission='EMPLOYEE'
        component={EmployeePaymentView}
        layout={MainLayout}
        path='/employees/payments'
      />
      <RouteWithLayout
        permission='EMPLOYEE'
        component={OrdersPageLinkView}
        layout={MainLayout}
        path='/employees/orderLink/:id?'
      />
      <RouteWithLayout
        permission='ADMIN'
        component={OrdersPageLinkView}
        layout={MainLayout}
        path='/order-management/orderLink/:id?'
      />
      <RouteWithLayout permission='ADMIN' component={PaymentView} layout={MainLayout} path='/orders-payments/:id?' />
      <RouteWithLayout permission='EMPLOYEE' component={PaymentView} layout={MainLayout} path='/order-payment/:id?' />
      <RouteWithLayout permission='EMPLOYEE' component={ChatPageView} layout={MainLayout} path='/employee/chat' />
      <RouteWithLayout permission='ADMIN' component={ChatPageView} layout={MainLayout} path='/chat' />
      <RouteWithLayout component={NotFoundView} exact layout={MinimalLayout} path='/not-found' />
      <Redirect to='/not-found' />
    </Switch>
  );
};

export default React.memo(Routes);
