import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import { Button, Card, CardContent } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import { DashboardPanelContext } from '../../../../components/context/DashboardContext';
import Table from '../../../../components/tables/Table';
import { SET_VIEW_READ_ONLY } from '../../../../components/context/reducer/actionTypes';
import useTableActions from '../../../../hooks/useTableActions';
import { convertArrayToKeyedObject, normalizeUsPhone } from '../../../../common/utilities';
import BTextField from '../../../../components/inputs/forms/BTextField';
import BSubmitButton from '../../../../components/buttons/SubmitButton';
import EmployeeOrdersList from './components/EmployeeOrderList';

const OrdersList = () => {
  const { handleSubmit } = useForm();
  const history = useHistory();
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const tableRef = useRef(null);
  const { globalDispatch, fetchItem } = useContext(DashboardPanelContext);
  const [permission, setPermission] = useState(null);
  const [columns, setColumns] = useState([]);
  const [key, setKey] = useState([]);
  const [data, setData] = useState([]);
  const [clients, setClients] = useState([]);
  const [companyTypes, setCompanyTypes] = useState([]);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchField, setSearchField] = useState('');
  const [employeeId, setEmployeeId] = useState(null);
  const [tableDataLoader, setTableDataLoader] = useState(false);
  const [orderStatus, setOrderStatus] = useState('');

  const getOrdersDataTable = (pageNumber, searchText, status) => {
    setTableDataLoader(true);
    fetchItem({
      modelName: `/orders?page=${pageNumber}${searchText ? `&search=${searchText}` : ''}${
        status && !searchText ? `&status=${status}` : ''
      } `,
      showLoader: true,
    })
      .then((res) => {
        if (res.data) {
          setTotalCount(res.data.total > pageSize ? res.data.total + pageSize : res.data.total);
          setTableDataLoader(false);
          const open = [];
          const closed = [];
          const approved = [];
          const _reopens = [];
          const all = [];
          for (let i = 0; i < res.data.data.length; i++) {
            // if (Number(res.data.data[i].id) < 162) {
            res.data.data[i].order_status = res.data.data[i].order_status.title;
            if (res.data.data[i].reopen === '1') {
              _reopens.push(res.data.data[i]);
            } else {
              if (res.data.data[i].order_status === 'Open') {
                open.push(res.data.data[i]);
              } else if (res.data.data[i].order_status !== 'Open' && res.data.data[i].approved_by_admin === '0') {
                closed.push(res.data.data[i]);
              }
              if (res.data.data[i].approved_by_admin === '1' && res.data.data[i].report === '0') {
                approved.push(res.data.data[i]);
              }
              if (res.data.data) {
                all.push(res.data.data[i]);
              }
            }
            // }
          }
          setData(all);
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchItem({
      modelName: `/clientlist`,
      showLoader: true,
    }).then((response) => {
      if (response) {
        const res = [];
        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].status === '1') {
            res.push(response.data[i]);
          }
        }
        setClients(res);
      }
    });
    fetchItem({
      modelName: `/profile`,
      showLoader: true,
    }).then((response) => {
      setEmployeeId(response?.id);
      if (response.is_admin === '1') {
        setPermission('ADMIN');
        fetchItem({
          modelName: `/company-types`,
          showLoader: true,
        }).then((res) => {
          if (res) {
            const res1 = [];
            for (let i = 0; i < res.data.length; i++) {
              if (res.data[i].status === '1') {
                res1.push(res.data[i]);
              }
            }
            setCompanyTypes(res1);
          }
        });
        fetchItem({
          modelName: `/users`,
          showLoader: true,
        })
          .then((res) => {
            if (res) {
              const res1 = [];
              for (let i = 0; i < res.data.data.length; i++) {
                if (res.data.data[i].status === '1') {
                  res1.push(res.data.data[i]);
                }
              }
              setUsers(res1);
            }
          })
          .finally(() => setLoading(false));
        getOrdersDataTable(page, searchField, orderStatus);
      } else {
        setPermission('EMPLOYEE');
        setLoading(false);
      }
    });
  }, []);

  const changedOrders = (e) => {
    const status = e.currentTarget.name;
    setOrderStatus(status);
    if (status) {
      setSearchField('');
      setPage(1);
      getOrdersDataTable(1, '', status);
    }
  };

  useEffect(() => {
    const _col = [
      {
        title: 'Order Number',
        field: 'order_number',
        filtering: true,
        sorting: false,
        customSort: (a, b) => a?.id - b?.id,
      },
      {
        title: 'External Order Number',
        field: 'external_order_number',
        filtering: true,
        sorting: false,
        customSort: (a, b) => a?.id - b?.id,
      },
      {
        title: 'Employee',
        field: 'user',
        sorting: false,
        render: (rowData) => (rowData?.user ? `${rowData?.user.f_name} ${rowData?.user.l_name}` : ''),
      },
      {
        title: 'Phone Number',
        render: (rowData) => (rowData?.phone_1?.length === 10 ? normalizeUsPhone(rowData?.phone_1) : rowData?.phone_1),
        field: 'phone_1',
        filtering: false,
        sorting: false,
      },
      {
        title: 'Email Address',
        field: 'email',
        filtering: false,
        sorting: false,
      },
      {
        title: 'Opened Date',
        field: 'created_at',
        render: (rowData) =>
          rowData.created_at && moment(rowData.created_at, true).isValid()
            ? moment(rowData.created_at).utc().format('MM-DD-YYYY')
            : '',
        filtering: false,
        sorting: false,
      },
      {
        title: 'Route Date',
        field: 'date',
        render: (rowData) =>
          rowData.date && moment(rowData.date, true).isValid() ? moment(rowData.date).format('MM-DD-YYYY') : '',
        filtering: false,
        sorting: false,
      },
      {
        title: 'Client',
        field: 'client_id',
        sorting: false,
        lookup: convertArrayToKeyedObject(clients, 'id', 'name'),
      },
    ];

    _col.push(
      {
        title: 'Order Status',
        field: 'order_status',
        render: (rowData) => (rowData.status === '0' ? `${rowData.order_status} X` : rowData.order_status),
        filtering: true,
        sorting: false,
      },
      {
        title: 'Company Type',
        field: 'company_type_id',
        lookup: convertArrayToKeyedObject(companyTypes, 'id', 'name'),
        filtering: false,
        sorting: false,
        hiddenByColumnsButton: false,
        hidden: true,
      },
      {
        title: 'description',
        field: 'description',
        filtering: false,
        sorting: false,
        hiddenByColumnsButton: false,
        hidden: true,
      },
      {
        title: 'Full Address',
        field: 'full_address',
        filtering: false,
        sorting: false,
        hiddenByColumnsButton: false,
        hidden: true,
      },
      {
        title: 'Mobile',
        field: 'phone_2',
        render: (rowData) => (rowData?.phone_2?.length === 10 ? normalizeUsPhone(rowData?.phone_2) : rowData?.phone_2),
        filtering: false,
        sorting: false,
        hiddenByColumnsButton: false,
        hidden: true,
      },
      {
        title: 'Special Notes',
        field: 'special_notes',
        filtering: false,
        sorting: false,
        hiddenByColumnsButton: false,
        hidden: true,
      },
    );
    setColumns(_col);
    setKey(key + 1);
  }, [clients, companyTypes, users]);

  const actions = useTableActions({
    updatePermission: 'ADMIN',
    deletePermission: '',
    editURL: '/order-management/order/',
    removePATH: 'orders',
    tableRef,
  });

  const handleCreate = () => {
    globalDispatch({ type: SET_VIEW_READ_ONLY, value: false });
    history.push('/order-management/order');
  };

  const onRowClick = (event, rowData) => {
    globalDispatch({ type: SET_VIEW_READ_ONLY, value: true });
    if (permission === 'ADMIN') {
      history.push(`/order-management/order/${rowData.id}`);
    }
    if (permission === 'EMPLOYEE') {
      history.push(`/employees/order/${rowData.id}`);
    }
  };

  // const showAllOrders = () => {
  //   setData(ordersAll);
  // };

  const onSubmit = () => {
    setLoading(true);
    if (permission === 'ADMIN') {
      fetchItem({
        modelName: `/orders?page=1${searchField ? `&search=${searchField}` : ''}`,
        showLoader: true,
      })
        .then((res) => {
          if (res) {
            const open = [];
            const closed = [];
            const approved = [];
            const allOrders = [];
            const _reopen = [];
            // eslint-disable-next-line no-shadow
            const { data } = res.data;
            setPage(1);
            setTotalCount(res.data.total > pageSize ? res.data.total + pageSize : res.data.total);
            for (let i = 0; i < data.length; i++) {
              data[i].order_status = data[i].order_status.title;
              if (Number(data[i].id) > 162) {
                if (searchField) {
                  allOrders.push(data[i]);
                } else {
                  if (data[i].reopen === '1') {
                    _reopen.push(data[i]);
                  }
                  if (data[i].order_status === 'Open') {
                    open.push(data[i]);
                  } else if (data[i].order_status !== 'Open' && data[i].approved_by_admin === '0') {
                    closed.push(data[i]);
                  }
                  if (data[i].approved_by_admin === '1' && data[i].report === '0') {
                    approved.push(data[i]);
                  }
                }
              }
            }
            if (searchField) {
              setData(allOrders);
            } else {
              setData(open);
            }
          }
        })
        .finally(() => setLoading(false));
    } else {
      fetchItem({
        modelName: `/orders?search=${searchField}`,
        showLoader: true,
      })
        .then((res) => {
          if (res) {
            const open = [];
            const closed = [];
            const approved = [];
            const _reopen = [];
            const all = [];
            for (let i = 0; i < res.data.length; i++) {
              res.data[i].order_status = res.data[i].order_status.title;
              if (Number(res.data[i].id) > 162) {
                if (res.data[i].user_id && String(res.data[i].user_id) === String(employeeId)) {
                  if (searchField) {
                    all.push(res.data[i]);
                  }
                  if (res.data[i].reopen === '1') {
                    _reopen.push(res.data[i]);
                  } else {
                    if (res.data[i].order_status === 'Open') {
                      open.push(res.data[i]);
                    } else if (res.data[i].order_status !== 'Open' && res.data[i].approved_by_admin === '0') {
                      closed.push(res.data[i]);
                    }
                    if (res.data[i].approved_by_admin === '1' && res.data[i].report === '0') {
                      approved.push(res.data[i]);
                    }
                    if (res.data[i].approved_by_admin === '1') {
                      all.push(res.data[i]);
                    }
                  }
                }
              }
            }
            open.sort((a, b) => moment(b.date) - moment(a.date));
            closed.sort((a, b) => moment(b.date) - moment(a.date));
            approved.sort((a, b) => moment(b.date) - moment(a.date));
            _reopen.sort((a, b) => moment(b.date) - moment(a.date));
            all.sort((a, b) => moment(b.date) - moment(a.date));
            if (searchField) {
              setData(all);
            } else {
              setData(open);
            }
          }
        })
        .finally(() => setLoading(false));
    }
  };

  const onChangePage = (pageNumber, pageNumberSize) => {
    setPageSize(pageNumberSize);
    if (searchField) {
      setOrderStatus('');
    }
    const orderPage = pageNumber === 0 ? 1 : pageNumber;
    setPage(orderPage);
    getOrdersDataTable(orderPage, searchField, orderStatus);
  };

  return (
    <div style={{ textAlign: 'center', paddingBottom: '50px' }}>
      <Card>
        <CardContent>
          <form className='formContainer' onSubmit={handleSubmit(onSubmit)}>
            <Grid container>
              <BTextField
                label=''
                sm={2}
                name='search'
                placeholder='Search'
                value={searchField}
                onChange={(e) => setSearchField(e.currentTarget.value)}
              />
              {permission === 'ADMIN' && (
                <>
                  <Grid item xs={12} sm={2} style={{ marginTop: '5px' }}>
                    <BSubmitButton id='submitEmployee' label='Search' loading={loading} />
                  </Grid>

                  <Grid item xs={12} sm={2} style={{ marginTop: '5px' }}>
                    <Button id='closed' color='secondary' name='order_open' variant='outlined' onClick={changedOrders}>
                      Open orders
                    </Button>
                  </Grid>
                </>
              )}
              {permission === 'ADMIN' && (
                <Grid item xs={12} sm={2} style={{ marginTop: '5px' }}>
                  <Button id='closed' color='secondary' variant='outlined' name='order_closed' onClick={changedOrders}>
                    Closed orders
                  </Button>
                </Grid>
              )}
              {permission === 'ADMIN' && (
                <Grid item xs={12} sm={2} style={{ marginTop: '5px' }}>
                  <Button id='closed' color='secondary' variant='outlined' name='order_reopen' onClick={changedOrders}>
                    Reopen orders
                  </Button>
                </Grid>
              )}
              {/* {permission === 'EMPLOYEE' && (
                <Grid item xs={12} sm={3} style={{ marginTop: '5px' }}>
                  <Button id='closed' onClick={showAllOrders} color='secondary' variant='outlined'>
                    ALL ORDERS
                  </Button>
                </Grid>
              )} */}
              {permission === 'ADMIN' && (
                <Grid item xs={12} sm={2} style={{ marginTop: '5px' }}>
                  <Button id='closed' color='secondary' variant='outlined' name='order_report' onClick={changedOrders}>
                    Report orders
                  </Button>
                </Grid>
              )}
            </Grid>
          </form>
        </CardContent>
      </Card>
      {permission === 'EMPLOYEE' && <EmployeeOrdersList searchField={searchField} onRowClick={onRowClick} />}
      {loading && permission === 'ADMIN' && (
        <CircularProgress style={{ margin: '200px 0', position: 'absolute', zIndex: '10000' }} />
      )}

      {permission === 'ADMIN' && (
        <Table
          // key={key}
          title='Orders List'
          columns={columns}
          createSlug='Add Order'
          exportCsv={false}
          actions={permission === 'ADMIN' ? actions : []}
          createAction={permission === 'ADMIN' ? handleCreate : undefined}
          tableRef={tableRef}
          data={data}
          onRowClick={onRowClick}
          onChangeRowsPerPage={(pgSize) => {
            setPageSize(pgSize);
          }}
          pageSize={pageSize}
          paging={permission === 'ADMIN'}
          filtering={false}
          sortableColumns
          onChangePage={onChangePage}
          totalCount={totalCount}
          page={page}
          isLoading={tableDataLoader}
        />
      )}
    </div>
  );
};

export default OrdersList;
