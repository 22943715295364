import L from 'leaflet';

export const MarkerNew = L.icon({
  iconUrl: 'https://cdn0.iconfinder.com/data/icons/small-n-flat/24/678111-map-marker-512.png',
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
});

export const MarkerHide = L.icon({
  iconUrl: 'https://cdn0.iconfinder.com/data/icons/small-n-flat/24/678111-map-marker-512.png',
  iconSize: [10, 10],
  iconAnchor: [10, 10],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
});
