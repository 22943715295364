import React from 'react';
import { InputLabel, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { InfoOutlined } from '@material-ui/icons';
import { PropTypes, ReactFormHookServerError } from '../common/utilities';

const useStyles1 = makeStyles(() => ({
  formLabel: {
    fontSize: 16,
    marginBottom: 10,
  },
}));

const IconWithTooltip = ({ title }) => (
  <Tooltip color='primary' style={{ position: 'absolute', marginTop: -3 }} title={title}>
    <InfoOutlined />
  </Tooltip>
);

IconWithTooltip.propTypes = {
  title: PropTypes.string,
};

const BInputLabel = ({ error, serverError, name, label, tooltip, required = false }) => {
  const classes = useStyles1();
  return label ? (
    <InputLabel
      error={error?.message || ReactFormHookServerError(serverError, 'messages', 'fieldName', name)}
      htmlFor={`input--${name}`}
      className={classes.formLabel}
    >
      {label.trim()}
      {required && <span style={{ color: 'red' }}> *</span>}
      {tooltip && <IconWithTooltip title={tooltip} />}
    </InputLabel>
  ) : (
    ''
  );
};

BInputLabel.propTypes = {
  error: PropTypes.object,
  serverError: PropTypes.any,
  name: PropTypes.string,
  label: PropTypes.string,
  tooltip: PropTypes.string,
  required: PropTypes.bool,
};

export default React.memo(BInputLabel);
