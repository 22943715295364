import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import MaterialTable from '@material-table/core';
import { Skeleton } from '@material-ui/lab';
import { Avatar, Card, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { font, primaryMainColor, whiteColor } from '../../../../../components/configs/Variables';
import { DashboardPanelContext } from '../../../../../components/context/DashboardContext';
import { BSwalShowError } from '../../../../../common/BSwal';
import { BMaterialTableLocalization } from '../../../../../constants/MaterialTable';
import useOptions from '../../../../../components/tables/useOptions';

const Inventory = ({
  c = true,
  d = true,
  u = true,
  columns,
  style,
  title,
  items,
  onChange,
  children,
  search = false,
  paginate = false,
  pageSize = 30,
  key = 0,
  onRefChange = () => '',
}) => {
  const {
    globalState: { pageLoading },
  } = useContext(DashboardPanelContext);
  const tableRef = useRef();

  useEffect(() => {
    onRefChange(tableRef);
  }, [tableRef]);

  const { options } = useOptions({
    init: {
      search: false,
      actionsColumnIndex: -1,
      emptyRowsWhenPaging: false,
      filterCellStyle: {
        direction: 'ltr',
        textAlign: 'left',
      },
      filtering: false,
      pageSize: 10,
      paging: false,
      exportButton: false,
      exportAllData: false,
      searchFieldStyle: {
        fontFamily: font,
      },
      headerStyle: {
        backgroundColor: primaryMainColor,
        color: whiteColor,
        direction: 'ltr',
        textAlign: 'left',
      },
      rowStyle: {
        textAlign: 'left',
      },
      cellStyle: {
        textAlign: 'left',
      },
    },
    paginate,
    pageSize,
    search,
  });

  return pageLoading ? (
    <>
      <Card style={{ marginTop: 10, marginBottom: 10 }}>
        <Grid container spacing={1}>
          <Grid item sm={6}>
            <Skeleton width={100} style={{ marginRight: 5 }}>
              <Typography variant='h2'>.</Typography>
            </Skeleton>
          </Grid>
          <Grid item sm={6} style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div />
            <Skeleton variant='rect' style={{ marginLeft: 5 }}>
              <Avatar />
            </Skeleton>
          </Grid>
        </Grid>
        <Skeleton width='100%' height={150}>
          <Typography>.</Typography>
        </Skeleton>
      </Card>
    </>
  ) : (
    <>
      {children}
      <MaterialTable
        key={key}
        style={style}
        options={options}
        localization={BMaterialTableLocalization}
        title={title}
        tableRef={tableRef}
        editable={{
          onRowAdd: c
            ? (newData) =>
                new Promise((resolve, reject) => {
                  const requires = columns.filter((e) => e.required);
                  let r = [];
                  if (requires.length > 0) {
                    requires.forEach((y) => {
                      if (typeof y.required === 'boolean') {
                        if (!newData[y.field] || (Array.isArray(newData[y.field]) && newData[y.field].length === 0)) {
                          r = [...r, `مقدار ${y.title} اجباری می باشد`];
                        } else if (y.rules) {
                          if (!y.rules(newData[y.field])) {
                            r = [...r, `مقدار ${y.title} صحیح نمی باشد`];
                          }
                        }
                      } else {
                        const result = y.required(newData);
                        if (result) {
                          r = [...r, result];
                        }
                      }
                    });
                  }
                  if (r.length === 0) {
                    if (items) {
                      onChange([...items, newData]);
                      return resolve();
                    }
                    onChange([newData]);
                    return resolve();
                  }
                  BSwalShowError(r).then((res) => res);
                  return reject();
                })
            : undefined,
          onRowUpdate: u
            ? (newData, oldData) =>
                new Promise((resolve, reject) => {
                  // setTimeout(() => {
                  const requires = columns.filter((x) => x.required);
                  let r = [];
                  if (requires.length > 0) {
                    requires.forEach((y) => {
                      if (typeof y.required === 'boolean') {
                        if (!newData[y.field] || (Array.isArray(newData[y.field]) && newData[y.field].length === 0)) {
                          r = [...r, `مقدار ${y.title} اجباری می باشد`];
                        } else if (c.rules) {
                          if (!y.rules(newData[y.field])) {
                            r = [...r, `مقدار ${y.title} صحیح نمی باشد`];
                          }
                        }
                      } else {
                        const result = y.required(newData);
                        if (result) {
                          r = [...r, result];
                        }
                      }
                    });
                  }
                  if (r.length === 0) {
                    const dataUpdate = [...items];
                    const index = oldData.tableData.id;
                    dataUpdate[index] = newData;
                    onChange([...dataUpdate]);
                    return resolve();
                  }
                  BSwalShowError(r).then(reject);
                })
            : undefined,
          onRowDelete: d
            ? (oldData) =>
                new Promise((resolve) => {
                  const dataDelete = [...items];
                  const index = oldData.tableData.id;
                  dataDelete.splice(index, 1);
                  onChange([...dataDelete]);
                  resolve();
                })
            : undefined,
        }}
        columns={columns}
        data={items}
      />
    </>
  );
};

Inventory.propTypes = {
  c: PropTypes.bool,
  d: PropTypes.bool,
  u: PropTypes.bool,
  columns: PropTypes.array,
  style: PropTypes.object,
  title: PropTypes.string,
  items: PropTypes.array,
  onChange: PropTypes.func,
  children: PropTypes.any,
  paginate: PropTypes.bool,
  pageSize: PropTypes.number,
  search: PropTypes.bool,
};

export default Inventory;
