import moment from 'moment';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import Grid from '@material-ui/core/Grid';
import { InputLabel } from '@material-ui/core';
import styles from '../../../assets/scss/modules/inputs.module.scss';
import { ReactFormHookServerError } from '../../../common/utilities';

// jMoment.loadPersian({ dialect: 'persian-modern', usePersianDigits: false });
// const useStyles = makeStyles(theme => ({
//   root: {
//     padding: theme.spacing(4)
//   },
//   customInputFormControl: {
//     width: "100%"
//   },
//   formLabel: {
//     fontSize: 16,
//     marginBottom: 10
//   },
//   avatar: {
//     width: 120,
//     height: 120,
//     margin: 20
//   }
// }));
const BDateFieldTable = ({
  xs = 12,
  sm = 4,
  label,
  name,
  readOnly = false,
  onChange,
  value,
  max = '2200-01-01',
  min = '1001-01-01',
  error,
  serverError,
  placeholder,
}) => {
  // const classes = useStyles();

  const [selectedDate, handleDateChange] = useState(value);
  const [firstSearch, setFirstSearch] = useState(true);

  useEffect(() => {
    handleDateChange(value);
  }, [value]);

  useEffect(() => {
    if (!moment(selectedDate, true).isValid()) handleDateChange(null);
    if (onChange) {
      if (!selectedDate && !firstSearch) {
        onChange({
          currentTarget: {
            name,
            value: null,
          },
        });
      } else if (moment(selectedDate, 'YYYY-MM-DD', true).isValid()) {
        setFirstSearch(false);
        onChange({
          currentTarget: {
            name,
            value: moment(selectedDate).format('YYYY-MM-DD'),
          },
        });
      }
    }
  }, [selectedDate]);
  return (
    <Grid item xs={xs} sm={sm} aria-disabled={readOnly}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <>
          {label && <InputLabel htmlFor={`input--${name}`}>{label}</InputLabel>}
          <DatePicker
            maxDate={readOnly ? null : max}
            minDate={readOnly ? null : min}
            clearable
            minDateMessage={`The entered date should not be before ${moment(min).format('MM/DD/YYYY')}.`}
            onKeyPress={(e) => e.preventDefault()}
            invalidDateMessage='The date is incorrect.'
            inputVariant='outlined'
            value={selectedDate}
            labelFunc={(date) => (date ? moment(date).format('MM/DD/YYYY') : '')}
            okLabel='Confirm'
            disabled={readOnly}
            cancelLabel='Reject'
            clearLabel='Delete'
            showTodayButton
            todayLabel='Today'
            fullWidth
            placeholder={placeholder}
            onChange={handleDateChange}
          />
          {error && <p className={styles.validationError}>{error.message}</p>}
          {serverError && ReactFormHookServerError(serverError, 'messages', 'fieldName', name)}
        </>
      </MuiPickersUtilsProvider>
    </Grid>
  );
};

BDateFieldTable.propTypes = {
  xs: PropTypes.number,
  sm: PropTypes.number,
  label: PropTypes.string,
  name: PropTypes.string,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.string,
  max: PropTypes.any,
  min: PropTypes.any,
  error: PropTypes.object,
  serverError: PropTypes.array,
  placeholder: PropTypes.string,
};

export default BDateFieldTable;
