export const logActions = {
  1: 'Employee created the order',
  2: 'Employee edited the order',
  3: 'Employee changed order status',
  4: 'Employee made CC payment',
  5: 'Employee made cash payment',
  6: 'Admin created route',
  7: 'Admin made CC payment',
  8: 'Admin made cash payment',
  9: 'Admin edited the order',
  10: 'Admin created the order',
  11: 'Admin changed the order status',
  12: 'Admin assigned the route to the employee',
};
