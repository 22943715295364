import { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import createPersistedState from 'btechn01-use-persisted-state';
import { SET_PAGE_LOADING, SET_SAVE_SUCCESS } from '../components/context/reducer/actionTypes';
import { DashboardPanelContext } from '../components/context/DashboardContext';
import { GetQueryStringValue, PropTypes } from '../common/utilities';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useBasicInformationMountedEffect = <T>({
  match,
  fetchItemURL,
  localStorageKey,
  initialItem,
  fetchData,
  historyId,
}: {
  match: any;
  fetchItemURL: string;
  localStorageKey: string;
  fetchData?: (id: string | number) => any;
  initialItem: T;
  historyId?: string | number;
}) => {
  const {
    globalDispatch,
    fetchItem,
    globalState: { historyIdList },
  } = useContext(DashboardPanelContext);

  const [isConfirmation, setIsConfirmation] = useState(false);
  const useItem = createPersistedState(localStorageKey);

  const [item, setItem] = useItem<T>(initialItem as T);
  const [id, setId] = useState<number | string | null>(null);
  useEffect(() => {
    if (GetQueryStringValue('confirmation')) {
      setIsConfirmation(true);
    }
  }, []);
  useEffect(() => {
    const tempId = parseInt(_.get(match, 'params.id', '0'), 10);
    setId(tempId);
    if (tempId !== 0) {
      if (parseInt(_.get(item, 'id'), 10) === tempId) {
        setItem(item);
        // sarina added for refresh bug
        if (match.params.id !== 'histories') {
          if (match.params.id && historyIdList.length === 0) {
            globalDispatch({ type: SET_PAGE_LOADING, value: true });
            fetchItem<T>({ modelName: fetchItemURL, id: tempId })
              .then((r) => setItem(r!))
              .finally(() => {
                globalDispatch({ type: SET_PAGE_LOADING, value: false });
              });
          }
        }
      } else if (match.params.id !== 'histories') {
        if (match.params.id && historyIdList.length === 0) {
          globalDispatch({ type: SET_PAGE_LOADING, value: true });
          fetchItem<T>({ modelName: fetchItemURL, id: tempId })
            .then((r) => setItem(r!))
            .finally(() => {
              globalDispatch({ type: SET_PAGE_LOADING, value: false });
            });
        }
      } else {
        setItem(initialItem);
        if (historyIdList.length > 0 && fetchData) {
          fetchData((historyIdList[0] as any[]).indexOf(historyId));
        }
      }
    } else {
      setItem(initialItem);
    }
    globalDispatch({ type: SET_SAVE_SUCCESS, value: false });
    return () => {
      setItem(initialItem);
      localStorage.removeItem(localStorageKey);
    };
  }, [historyId]);

  return {
    id,
    setItem,
    item,
    isConfirmation,
  };
};

useBasicInformationMountedEffect.propTypes = {
  match: PropTypes.object,
  fetchItemURL: PropTypes.string,
  localStorageKey: PropTypes.string,
  initialItem: PropTypes.object,
  fetchData: PropTypes.func,
  historyId: PropTypes.any,
};

export default useBasicInformationMountedEffect;
